<template>
  <div>
    <div>
      <h4 style="color:#000">Welcome ,欢迎使用动优康复后台管理系统</h4>
    </div>
<!--    <div style="display: flex;margin-top: 16px">-->
<!--      <div style="width: 10px;height: 32px;background-color: #0066cc;margin-right: 16px"></div>-->
<!--      <span style="font-size: 32px;">数据统计</span>-->
<!--    </div>-->
<!--    <el-row :gutter="20" style="margin-top: 16px">-->
<!--      <el-col :span="6">-->
<!--        <div class="grid-content bg-purple">-->
<!--          <div class="box-card">-->
<!--            <div-->
<!--              style="display: flex;flex-direction: column;justify-items: center;justify-content:center;width: 100%;height: 100%">-->
<!--              <div style="text-align: center;font-size: 100px">180</div>-->
<!--              <div style="text-align: center;font-size: 23px;margin-top: 32px">小程序体操</div>-->
<!--              <div style="text-align: center;font-size: 24px;margin-top: 6px">申 请 人 次</div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </el-col>-->
<!--      <el-col :span="6">-->
<!--        <div class="grid-content bg-purple">-->
<!--          <div class="box-card">-->
<!--            <div-->
<!--              style="display: flex;flex-direction: column;justify-items: center;justify-content:center;width: 100%;height: 100%">-->
<!--              <div style="text-align: center;font-size: 100px">532</div>-->
<!--              <div style="text-align: center;font-size: 23px;margin-top: 32px">小程序体操</div>-->
<!--              <div style="text-align: center;font-size: 24px;margin-top: 6px">播 放 次 数</div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </el-col>-->
<!--      <el-col :span="6">-->
<!--        <div class="grid-content bg-purple"></div>-->
<!--      </el-col>-->
<!--      <el-col :span="6">-->
<!--        <div class="grid-content bg-purple"></div>-->
<!--      </el-col>-->
<!--    </el-row>-->
<!--    <div id="chart-bar-ticao-gender"-->
<!--         style="height: 320px;width: 820px;margin-top: 16px"></div>-->
  </div>
</template>
<script>
import * as echarts from 'echarts'

export default {
  data () {
    return {
      ticaoGender: {
        name: '小程序体操 年龄段分布',
        xData: ['10-20', '20-30', '30-40', '40-50', '50-60', '60-70', '70-80', '80-100'],
        yData: [12, 35, 27, 38, 37, 12, 6, 2]
      }
    }
  },
  mounted () {
    const {
      name,
      xData,
      yData
    } = this.ticaoGender
    const yPercent = []
    let sum = 0
    for (const yDataKey in yData) {
      sum += yData[yDataKey]
    }
    for (const a in yData) {
      const item = yData[a] * 100 / sum
      yPercent.push(item)
      console.log('年龄段分布', `${xData[a]}岁${item}`)
    }
    this.initChart(name, xData, yPercent)
  },
  methods: {
    initChart (name, xData, yData) {
      const chart = echarts.init(document.getElementById('chart-bar-ticao-gender'))
      const option = {
        title: {
          text: name
        },
        tooltip: {},
        xAxis: {
          data: xData,
          type: 'category',
          name: '年龄段'
        },
        yAxis: {
          type: 'value',
          name: '百分比',
          axisLabel: {
            formatter: '{value} %',
            align: 'center'
          }

        },
        series: [
          {
            type: 'bar',
            data: yData,
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            }
          }
        ]
      }

      chart.setOption(option)
      window.addEventListener('resize', () => {
        chart.resize()
      })
    }
  }
}
</script>

<style lang="less" scoped>
.flex_row {
  display: flex;
  justify-content: left;
  justify-items: left;
}

.grid-content {
  width: 100%;
  height: 200px;
}

.bg-purple {
  //background-color: #9ecfd5;
}

.box-card {
  width: 100%;
  height: 100%;
  border-width: 2px;
  border-style: solid;
  border-color: #757575;
  border-radius: 10px;
}
</style>
