<template>
  <div @click="clickHandle">
    <slot></slot>
    <input type="file" hidden
           ref="inputRef"
           @change="changeFile"
           :accept="accept"
           :multiple="multiple"
    />
    <div v-if="showFile">
      <div v-if="file">{{ file.name }}</div>
    </div>
  </div>
  <!--  ————————————————-->
  <!--  版权声明：本文为CSDN博主「Mr阿斌」的原创文章，遵循CC 4.0 BY-SA版权协议，转载请附上原文出处链接及本声明。-->
  <!--  原文链接：https://blog.csdn.net/mrqi122/article/details/126249497-->
</template>

<script>
export default {
  name: 'FileSelect',
  data () {
    return {
      file: null
    }
  },
  props: {
    accept: {
      type: String
    },
    multiple: {
      type: Boolean,
      default: true
    },
    showFile: {
      type: Boolean,
      default: true
    }

  },
  methods: {
    clickHandle () {
      this.$refs.inputRef.click()
    },
    changeFile (e) {
      this.file = e.target.files[0]
      this.$emit('chooseFile', e.target.files)
    }
  }
}
</script>

<style scoped>

</style>
