<template>
  <div class="editor-wrapper">
    <Toolbar
      class="rich-editor-toolbar"
      :editor="editor"
      :defaultConfig="toolbarConfig"
      :mode="mode"
    />
    <Editor
      class="rich-editor"
      v-model="editorContent"
      :defaultConfig="editorConfig"
      :mode="mode"
      @onCreated="onEditorCreated"
    />
    <RichContentPreview
      :previewTime="previewTime"
      :htmlContent="editorContent"
    ></RichContentPreview>
  </div>
</template>

<!-- 基础富文本编辑 -->
<!--
FIXME
1、加粗样式丢失
2、添加图片链接的浮层样式丢失
 -->
<script>
import { Boot } from '@wangeditor/editor'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import Vue from 'vue'
import { uploadFile } from '../../utils/AliOssUploadUtils'
import { getAllMenuConf } from './custom-menu'
import RichContentPreview from './RichContentPreview.vue'

const menuConfList = getAllMenuConf()
menuConfList.forEach(c => {
  Boot.registerMenu(c)
})
const menuKeyList = menuConfList.map(c => c.key)

export default Vue.extend({
  components: { Editor, Toolbar, RichContentPreview },
  props: {
    value: String,
    readonly: Boolean,
    fileDir: String
  },
  beforeDestroy () {
    const editor = this.editor
    if (editor === null) return
    // 取消事件监听
    editor.off('preview', () => {})
    // 销毁编辑器实例
    editor.destroy()
  },
  data () {
    return {
      previewTime: null,
      editor: null,
      editorContent: this.value || null,
      toolbarConfig: {
        insertKeys: {
          index: 31,
          keys: menuKeyList
        },
        excludeKeys: [
          // TODO 看需求是否需要在文章内部上传视频
          'group-video',
          // FIXME 全屏模式下，存在 element 表单的按钮遮挡输入区域的问题，以及预览 Drawer 遮罩显示异常的问题
          'fullScreen'
        ]
      },
      editorConfig: {},
      mode: 'default' // or 'simple'
    }
  },
  watch: {
    readonly (isReadOnly) {
      if (typeof isReadOnly !== 'boolean') return
      isReadOnly ? this.editor.disable() : this.enable()
    },
    value (nextValue) {
      if (typeof nextValue !== 'string') return
      if (nextValue === this.editorContent) return
      this.editorContent = nextValue
    },
    editorContent (nextHtml) {
      if (this.value === nextHtml) return
      let nextValue = nextHtml
      const currentText = this.editor.getText()
      // 没有内容
      if (!currentText?.trim()) nextValue = null
      this.$emit('input', nextValue)
    }
  },
  methods: {
    onEditorCreated (editor) {
      // 一定要用 Object.seal() ，否则会报错
      this.editor = Object.seal(editor)

      const VueInstance = this
      const editorConfig = this.editor.getConfig()
      // 自定义上传
      editorConfig.MENU_CONF.uploadImage = {
        customUpload (file, insertToEditor) {
          if (!VueInstance.fileDir) {
            VueInstance.$message.error('未提供存储路径，无法上传图片')
            return
          }
          uploadFile(
            VueInstance.fileDir,
            file,
            percent => {},
            fileUrl => {
              insertToEditor(fileUrl, file.name)
            },
            error => {
              console.log('上传失败', error)
              VueInstance.$message.error('图片上传失败')
            }
          )
        }
      }
      // 监听预览事件
      this.editor.on('preview', () => {
        this.previewTime = Date.now().valueOf()
      })
    }
  }
})
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
<style src="./styles/rich-editor.less" lang="less" scoped></style>
