<template>
  <div>
    <el-upload
      :class="{ 'pic-dragger': listType === 'picture-card', 'text-dragger': listType === 'text', hidden: limitationReached }"
      drag
      action=""
      :list-type="listType"
      :file-list="fileList"
      :limit="fileLimit"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      ref="upload"
      :http-request="uploadRequest"
      :on-success="uploadSuccess"
      :on-error="uploadError"
      :on-progress="onProgress"
      :on-change="onChange">
      <i class="el-icon-plus"></i>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>

</template>

<script>

export default {
  name: 'FileUploadOssPics',
  model: {
    prop: 'fileList',
    event: 'change'
  },
  props: {
    fileList: Array,
    fileLimit: Number,
    fileListType: String,
    dir: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      progress: 0,
      dialogImageUrl: '',
      dialogVisible: false
    }
  },
  computed: {
    listType () {
      return this.fileListType || 'picture-card'
    },
    limitationReached () {
      return this.fileList?.length === this.fileLimit
    }
  },
  methods: {
    handleRemove (file, fileList) {
      // console.log(file, fileList)
      this.$emit('change', fileList)
      this.getFileUrl(fileList)
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    /**
     * @description [UploadRequest 覆盖默认的上传行为，实现自定义上传]
     * @author shanshuizinong
     * @param {object} option [上传选项]
     * @return {null} [没有返回]
     */
    async uploadRequest (option) {
      // console.log('【uploadRequest】', option)
      try {
        const OSS = require('ali-oss')
        const ossConfig = {
          // region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
          region: 'oss-cn-shenzhen',
          // 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录RAM控制台创建RAM账号。
          accessKeyId: 'LTAIAcClE56I1mY0',
          accessKeySecret: 'IIPoiW0o3noCHU9bRT5vyAw0EDtem8',
          bucket: 'ihealthy'
        }
        const client = new OSS(ossConfig)
        const file = option.file
        // console.log(file)
        // 分片上传文件
        const ret = await client.multipartUpload(this.dir + '/' + file.name, file, {
          progress: async function (p) {
            // console.log('multipartUpload', p)
            const e = {}
            e.percent = parseInt(p * 100)
            option.onProgress(e)
          }
        })

        if (ret.res.statusCode === 200) {
          // option.onSuccess(ret)
          return ret
        } else {
          option.onError('上传失败')
        }
      } catch (err) {
        console.error(err)

        option.onError('上传失败')
        this.$error(err.message)
      }
    },
    onProgress (event, file, fileList) {
      // console.log('onProgress', event)
      this.progress = event.percent
    },
    uploadError (err, file, fileList) {
      this.$message.error(err)
      console.log('【uploadError】', err)
    },
    uploadSuccess (response, file, fileList) {
      // console.log('【uploadSuccess】', response)
      this.progressStatus = 'success'
      this.$message({
        message: '上传成功',
        type: 'success'
      })

      // 文件URL
      // var fileUrl = response.res.requestUrls[0]
      // console.log('【文件URL】', fileUrl)
      this.getFileUrl(fileList)
    },
    onChange (file, fileList) {
      // console.log('【onChange】', file)
      this.$emit('change', fileList)
    },
    getFileUrl (fileList) {
      const fileUrls = []
      for (const fileListKey in fileList) {
        const response = fileList[fileListKey].response.res.requestUrls[0]
        fileUrls.push(response.split('?')[0])
      }

      this.$emit('urlListChange', fileUrls)
    }
  }
}
</script>

<style scoped>

</style>
<style>
.pic-dragger .el-upload-dragger {
  width: 146px !important;
  height: 146px !important;
}
.text-dragger .el-upload-dragger {
  width: 100px !important;
  height: 40px !important;
}
.hidden .el-upload {
  display: none;
}
</style>
