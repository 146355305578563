<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/menu/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>运营数据</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>医生</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-input
            v-model="inputPhone"
            placeholder="请输入姓名或手机号进行查询"
          >

            <el-button
              slot="append"
              icon="el-icon-search"
              @click="onClickPhoneSearch"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="2">
          <el-button
            plain
            type="info"
          >清除输入
          </el-button>
        </el-col>
      </el-row>

      <el-table
        v-loading="loading"
        :data="doctors"
        :height="tableHeight"
        border
        stripe
        style="width: 100%"
      >
        <el-table-column
          label="日期"
          prop="createDate"
          width="180"
        >
        </el-table-column>
        <el-table-column
          label="姓名"
          prop="realname"
          width="180"
        >
        </el-table-column>

        <el-table-column
          label="user_id"
          prop="userId"
          width="180"
        ></el-table-column>
        <el-table-column
          label="手机号"
          prop="phone"
          width="180"
        >
        </el-table-column>
        <el-table-column
          label="医院"
          prop="realHospital"
          width="180"
        >
        </el-table-column>
        <el-table-column
          label="注册医院"
          prop="hospital"
          width="180"
        ></el-table-column>
        <!--        <el-table-column label="医院">-->
        <!--          <template slot-scope="scope">-->
        <!--            <el-select-->
        <!--              v-model="scope.row.hospital"-->
        <!--              style="width:300px"-->
        <!--              @change="handleHospitalChange(scope.$index, scope.row)"-->
        <!--            >-->
        <!--              <el-option-->
        <!--                v-for="item in hospitals"-->
        <!--                :key="item.id"-->
        <!--                :label="item.name"-->
        <!--                :value="item.id"-->
        <!--              >-->
        <!--              </el-option>-->
        <!--            </el-select>-->
        <!--          </template>-->
        <!--        </el-table-column>-->
        <el-table-column
          label="邀请人"
          width="180"
        >
          <template v-slot="scope">
            <div>{{ `${scope.row.inviterName} - ${scope.row.inviterCode}` }}</div>
          </template>
        </el-table-column>
        <el-table-column
          :fixed="isMobile?'':'right'"
          label="操作"
          width="300"
        >
          <template slot-scope="scope">
            <el-button
              v-show="false"
              size="small"
              type="text"
              @click="handleClick(scope.row)"
            >查看
            </el-button>
            <el-button
              v-show="false"
              size="small"
              type="text"
            >编辑
            </el-button>
            <el-button
              size="small"
              type="text"
              @click="onUpLineDoctor(scope.row)"
            >上线医生
            </el-button>
            <el-button
              size="small"
              type="text"
              @click="onClickDoctorTeam(scope.row)"
            >服务医院
            </el-button>
            <el-button
              size="small"
              type="text"
              @click="onClickRegisterHospital(scope.row)"
            >医院注册
            </el-button>
            <el-button
              size="small"
              type="text"
              @click="onClickPriceSetting(scope.row)"
            >价格设置
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        :page-size="pageSize"
        :total="total"
        layout="total, prev, pager, next"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </el-card>
    <el-dialog :visible.sync="dialogDoctorTeamManager" title="医生团队(服务预约)" @open="onDoctorTeamManagerOpen">
      <!--      <div v-if="loadingGetChangeHospitalList"></div>-->
      <!--      <div v-else></div>-->
      <el-table
        ref="multipleTableDoctorTeams"
        :data="doctorTeams"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange">
        <!--        <el-table-column-->
        <!--          width="100"-->
        <!--          align="center"-->
        <!--          label="当前医院">-->
        <!--          <template slot-scope="scope">-->
        <!--            <el-radio v-model="changeHospitalSeleced" :label="scope.row.hospitalId">{{ ' ' }}</el-radio>-->
        <!--          </template>-->
        <!--        </el-table-column>-->
        <el-table-column
          type="selection"
          width="55">
        </el-table-column>
        <el-table-column
          label="医院"
          prop="name"
        >
        </el-table-column>
      </el-table>
      <el-row justify="end" style="margin-top: 32px" type="flex">
        <el-button type="info" @click="dialogDoctorTeamManager = false">取消</el-button>
        <el-button type="primary" @click="changeDoctorTeam">确定</el-button>
      </el-row>
    </el-dialog>
    <RegisterHospitalSelectDialog ref="refDialogHospitalRegister"/>
  </div>
</template>

<script>
import RegisterHospitalSelectDialog from './RegisterHospitalSelectDialog'
import NumberUtil from '@/utils/NumberUtil'

export default {
  components: { RegisterHospitalSelectDialog },
  data () {
    return {
      loading: true,
      inputPhone: '',
      requesParams: {
        page: 1,
        pageSize: 20
      },
      pageSize: 20,
      total: 0,
      doctors: [],
      hospitals: [],
      dialogDoctorTeamManager: false,
      loadingDoctorTeamManager: true,
      doctorTeams: [],
      registerHospitals: [],
      operatorDoctor: null,
      multipleSelectionDoctorTeam: [],
      tableHeight: 400
    }
  },
  created () {

  },
  mounted () {
    this.$nextTick(() => {
      // 窗口高度 - 上边其他布局高度 - 最上面的导航栏高度和其他边距高度(225) = 表格高度（也就是剩下的高度）
      this.tableHeight = (window.innerHeight - 275)
    })
    this.getHosiptals()
    this.getDoctors()
  },
  computed: {
    isMobile () {
      const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    }
  },
  methods: {
    onClickRegisterHospital (row) {
      console.log('注册医院选择', row)
      this.$refs.refDialogHospitalRegister.onShow(row.phone, row.userId)
    },
    onClickPriceSetting (doctor) {
      console.log('设置价格', doctor)
      this.$router.push(`/menu/data/operation/doctor?phone=${doctor.phone}&doctorId=${doctor.userId}&name=${doctor.realname}`)
    },
    handleSelectionChange (val) {
      this.multipleSelectionDoctorTeam = val
      console.log('选择', this.multipleSelectionDoctorTeam)
    },
    async onUpLineDoctor (doctor) {
      console.log('当前操作医生', doctor)
      this.operatorDoctor = doctor

      await this.onDoctorTeamManagerOpen()// id与hospital表对应 == doctor.hospitalCode
      console.log('onUpLineDoctor-1', this.doctorTeams)
      this.multipleSelectionDoctorTeam.push({
        id: doctor.hospitalCode
      })
      await this.changeDoctorTeam()
      this.$message.success('医生上线成功(1/2)')
      const { data: resp } = await this.$http.get('regist/hospitals/phone',
        {
          params:
            {
              userId: doctor.userId
            }

        }
      )
      if (resp.code !== 200) return this.$message.error(resp.message)
      const hostpials = resp.data
      console.log('onUpLineDoctor-2', hostpials)// hospitalId与hospital表对应 == doctor.hospitalCode；id == RegisterHospital.ID
      var selectedH = null
      for (const h of hostpials) {
        if (h.hospitalId === doctor.hospitalCode) {
          selectedH = h
          console.log('onUpLineDoctor-3', selectedH)
        }
      }

      const hids = []
      hids.push(selectedH.id)
      const { data: resp2 } = await this.$http.post('regist/updateDoctor', {
        phone: doctor.phone,
        hid: hids
      })
      if (resp2.code !== 200) this.$message.error(resp2.message)
      this.$message.success('医生上线成功(2/2)')
    },
    onClickDoctorTeam (doctor) {
      console.log('当前操作医生', doctor)
      this.operatorDoctor = doctor
      this.dialogDoctorTeamManager = true
    },
    async onDoctorTeamManagerOpen () {
      console.log('读取医院')
      const { data: resp } = await this.$http.get('cms/doctor/doctorTeam/get', {
        params: {
          userId: this.operatorDoctor.userId
        }
      })
      this.loadingDoctorTeamManager = false
      // console.log('读取医院', resp)
      this.doctorTeams = resp.data
      setTimeout(() => {
        this.doctorTeams.forEach(row => {
          if (row.selected) {
            console.log('已选择', row)
            this.$refs.multipleTableDoctorTeams.toggleRowSelection(row)
          }
        })
      }, 50)
    },
    async changeDoctorTeam () {
      const hospitalIds = []
      this.multipleSelectionDoctorTeam.forEach(selection => {
        hospitalIds.push(selection.id)
      })
      console.log(hospitalIds)
      const rsp = await this.$http.post('cms/doctor/doctorTeam/add', {
        userId: this.operatorDoctor.userId,
        hospitalIds: hospitalIds
      })
      console.log(rsp)
      if (rsp.data.code === 200) {
        this.$message({
          message: '修改成功',
          type: 'success'
        })
        // this.dialogDoctorTeamManager = false
      } else {
        this.$message({
          message: '修改失败：' + rsp.data.message,
          type: 'error'
        })
      }
    },
    async getHosiptals () {
      const { data: resp } = await this.$http.get('cms/doctor/hospitals')
      console.log(resp)
      if (resp.code !== 200) return this.$message.error(resp.message)

      this.hospitals = resp.data
    },
    async getDoctors () {
      this.loading = true
      // this.doctors = [
      //   { name: '陈其康', hospital: '动优健康管理中心', date: '2020年8月23日', phone: '13610386482' }
      // ]
      const { data: resp } = await this.$http.get('cms/doctor/info', { params: this.requesParams })
      console.log(resp)
      if (resp.code !== 200) return this.$message.error(resp.message)

      this.onDoctorLoaded(resp)
      this.loading = false
    },
    onDoctorLoaded (resp) {
      resp.data.content.forEach(function (d) {
        d.createDate = new Date(d.createDate).toLocaleString()
      })
      this.doctors = resp.data.content
      this.total = resp.data.totalElements
      this.pageSize = resp.data.size
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
      this.requesParams.page = val
      this.getDoctors()
    },
    async onClickPhoneSearch () {
      if (!this.inputPhone) {
        this.$message({
          message: '请输入姓名或手机号进行查询',
          type: 'warning'
        })
        return
      }
      const isNumber = NumberUtil.isNumeric(this.inputPhone)
      console.log('是否是数字', isNumber)
      if (isNumber) {
        const { data: resp } = await this.$http.get('cms/doctor/search/phone', {
          params: {
            phone: this.inputPhone
          }
        })
        if (resp.code === 200) {
          this.onDoctorLoaded(resp)
        } else {
          this.$message(
            {
              message: resp.message,
              type: 'error'
            }
          )
        }
      } else {
        const { data: resp } = await this.$http.get('cms/doctor/search/name', {
          params: {
            name: this.inputPhone
          }
        })
        if (resp.code === 200) {
          this.onDoctorLoaded(resp)
        } else {
          this.$message(
            {
              message: resp.message,
              type: 'error'
            }
          )
        }
      }
    },
    async handleHospitalChange (index, row) {
      const loading = this.$message({
        message: '切换医院中',
        duration: 0
      })

      // console.log(value)
      console.log(index)
      console.log(row)

      const { data: resp } = await this.$http.get('cms/doctor/changeHospital', {
        params: {
          doctorId: row.userId,
          hospitalId: row.hospital
        }
      })

      console.log(resp)
      if (resp.code !== 200) return this.$message.error(resp.message)

      loading.close()
      this.$message({
        message: '切换成功',
        type: 'success'
      })
    }
  }

}
</script>

<style lang="less" scoped>
.el-table {
  margin-top: 15px;
  font-size: 10px;
}

//html {
//  //-webkit-user-select: text !important;
//}
</style>
