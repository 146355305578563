<template>
  <el-drawer :visible.sync="visible" size="420px" :with-header="false">
    <div class="rich-content-wrapper">
      <div class="frame">
        <rich-content class="non-selection" :rich-text="htmlContent"></rich-content>
      </div>
    </div>
  </el-drawer>
</template>

<!-- 基础富文本预览 -->
<script>
import Vue from 'vue'
import RichContent from './RichContent.vue'

export default Vue.extend({
  components: { RichContent },
  props: {
    // TODO 触发弹窗方式可优化，实现不优雅
    previewTime: Number,
    htmlContent: String
  },
  beforeDestroy () {
  },
  data () {
    return {
      visible: false
    }
  },
  watch: {
    previewTime () {
      this.visible = true
    }
  },
  methods: {}
})
</script>

<style src="./styles/rich-content-preview.less" lang="less" scoped>
</style>
