<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/menu/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>运营数据</el-breadcrumb-item>
      <el-breadcrumb-item>概览</el-breadcrumb-item>
      <el-breadcrumb-item>概览</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-input
            placeholder="请输入手机号"
            v-model="inputPhone"
          >

            <el-button
              slot="append"
              icon="el-icon-search"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="2">
          <el-button
            type="info"
            plain
          >清除输入</el-button>
        </el-col>
      </el-row>

      <el-table
        :data="doctors"
        style="width: 100%"
        stripe
        border
      >
        <el-table-column
          prop="createDate"
          label="日期"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="realname"
          label="姓名"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="phone"
          label="手机号"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="hospital"
          label="医院"
          width="280"
        >
        </el-table-column>
        <el-table-column
          prop="inviterName"
          label="邀请人"
          width="180"
        >
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="100"
        >
          <template slot-scope="scope">
            <el-button
              @click="handleClick(scope.row)"
              type="text"
              size="small"
            >查看</el-button>
            <el-button
              type="text"
              size="small"
            >编辑</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        layout="total, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </el-card>

  </div>
</template>

<script>
export default {
  data () {
    return {
      inputPhone: '',
      requesParams: {
        page: 1,
        pageSize: 10
      },
      pageSize: 0,
      total: 0,
      doctors: [],
      hospitals: []
    }
  },
  created () {
    // this.getHosiptals()
    this.getDoctors()
  },
  methods: {
    // async getHosiptals () {
    //   const { data: resp } = await this.$http.get('cms/doctor/hospitals')
    //   console.log(resp)
    //   if (resp.code !== 200) return this.$message.error(resp.message)
    //
    //   this.hospitals = resp.data
    // },
    async getDoctors () {
      // this.doctors = [
      //   { name: '陈其康', hospital: '动优健康管理中心', date: '2020年8月23日', phone: '13610386482' }
      // ]
      const { data: resp } = await this.$http.get('cms/patient/list', { params: this.requesParams })
      console.log(resp)
      if (resp.code !== 200) return this.$message.error(resp.message)

      resp.data.content.forEach(function (d) {
        d.createDate = new Date(d.createDate).toLocaleString()
      })
      this.doctors = resp.data.content
      this.total = resp.data.totalElements
      this.pageSize = resp.data.size
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
      this.requesParams.page = val
      this.getDoctors()
    }
  }

}
</script>

<style lang="less" scoped>
.el-table {
  margin-top: 15px;
  font-size: 10px;
}
</style>
