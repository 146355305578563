<template>

  <div class="container">

    <div class="box">
      <div id="tips">请输入申请训练方案时的手机号</div>
      <cube-input
        class="input_phone"
        v-model="phone"
        placeholder="手机号"
      ></cube-input>
      <cube-button
        type="submit"
        :clearable="clearable"
        style="margin-top:20px;"
        @click="submit"
      >查询</cube-button>
    </div>

  </div>

</template>

<script>

export default {

  data () {
    return {
      clearable: {
        visible: true,
        blurHidden: true
      },
      phone: ''

    }
  },
  created () {

  },
  methods: {

    async submit () {
      console.log(this.phone)
      const toast = this.$createToast({
        time: 0,
        txt: '正在为您查询',
        mask: true
      })
      toast.show()
      const { data: resp } = await this.$http.get('assessPlan/check?phone=' + this.phone)
      toast.hide()
      console.log(resp)
      if (resp.code === 200) {
        // this.$router.push('/PlanApplySuccess')
        if (resp.data > 0) {
          this.$router.push('/PlanRead2/' + this.phone)
        } else {
          this.$createDialog({
            type: 'alert',
            title: '',
            content: '您尚未获得训练方案'
          }).show()
        }
      } else {
        // this.$message.error(resp.message)
        this.$createDialog({
          type: 'alert',
          title: '',
          content: '错误：' + resp.message
        }).show()
      }
    }

  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
  background: #9ecfd5;
  position: relative;
}

.box {
  position: absolute;
  top: 100px;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
}
#tips {
  font-size: 20px;
  width: 100%;
  text-align: center;
  color: #333333;
}

.input_phone {
  font-size: 20px;
  width: 100%;
  margin-top: 20px;
  text-align: center;
}
</style>
