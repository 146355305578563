import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/Login.vue'
import Home from '../components/Home.vue'
import Welcome from '../components/Welcome.vue'
import Course from '../components/contents/course/Course.vue'
import CourseUpload from '../components/contents/course/CourseUpload.vue'
import Doctor from '../components/datas/user/Doctor.vue'
import Patient from '../components/datas/user/Patient.vue'
import Zfkp from '../components/contents/zfkp/Zfkp.vue'
import ZfkpUpload from '../components/contents/zfkp/ZfkpUpload.vue'
import Article from '../components/contents/article/Article.vue'
import ArticleUpload from '../components/contents/article/ArticleUpload.vue'
import DoctorApkUpload from '../components/system/DoctorApkUpload.vue'
import ArticleShow from '../components/contents/article/ArticleShow.vue'
import ArticleCategories from '../components/contents/article/ArticleCategories.vue'
import Live from '../components/webapp/live/Live.vue'
import Choujiang from '../components/test/Choujiang.vue'
import Plan from '../components/webapp/plan/Plan.vue'
import PlanApplySuccess from '../components/webapp/plan/PlanApplySuccess.vue'
import PlanRead1 from '../components/webapp/plan/PlanRead1.vue'
import PlanRead2 from '../components/webapp/plan/PlanRead2.vue'
import PlanRead3 from '../components/webapp/plan/PlanRead3.vue'
import OrderAll from '@/components/datas/order/OrderAll'
import Overview from '@/components/datas/Overview'
import HospitalIntroJdsy from '@/components/webapp/hospital_intro/HospitalIntroJdsy'
import RegisterHospital from '@/components/datas/hospital/RegisterHospital'
import HospitalIntroChongai from '@/components/webapp/hospital_intro/HospitalIntroChongai'
import HospitalIntroSdzyy from '@/components/webapp/hospital_intro/HospitalIntroSdzyy'
import HospitalIntroNfykdx7 from '../components/webapp/hospital_intro/HospitalIntroNfykdx7'
import OperationDoctor from '../components/datas/operation/OperationDoctor'
import HospitalIntroZjyy from '../components/webapp/hospital_intro/HospitalIntroZjyy'
import HospitalIntroGzyj from '../components/webapp/hospital_intro/HospitalIntroGzyj'
import AppDownloadCommunityOrganization
from '../components/webapp/app_download/community_organization/AppDownloadCommunityOrganization'
import HospitalIntroGzFsk from '../components/webapp/hospital_intro/HospitalIntroGzFsk'
import FeedList from '../components/contents/feeds/List.vue'
import FeedDetail from '../components/contents/feeds/Detail.vue'
import FeedTag from '../components/contents/feeds/Tag.vue'
import FeedGroup from '../components/contents/feeds/Group.vue'
import TestFileUpload from '../components/test/TestFileUpload'
import HospitalIntroDoucan from '../components/webapp/hospital_intro/HospitalIntroDoucan'
import MdtList from '../components/datas/mdt/MdtList'
import AppDownloadDoucanDoctor from '../components/webapp/app_download/AppDownloadDoucanDoctor'
import HospitalIntroDGKanghua from '../components/webapp/hospital_intro/HospitalIntroDGKanghua'
import HospitalIntroZJlinnan from '../components/webapp/hospital_intro/HospitalIntroZJlinnan'
import HospitalIntroXianTangdu from '../components/webapp/hospital_intro/HospitalIntroXianTangdu'
import HospitalIntroTjxyt from '../components/webapp/hospital_intro/HospitalIntroTjxyt'
import HospitalIntroHzsy from '../components/webapp/hospital_intro/HospitalIntroHzsy'
import CreateMallGoods from '@/components/mall/CreateMallGoods.vue'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  redirect: '/login'
}, {
  path: '/login',
  component: Login
}, {
  path: '/article/:id',
  component: ArticleShow,
  meta: {
    // 页面标题title
    title: '远程康复'
  }
}, {
  path: '/live/:channel',
  component: Live,
  meta: {
    // 页面标题title
    title: '动优直播'
  }
}, {
  path: '/hospital_intro/jdsy',
  component: HospitalIntroJdsy,
  meta: {
    // 页面标题title
    title: '暨南大学附属顺德医院康复医学科'
  }
}, {
  path: '/hospital_intro/chongai',
  component: HospitalIntroChongai,
  meta: {
    // 页面标题title
    title: '暨南大学崇爱康复医院'
  }
}, {
  path: '/hospital_intro/nfykdx7',
  component: HospitalIntroNfykdx7,
  meta: {
    // 页面标题title
    title: '南方医科大学第七附属医院'
  }
}, {
  path: '/hospital_intro/zjlnyy',
  component: HospitalIntroZJlinnan,
  meta: {
    // 页面标题title
    title: '湛江岭南骨科医院'
  }
}, {
  path: '/hospital_intro/xiantangdu',
  component: HospitalIntroXianTangdu,
  meta: {
    // 页面标题title
    title: '空军军医大学唐都医院'
  }
}, {
  path: '/hospital_intro/Tjxyt',
  component: HospitalIntroTjxyt,
  meta: {
    // 页面标题title
    title: '天津西青修园堂中医医院'
  }
}, {
  path: '/hospital_intro/Hzsy',
  component: HospitalIntroHzsy,
  meta: {
    // 页面标题title
    title: '惠州市第三人民医院'
  }
}, {
  path: '/hospital_intro/sdzyy',
  component: HospitalIntroSdzyy,
  meta: {
    // 页面标题title
    title: '广州中医药大学顺德医院康复医学科'
  }
}, {
  path: '/hospital_intro/gzfsk',
  component: HospitalIntroGzFsk,
  meta: {
    // 页面标题title
    title: '福寿康'
  }
}, {
  path: '/hospital_intro/doucan',
  component: HospitalIntroDoucan,
  meta: {
    // 页面标题title
    title: '居家康复'
  }
}, {
  path: '/hospital_intro/universalHospital',
  component: HospitalIntroDoucan,
  meta: {
    // 页面标题title
    title: '居家康复平台'
  }
}, {
  path: '/hospital_intro/dgkanghua',
  component: HospitalIntroDGKanghua,
  meta: {
    // 页面标题title
    title: '东莞康华医院居家康复'
  }
}, {
  path: '/hospital_intro/zjyy',
  component: HospitalIntroZjyy,
  meta: {
    // 页面标题title
    title: '南方医科大学珠江医院康复医学科'
  }
}, {
  path: '/hospital_intro/gzyj',
  component: HospitalIntroGzyj,
  meta: {
    // 页面标题title
    title: '广州逸景健康养老服务有限公司'
  }
}, {
  path: '/appdownload/communityOrganization',
  component: AppDownloadCommunityOrganization,
  meta: {
    // 页面标题title
    title: '动优居家康复社区端APP下载'
  }
}, {
  path: '/appdownload/doctor/doucan',
  component: AppDownloadDoucanDoctor,
  meta: {
    // 页面标题title
    title: '动优居家康复医生端APP'
  }
}, {
  path: '/test/choujiang',
  component: Choujiang,
  meta: {
    // 页面标题title
    title: '抽奖'
  }
}, {
  path: '/plan/:doctorid',
  component: Plan,
  meta: {
    // 页面标题title
    title: '康复训练方案'
  }
}, {
  path: '/PlanApplySuccess',
  component: PlanApplySuccess,
  meta: {
    // 页面标题title
    title: '康复训练方案'
  }
}, {
  path: '/PlanRead1',
  component: PlanRead1,
  meta: {
    // 页面标题title
    title: '康复训练方案'
  }
}, {
  path: '/PlanRead2/:phone',
  component: PlanRead2,
  meta: {
    // 页面标题title
    title: '康复训练方案'
  }
}, {
  path: '/PlanRead3',
  name: 'PlanRead3',
  component: PlanRead3,
  meta: {
    // 页面标题title
    title: '康复训练方案'
  }
}, {
  path: '/home',
  component: Home,
  redirect: '/menu/welcome',
  children: [{
    path: '/menu/welcome',
    component: Welcome
  }, {
    path: '/menu/content/course',
    component: Course
  }, {
    path: '/menu/content/courseUpload',
    component: CourseUpload
  }, {
    path: '/menu/content/zfkp',
    component: Zfkp
  }, {
    path: '/menu/content/zfkpUpload',
    component: ZfkpUpload
  }, {
    path: '/menu/content/article',
    component: Article
  }, {
    path: '/menu/content/articleUpload',
    component: ArticleUpload
  }, {
    path: '/menu/content/articleCategories',
    component: ArticleCategories
  }, {
    path: '/menu/content/feeds',
    component: FeedList
  }, {
    path: '/menu/content/feeds/detail',
    component: FeedDetail
  }, {
    path: '/menu/content/feeds/tag',
    component: FeedTag
  }, {
    path: '/menu/content/feeds/group',
    component: FeedGroup
  }, {
    path: '/menu/data/overview',
    component: Overview
  }, {
    path: '/menu/data/user/doctor',
    component: Doctor
  }, {
    path: '/menu/data/user/patient',
    component: Patient
  }, {
    path: '/menu/data/mdt/list',
    component: MdtList
  }, {
    path: '/menu/data/hospital/register',
    component: RegisterHospital
  }, {
    path: '/menu/data/operation/doctor',
    component: OperationDoctor
  }, {
    path: '/menu/data/order/all',
    component: OrderAll
  }, {
    path: '/menu/system/doctorApkUpload',
    component: DoctorApkUpload
  }, {
    path: '/menu/test/TestFileUpload',
    component: TestFileUpload
  }, {
    path: '/menu/mall/CreateMallGoods',
    component: CreateMallGoods
  }]
}

]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path.search('menu') !== -1) {
    window.sessionStorage.setItem('menuActivePath', to.path)
  }

  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  } else {
    // document.title = '健而康科技管理系统'
    document.title = '动优后台管理系统'
  }

  if (to.path === '/login') return next()
  if (to.path.search('/hospital_intro/') !== -1) return next()
  if (to.path.search('/appdownload/') !== -1) return next()
  if (to.path.search('/article/') !== -1) return next()
  if (to.path.search('/plan/') !== -1) return next()
  if (to.path.search('/PlanApplySuccess') !== -1) return next()
  if (to.path.search('/PlanRead1') !== -1) return next()
  if (to.path.search('/PlanRead2') !== -1) return next()
  if (to.path.search('/PlanRead3') !== -1) return next()
  if (to.path.search('/live/') !== -1) return next()
  if (to.path.search('choujiang') !== -1) return next()
  const token = window.sessionStorage.getItem('token')
  if (!token) return next('/login')
  next()
})

export default router
