<template>
  <div class="container">

    <div class="box">
      <div id="tips">我的康复训练方案</div>

      <div class="list">
        <div
          v-for="(item,index) of plans"
          :key="index"
        >

          <el-card class="box-card">
            <div
              class="item"
              @click="planItemClick(item)"
            >
              <div class="doctor">
                治疗师/医生：{{item.doctorName}}
              </div>

              <div
                class="time"
                style="margin-top:10px"
              >
                定制日期：{{item.gmtCreate}}
              </div>

            </div>
          </el-card>

        </div>
      </div>

    </div>
  </div>
</template>

<script>

export default {

  data () {
    return {
      phone: '',
      name: 'test',
      plans: []

    }
  },
  created () {
    this.phone = this.$route.params.phone
    this.submit()
  },
  methods: {
    planItemClick (item) {
      console.log(item)
      this.$router.push({
        name: 'PlanRead3', // 这个name就是你刚刚配置在router里边的name
        params: {
          plan: item
        }
      })
    },
    async submit () {
      const toast = this.$createToast({
        time: 0,
        txt: '正在读取训练方案',
        mask: true
      })
      toast.show()
      const { data: resp } = await this.$http.get('assessPlan/find?phone=' + this.phone)
      toast.hide()
      console.log(resp)
      if (resp.code === 200) {
        // this.$router.push('/PlanApplySuccess')
        if (resp.data.length > 0) {
          resp.data.forEach(e => {
            const date = new Date(e.gmtCreate)
            var year = date.getFullYear() // 获取完整的年份(4位)
            var month = date.getMonth() + 1 // 获取当前月份(0-11,0代表1月)
            var day = date.getDate() // 获取当前日(1-31)
            var hour = date.getHours() // 获取当前小时数(0-23)
            var min = date.getMinutes() // 获取当前分钟数(0-59)
            e.gmtCreate = year + '年' + month + '月' + day + '日' + ' ' + hour + '时' + min + '分'
          })

          this.plans = resp.data
        } else {
          this.$createDialog({
            type: 'alert',
            title: '',
            content: '您尚未获得训练方案'
          }).show()
        }
      } else {
        // this.$message.error(resp.message)
        this.$createDialog({
          type: 'alert',
          title: '',
          content: '错误：' + resp.message
        }).show()
      }
    }

  }
}
</script>

<style lang="less" scoped>
html {
  background: #9ecfd5;
}

.container {
  width: 100%;
  background: #9ecfd5;
  height: 100%;
  position: relative;
}

.box {
  position: absolute;
  background: #9ecfd5;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
}

#tips {
  font-size: 24px;
  width: 100%;
  text-align: center;
  color: aliceblue;
}
.box-card {
  margin-top: 10px;
}
.list {
  margin-top: 20px;
}
.item {
}

.doctor,
.time {
  font-size: 20px;
  line-height: 22px;
}
</style>
