<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/menu/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>内容管理</el-breadcrumb-item>
      <el-breadcrumb-item>训练视频管理</el-breadcrumb-item>
      <el-breadcrumb-item>训练视频库</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row>
      <!--      <el-button>默认按钮</el-button>-->
      <el-button type="primary" @click="handleAddFirstSort">添加一级分类</el-button>
      <el-button type="success" @click="batchUploadVideo">批量上传视频</el-button>
      <el-button type="success" @click="updateCache">更新视频缓存</el-button>
      <!--      <el-button type="info">信息按钮</el-button>-->
      <!--      <el-button type="warning">警告按钮</el-button>-->
      <!--      <el-button type="danger">危险按钮</el-button>-->
    </el-row>
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      @select="handleSelect"
    >
      <el-menu-item
        :index="item.id+''"
        v-for="item in firstSorts"
        :key="item.id"
        @click="onFirstSortClick(item)"
      >{{ item.sortName }}
      </el-menu-item>

    </el-menu>
    <el-container style="height: 700px; border: 1px solid #eee">

      <el-container>
        <el-aside
          width="100px"
          style="border-right:1px dashed #07A08A "
        >

          <el-menu default-active="1">
            <el-menu-item
              :index="item.id+''"
              v-for="item in secondSorts"
              :key="item.id"
              @click="onSecondSortClick(item)"
            >{{ item.sortName }}
            </el-menu-item>
          </el-menu>
          <el-button type="primary" @click="addSecondSort">添加二<br/>级分类</el-button>
        </el-aside>
        <el-main>
          <el-table
            :data="courses"
            style="width: 100%;"
            height="630"
            stripe
            border
          >
            <el-table-column
              prop="modifiedDate"
              label="编辑时间"
              width="120"
            >
            </el-table-column>

            <el-table-column
              prop="courseNum"
              label="编号"
              width="80"
            >
            </el-table-column>
            <el-table-column
              label="视频名称"
              width="174"
            >
              <template slot-scope="scope">

                <el-link
                  slot="reference"
                  class="name-wrapper"
                  :href="scope.row.videoUrl "
                  target="_blank"
                > {{ scope.row.title }}
                </el-link>

              </template>
            </el-table-column>

            <el-table-column
              prop="lable_1"
              label="标签1"
              width="70"
            >
            </el-table-column>
            <el-table-column
              prop="lable_2"
              label="标签2"
              width="70"
            >
            </el-table-column>
            <el-table-column
              prop="lable_3"
              label="标签3"
              width="70"
            >
            </el-table-column>
            <el-table-column
              prop="trainingMethods"
              label="训练方法"
              width="150"
            >
            </el-table-column>

            <el-table-column
              prop="explains"
              label="说明"
              width="180"
            >

            </el-table-column>
            <el-table-column
              prop="text"
              label="步骤要点"
              width="220"
            ></el-table-column>

          </el-table>

          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-size="pageSize"
            layout="total, prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </el-main>
      </el-container>
    </el-container>
    <el-dialog title="添加一级分类" :visible.sync="dialogAddFirstSort" @open="onAddFirstSortOpen">
      <el-card>
        <div style="width: 50%">
          <el-input v-model="newFirstSort" placeholder="请输入一级分类名称"></el-input>
        </div>
      </el-card>
      <el-row style="margin-top: 32px" type="flex" justify="end">
        <el-button type="info" @click="dialogAddFirstSort = false">取消</el-button>
        <el-button type="primary" @click="submitFirstSortAdd">确定</el-button>
      </el-row>
    </el-dialog>
    <el-dialog title="添加二级分类" :visible.sync="dialogAddSecondSort" @open="onAddFirstSortOpen">
      <el-card>
        <div style="width: 50%">
          <el-input v-model="newSecondSort" placeholder="请输入二级分类名称"></el-input>
        </div>
      </el-card>
      <el-row style="margin-top: 32px" type="flex" justify="end">
        <el-button type="info" @click="dialogAddSecondSort = false">取消</el-button>
        <el-button type="primary" @click="submitSecondSortAdd">确定</el-button>
      </el-row>
    </el-dialog>

    <el-dialog title="批量上传视频" :visible.sync="dialogBatchUpload" @open="onDialogBatchUploadOpen">
      <div v-if="uploadDone" style="font-weight: bolder;font-size: 32px;color: #00cc00">上传完成！！！</div>
      <div v-if="selecSecondSort">上传视频到：{{ `${selecFirstSort.sortName} - ${selecSecondSort.sortName}` }}</div>
      <file-select @chooseFile="onFileChoose" style="margin-top: 16px">
        <el-button type="primary">选取Excel文档</el-button>
      </file-select>
      <file-select :show-file="false" @chooseFile="onVideoFilesChoose" style="margin-top: 16px">
        <el-button type="primary">选择视频文件列表</el-button>
      </file-select>
      <el-card style="margin-top: 16px">
        <el-table
          :data="batchUploadTableData"
          border
          style="width: 100%">
          <el-table-column
            label="入库"
            width="50">
            <template slot-scope="scope">
              <div v-if="scope.row.done" style="color: #00cc00;font-weight: bolder">完成</div>
              <div v-if="scope.row.error">{{ scope.row.error }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="percent"
            label="上传进度"
            width="50">
          </el-table-column>
          <el-table-column
            prop="number"
            label="编号"
            width="180">
          </el-table-column>
          <el-table-column
            prop="title"
            label="标题"
            width="180">
          </el-table-column>
          <el-table-column
            prop="descp"
            label="说明"
            width="180">
          </el-table-column>
          <el-table-column
            prop="step"
            label="步骤要点"
            width="180">
          </el-table-column>
          <el-table-column
            prop="trainMethod"
            label="训练方法"
            width="180">
          </el-table-column>
          <el-table-column
            prop="tag1"
            label="标签1"
            width="180">
          </el-table-column>
          <el-table-column
            prop="tag2"
            label="标签2(主被动)"
            width="180">
          </el-table-column>
          <el-table-column
            prop="tag3"
            label="标签3"
            width="180">
          </el-table-column>
          <el-table-column
            label="文件"
            width="180">
            <template slot-scope="scope">
              <div v-if="scope.row.file">{{ scope.row.file.name }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="duration"
            label="duration（秒）"
            width="180">
          </el-table-column>
          <el-table-column
            label="videoUrl"
            width="180">
            <template slot-scope="scope">
              <a :href="scope.row.videoUrl" target="_blank">{{ scope.row.videoUrl }}</a>
            </template>
          </el-table-column>
          <el-table-column
            label="coverUrl"
            width="180">
            <template slot-scope="scope">
              <a :href="scope.row.coverUrl" target="_blank">{{ scope.row.coverUrl }}</a>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
      <el-row style="margin-top: 32px" type="flex" justify="end">
        <el-button type="info" @click="dialogBatchUpload = false">取消</el-button>
        <el-button type="primary" @click="submitBatchUpload">确定</el-button>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>

import FileSelect from '../../../common-components/FileSelect'
// import { read, writeFileXLSX } from 'xlsx'
import * as XLSX from 'xlsx'

export default {
  components: { FileSelect },
  data () {
    return {
      selecFirstSort: null,
      selecSecondSort: null,
      activeFirstSort: null,
      activeIndex: '1',
      firstSorts: [],
      secondSorts: [],
      isFirst: true,
      courseQueryParams: {
        secondSortId: '',
        page: '1',
        pageSize: 10
      },
      courses: [],
      pageSize: 0,
      total: 0,
      dialogAddFirstSort: false,
      dialogAddSecondSort: false,
      newFirstSort: '',
      newSecondSort: '',
      dialogBatchUpload: false,
      fileList: [],
      batchUploadTableDataTitles: [],
      batchUploadTableData: [],
      uploadDone: false
    }
  },
  created () {
    this.getFirstSort()
  },
  methods: {
    async submitSecondSortAdd () {
      console.log('添加二级分类', this.newSecondSort)
      if (!this.newSecondSort) {
        // Notify({
        //   message: '不能为空',
        //   type: 'warning'
        // })
        this.$message({
          message: '不能为空',
          type: 'warning'
        })
        return
      }
      console.log(this.newSecondSort)
      const { data: resp } = await this.$http.get('/course/doctor/add/second_sorts2', {
        params: {
          firstId: this.activeFirstSort,
          secondSortName: this.newSecondSort
        }
      })
      console.log('添加二级分类 http', resp)
      if (resp.code === 200) {
        this.$message.success('成功')
      }
    },
    addSecondSort () {
      console.log('添加二级分类', this.activeFirstSort)
      this.dialogAddSecondSort = true
    },
    async submitFirstSortAdd () {
      console.log('添加一级分类', this.newFirstSort)
      if (!this.newFirstSort) {
        // Notify({
        //   message: '不能为空',
        //   type: 'warning'
        // })
        this.$message({
          message: '不能为空',
          type: 'warning'
        })
        return
      }
      const { data: resp } = await this.$http.get('/course/doctor/add/first_sorts2', {
        params: {
          firstSortName: this.newFirstSort
        }
      })
      if (resp.code === 200) {
        this.$message.success('成功')
      }
      console.log('添加一级分类 http', resp)
    },
    handleAddFirstSort () {
      this.dialogAddFirstSort = true
    },
    onAddFirstSortOpen () {
      console.log('onAddFirstSortOpen')
    },
    async getFirstSort () {
      const { data: resp } = await this.$http.get('cms/course/firstsort')
      console.log(resp)
      if (resp.code !== 200) return this.$message.error(resp.message)
      this.firstSorts = resp.data
      if (this.isFirst) {
        this.isFirst = false
        this.getSecondSort(this.firstSorts[0].id)
      }
    },
    handleSelect (key, keyPath) {
      console.log('点击了一级分类 key', key)
      console.log('点击了一级分类 keyPath', keyPath)
      this.activeFirstSort = keyPath[0]
      if (this.activeIndex === keyPath[0]) {
        return this.$message({
          message: '已在当前分类',
          type: 'success'
        })
      }
      this.activeIndex = keyPath[0]
      this.getSecondSort(keyPath[0])
    },
    async getSecondSort (firstSort) {
      const { data: resp } = await this.$http.get('cms/course/secondSort', { params: { firstSortId: firstSort } })
      console.log(resp)
      this.secondSorts = resp.data.secondSorts
    },
    onFirstSortClick (firstSort) {
      this.selecFirstSort = firstSort
    },
    onSecondSortClick (secondSort) {
      this.selecSecondSort = secondSort
      console.log('当前选择二级分类', secondSort)
      // 读取课程列表
      if (this.courseQueryParams.secondSortId === secondSort.id) return console.log('已在当前分类')

      this.courseQueryParams.secondSortId = secondSort.id
      this.courseQueryParams.page = 1

      console.log(JSON.stringify(secondSort))
      this.getCourses()
    },
    async getCourses () {
      const { data: resp } = await this.$http.get('cms/course/list', { params: this.courseQueryParams })
      console.log(resp)

      resp.data.data.forEach(course => {
        course.modifiedDate = new Date(course.modifiedDate).toLocaleDateString() + ' ' + new Date(course.modifiedDate).toLocaleTimeString()
        course.lables.forEach(lable => {
          if (lable.level === 1) (course.lable_1 = lable.name)
          if (lable.level === 2) (course.lable_2 = lable.name)
          if (lable.level === 3) (course.lable_3 = lable.name)
        })
      })

      this.courses = resp.data.data
      this.pageSize = resp.data.pageSize
      this.total = resp.data.total
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
      this.courseQueryParams.page = val
      this.getCourses()
    },
    async updateCache () {
      const resp = await this.$http.get('cms/course/updateCache')
      if (resp.data.code === 200) {
        this.$message.success('更新成功')
      }
    },
    batchUploadVideo () {
      // 选择上传Excel
      // 选择上传的视频文件夹
      // 展示Excel的课程信息
      // 上传视频文件到oss
      // 获取封面
      // 上传封面到oss
      // 确认信息无误则可以上传到服务器数据库
      console.log('批量上传 - 选择Excel')
      this.dialogBatchUpload = true
    },
    onDialogBatchUploadOpen () {
      this.uploadDone = false
      console.log('批量上传 一级分类', this.selecFirstSort)
      console.log('批量上传 二级分类', this.selecSecondSort)
      console.log(`批量上传 open ${this.selecFirstSort.name} - ${this.selecSecondSort.sortName}`)
    },
    submitBatchUpload () {
      console.log('批量上传 submit')
      this.upload()
    },
    handlePreview (file) {
      console.log(file)
    },
    onFileChoose (file) {
      const _that = this
      _that.batchUploadTableData = []
      console.log('父组件-File选择器', file)
      this.readWorkbookFromLocalFile(file[0], (wb) => {
        console.log('读取的Excel', wb)
        const ws = wb.Sheets[wb.SheetNames[0]]
        /* generate array of arrays from the first worksheet */
        // const aoa = XLSX.utils.sheet_to_json(ws, { header: 1 })
        const aoa = XLSX.utils.sheet_to_json(ws)
        console.log('sheet to json', aoa)
        aoa.forEach(d => {
          const item = {
            number: d['编号'],
            title: d['标题'],
            trainMethod: d['训练方法'],
            descp: d['说明'],
            step: d['步骤要点'],
            tag1: d['标签1'],
            tag2: d['标签2(主被动)'],
            tag3: d['标签3'],
            file: null,
            duration: 0,
            videoUrl: null,
            coverUrl: null,
            percent: 0,
            size: 0,
            done: false,
            error: ''
          }
          _that.batchUploadTableData.push(item)
        })
      })
    },
    // 读取本地excel文件
    readWorkbookFromLocalFile (file, callback) {
      var reader = new FileReader()
      reader.onload = function (e) {
        var data = e.target.result
        var workbook = XLSX.read(data, { type: 'binary' })
        if (callback) callback(workbook)
      }
      reader.readAsBinaryString(file)
    },
    async onVideoFilesChoose (files) {
      console.log('视频文件列表', files)
      // const that = this
      for (const file of files) {
        const number = file.name.split('-')[0]
        for (const data of this.batchUploadTableData) {
          if (data.number.toUpperCase() === number.toUpperCase()) {
            data.file = file
            data.size = file.size
            /**
             * 获取视频文件时长
             */
            var url = URL.createObjectURL(file)
            var audioElement = new Audio(url)
            // var ele = this.$refs.dur
            audioElement.addEventListener('loadedmetadata', function (_event) {
              const duration = audioElement.duration * 1000
              console.log('视频长度', duration)
              data.duration = duration // 时长为秒，小数，182.36
              // ele.name = duration // 目前使用这种方式解决js与vue之间的传值问题
              // this.$parent.$data.wDuration = parseInt(duration) //注意传递给接口的时长字段类型要与接口接收的字段类型一致，不然无法获取到值
            })

            // eslint-disable-next-line promise/param-names
            await new Promise(r => setTimeout(r, 300))
          }
        }
      }
    },
    async upload () {
      for (const data of this.batchUploadTableData) {
        const file = data.file
        if (!file) {
          continue
        }
        // 上传视频到阿里云，并获取封面
        const result = await this.uploadToAliyun(file, data)
        console.log('上传视频结果', result)
        const videourl = result.res.requestUrls[0].substring(0, result.res.requestUrls[0].indexOf('?'))
        console.log('【视频URL】', videourl)
        data.videoUrl = videourl
        const covername = file.name.substring(0, file.name.indexOf('.')) + '.jpeg'
        console.log('covername', covername)
        const coverResp =
          await this.capture(result, covername, data)
        data.coverUrl = coverResp.res.requestUrls[0].substring(0, coverResp.res.requestUrls[0].indexOf('?'))
        console.log('封面url', data.coverUrl)
      }
      // 上传到服务器
      await this.postVideos()
    },
    async uploadToAliyun (file, data) {
      const OSS = require('ali-oss')
      const ossConfig = {
        // region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
        region: 'oss-cn-shenzhen',
        // 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录RAM控制台创建RAM账号。
        accessKeyId: 'LTAIAcClE56I1mY0',
        accessKeySecret: 'IIPoiW0o3noCHU9bRT5vyAw0EDtem8',
        bucket: 'ihealthy'
      }
      const client = new OSS(ossConfig)
      // console.log(file)
      // 分片上传文件
      const ret = await client.multipartUpload('video/courses/' + file.name, file, {
        progress: async function (p) {
          // console.log('multipartUpload', p)
          const e = {}
          e.percent = parseInt(p * 100)
          console.log(`上传进度: ${e.percent} - ${data.title} `)
          data.percent = e.percent
          // option.onProgress(e)
        }
      })
      console.log('上传结果', ret)
      if (ret.res.statusCode === 200) {
        // option.onSuccess(ret)
        console.log('上传成功', ret)
        return ret
      } else {
        // vm.disabled = false
        // option.onError('上传失败')
        console.log('上传成功', ret)
      }
      return 'bababa'
    },
    // 截取封面
    async capture (response, covername, data) {
      const objectName = response.name

      // 创建OSSClient实例。
      const OSS = require('ali-oss')
      const client = new OSS({
        accessKeyId: 'LTAIAcClE56I1mY0',
        accessKeySecret: 'IIPoiW0o3noCHU9bRT5vyAw0EDtem8',
        bucket: 'ihealthy',
        endpoint: 'http://oss-cn-shenzhen.aliyuncs.com'
      })

      // 设置视频截帧操作。
      const style = 'video/snapshot,t_2000,f_jpg,w_1280,h_720,m_fast'
      // 过期时间10分钟, 图片处理式样"image/resize,w_300"
      const signUrl = client.signatureUrl(objectName, {
        expires: 600,
        process: style
      })
      console.log('signUrl=' + signUrl)

      // eslint-disable-next-line no-unused-vars
      let imageFile = null
      imageFile = await this.getImageFileFromUrl(signUrl, covername)
      // 上传封面
      // console.log(file)
      // 分片上传文件
      const ret = await client.multipartUpload('video/courses/covers/' + imageFile.name, imageFile, {
        progress: async function (p) {
          // console.log('multipartUpload', p)
          const e = {}
          e.percent = parseInt(p * 100)
          // option.onProgress(e)
          console.log('封面上传中', e.percent)
        }

      })

      if (ret.res.statusCode === 200) {
        // option.onSuccess(ret)
        return ret
      } else {
        // vm.disabled = false
        // option.onError('上传失败')
      }
    },
    /**
     * 根据图片url转为png文件对象
     * @param url
     * @param imageName
     * @returns {Promise<unknown>}
     */
    getImageFileFromUrl (url, imageName) {
      // await new Promise(r => setTimeout(r, 300))
      return new Promise((resolve, reject) => {
        var blob = null
        var xhr = new XMLHttpRequest()
        xhr.open('GET', url)
        xhr.setRequestHeader('Accept', 'image/png')
        xhr.responseType = 'blob'
        // 加载时处理
        xhr.onload = () => {
          // 获取返回结果
          blob = xhr.response
          const imgFile = new File([blob], imageName, { type: 'image/png' })
          // 返回结果
          resolve(imgFile)
        }
        xhr.onerror = (e) => {
          reject(e)
        }
        // 发送
        xhr.send()
      })
    },
    async postVideos () {
      for (const data of this.batchUploadTableData) {
        const courseInfo = {
          videoUrl: data.videoUrl,
          coverUrl: data.coverUrl,
          courseNum: data.number,
          firstSort: this.selecFirstSort.courseSortId,
          secondSort: this.selecSecondSort.courseSortId,
          label_1: data.tag1,
          label_2: data.tag2,
          label_3: data.tag3,
          introduce: data.descp,
          trainingMethod: data.trainMethod,
          stepPoints: data.step,
          duration: data.duration,
          size: data.size,
          name: data.title
        }
        console.log('上传数据库', courseInfo)
        const { data: resp } = await this.$http.post('cms/course/add/web/batch', courseInfo)
        if (resp.code === 200) {
          data.done = true
        } else {
          data.error = resp.message
        }
      }
      this.uploadDone = true
      this.$message({
        message: '上传完毕！',
        type: 'success'
      })
    }
  }
}
</script>

<style lang="less" scoped>
.el-table {
  font-size: 10px;
}
</style>
