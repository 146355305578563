<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/menu/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>内容管理</el-breadcrumb-item>
      <el-breadcrumb-item>中风科普</el-breadcrumb-item>
      <el-breadcrumb-item>已上架内容</el-breadcrumb-item>
    </el-breadcrumb>

    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      @select="onSortSelected"
    >
      <el-menu-item
        :index="item.id+''"
        v-for="item in sorts"
        :key="item.id"
      >{{item.title}}</el-menu-item>

    </el-menu>

    <el-table
      :data="contents"
      style="width: 100%;"
      height="630"
      stripe
      border
    >
      <el-table-column
        prop="gmtCreate"
        label="上传时间"
        width="120"
      >
      </el-table-column>

      <el-table-column
        prop="index"
        label="序号"
        width="80"
      >
      </el-table-column>
      <el-table-column
        label="标题"
        width="250"
      >
        <template slot-scope="scope">

          <el-link
            slot="reference"
            class="name-wrapper"
            :href="scope.row.fileUrl "
            target="_blank"
          > {{ scope.row.title }}</el-link>

        </template>
      </el-table-column>

      <el-table-column
        prop="duration"
        label="时长"
        width="100"
      ></el-table-column>

      <el-table-column
        prop="text"
        label="内容"
        width="700"
      ></el-table-column>

    </el-table>

    <!-- <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-size="pageSize"
      layout="total, prev, pager, next"
      :total="total"
    >
    </el-pagination> -->

  </div>
</template>

<script>
export default {
  data () {
    return {
      activeIndex: '0',
      sorts: [],
      contents: []
    }
  },
  created () {
    this.getSorts()
  },
  methods: {
    onSortSelected (key, keyPath) {
      console.log(key)
      console.log(keyPath)
      if (this.activeIndex === keyPath[0]) {
        return this.$message({
          message: '已在当前目录',
          type: 'success'
        })
      }
      this.activeIndex = keyPath[0]
      this.getContents(key)
    },
    async getSorts () {
      const { data: resp } = await this.$http.get('cms/zfkp/sorts')
      console.log(resp)
      this.sorts = resp.data
    },
    async getContents (key) {
      const { data: resp } = await this.$http.get('cms/zfkp/contents', { params: { sortId: key } })
      console.log(resp)
      resp.data.forEach(e => {
        e.gmtCreate = new Date(e.gmtCreate).toLocaleDateString() + ' ' + new Date(e.gmtCreate).toLocaleTimeString()
        e.duration = this.formatDuration(e.duration * 1000)
      })
      this.contents = resp.data
    },
    formatDuration (mss) {
      // const days = Math.floor(mss / (1000 * 60 * 60 * 24))
      // const hours = Math.floor((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const minutes = Math.floor((mss % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.round((mss % (1000 * 60)) / 1000)
      return minutes + '分钟' + seconds + '秒'
    }

  }

}
</script>

<style lang="less" scoped>
</style>
