<template>
  <!--湛江岭南骨科医院-->
  <div class="container">
    <div class="tips" v-if="showTip2">{{ tips }}</div>
    <div style="padding-bottom: 100px">
      <img class="img1"
           src="https://cdn.keihong.tech/typora/2022-11-29-Xnip2022-11-29_15-43-33.jpg">
      <div style="text-align: center;font-size: 32px;font-weight: bold;margin-bottom: 16px;color: #8d4796">
        康复医学科
      </div>
      <div style="text-align: center;font-size: 32px;margin-bottom: 8px;color: #0c4fa8">
        远程居家康复
      </div>
      <img class="img1"
           src="https://cdn.keihong.tech/typora/2022-10-09-%E7%A7%91%E5%AE%A4%E6%89%AB%E7%A0%81%E4%BB%8B%E7%BB%8D%E5%9B%BE_split_1.jpg">

      <img class="img1"
           src="https://cdn.keihong.tech/typora/2022-10-09-%E7%A7%91%E5%AE%A4%E6%89%AB%E7%A0%81%E4%BB%8B%E7%BB%8D%E5%9B%BE_split_2.jpg">

      <img class="img1"
           src="https://cdn.keihong.tech/typora/2022-10-09-%E7%A7%91%E5%AE%A4%E6%89%AB%E7%A0%81%E4%BB%8B%E7%BB%8D%E5%9B%BE_split_3.jpg">

      <img class="img1"
           src="https://cdn.keihong.tech/typora/2022-10-09-%E7%A7%91%E5%AE%A4%E6%89%AB%E7%A0%81%E4%BB%8B%E7%BB%8D%E5%9B%BE_split_4.jpg">

    </div>
    <div class="download-box" @click="download">点击立即下载居家康复APP</div>
    <van-popup position="top" class="pop-tips" v-model="showTip">点击右上角按钮【···】，在弹出的菜单中，点击「在浏览器中打开」，即可安装
    </van-popup>
  </div>

</template>

<script>
import { Dialog } from 'vant'

export default {

  data () {
    return {
      isWeixin: false,
      isAndroid: false,
      isMac: false,
      showTip: false,
      showTip2: false,
      where: '',
      tips: '点击右上角按钮【···】，在弹出的菜单中，点击「在浏览器中打开」，即可安装'

    }
  },
  created () {
    const { userAgent } = navigator
    this.isWeixin = !!/MicroMessenger/i.test(userAgent)
    this.isAndroid =
      userAgent.indexOf('Android') > -1 || userAgent.indexOf('Adr') > -1
    this.isMac = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  },
  methods: {
    async download () {
      console.log('下载')
      // 检查当前是否在微信，若在微信提示跳转到浏览器
      if (this.isWeixin) {
        // var tip = 1
        // if (tip) {
        if (!this.isAndroid) {
          await Dialog({ message: '暂不支持苹果手机' })
        } else {
          // 如果是微信环境，就弹出遮罩层
          this.showTip = true
          this.showTip2 = true
          console.log('weixin')
          this.where = '微信'
        }
      } else {
        if (!this.isAndroid) {
          await Dialog({ message: '暂不支持苹果手机' })
        } else {
          console.log('浏览器')
          this.where = '浏览器'
          const { data: resp } = await this.$http.get('version/update?userType=' + 2)
          console.log(resp.data.url)
          this.tips = '正在下载中，请稍后，下载完毕会自动弹出安装提示...'
          this.showTip2 = true
          window.location.href = resp.data.url
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  //width: 100vw;
  height: 100%;
  position: relative;
  //background-image: url("https://cdn.keihong.tech/typora/2022-04-05-%E9%95%BF%E5%9B%BEdemo.jpeg");
}

.van-dialog__message {
  font-size: 22px !important;
  padding: 0 !important;
}

.pop-tips {
  box-sizing: border-box;
  line-height: 28px;
  background-color: darkred;
  color: white;
  height: 20%;
  padding: 16px;
  font-size: 22px;
}

.tips {
  padding: 16px;
  background-color: darkred;
  color: white;
  font-size: 22px;
  line-height: 30px;
}

.img1 {
  display: block;
  width: 100%;
  height: auto;

}

.img-bottom {
  margin-bottom: 100px;
}

.download-box {
  bottom: 0;
  width: 100%;
  text-align: center;
  line-height: 100px;
  background-color: #24a631;
  font-size: 28px;
  color: white;
  font-weight: bolder;
  height: 100px;
  position: fixed;
}
</style>
