<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/menu/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>相关业务操作</el-breadcrumb-item>
      <el-breadcrumb-item>医生</el-breadcrumb-item>
      <el-breadcrumb-item>价格设置</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <div>医生：{{ name }}</div>
      <el-form ref="form" :model="formPrice" label-width="120px">
        <el-form-item label="医生手机号">
          <el-input v-model="formPrice.phone" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="单次价格">
          <el-input v-model="formPrice.p1" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="月度价格">
          <el-input v-model="formPrice.p2" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="季度价格">
          <el-input v-model="formPrice.p3" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmitPrice">提交</el-button>
        </el-form-item>
      </el-form>

    </el-card>
  </div>
</template>

<script>
export default {
  name: 'OperationDoctor',
  data () {
    return {
      fromPhone: '',
      doctorId: '',
      name: '',
      formPrice: {
        phone: '',
        p3: '',
        p2: '',
        p1: ''
      }
    }
  },
  mounted () {
    const query = this.$route.query
    if (query?.phone) {
      this.fromPhone = query.phone
    }
    if (query?.doctorId) {
      this.doctorId = query.doctorId
    }
    if (query?.name) {
      this.name = query.name
    }

    this.getPrice()
  },
  methods: {
    async getPrice () {
      if (!this.fromPhone) {
        return
      }
      if (!this.doctorId) {
        return
      }
      this.formPrice.phone = this.fromPhone
      const resp = await this.$http.get(`doctor/serve/prices/cms?doctorId=${this.doctorId}`)
      console.log('获取医生价格', resp)
      const data = resp.data
      if (data.code !== 200) {
        return this.$message.error(data.message)
      }
      console.log(data)
      this.formPrice.p1 = data.data['1']
      this.formPrice.p2 = data.data['2']
      this.formPrice.p3 = data.data['3']
    },
    async onSubmitPrice () {
      console.log('提交价格', this.formPrice)
      const { data: resp } = await this.$http.post(`doctor/serve/prices/cms?phone=${this.formPrice.phone}&p1=${this.formPrice.p1}&p2=${this.formPrice.p2}&p3=${this.formPrice.p3}`)
      console.log(resp)
      if (resp.code !== 200) return this.$message.error(resp.message)
      this.$message({
        message: '修改成功',
        type: 'success'
      })
    }
  }
}
</script>

<style scoped>

</style>
