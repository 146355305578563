<template>
  <div>

    <el-breadcrumb
      separator-class="el-icon-arrow-right"
      style="margin-bottom:50px;"
    >
      <el-breadcrumb-item :to="{ path: '/menu/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>内容管理</el-breadcrumb-item>
      <el-breadcrumb-item>资讯文章</el-breadcrumb-item>
      <el-breadcrumb-item>分类|标签</el-breadcrumb-item>
    </el-breadcrumb>

    <el-divider content-position="left">入院宣教 分类|标签管理</el-divider>

    <el-row>
      <el-col :span="8">
        <div class="title title_first">
          <el-row>
            <el-col :span="18">
              <div>一级分类</div>
            </el-col>
            <el-col :span="6">
              <div><i
                  class="el-icon-circle-plus"
                  style="cursor: pointer;"
                  @click="ruyuanFirstAddClick"
                ></i></div>
            </el-col>
          </el-row>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="title title_second">
          <el-row>
            <el-col :span="18">
              <div>二级分类</div>
            </el-col>
            <el-col :span="6">
              <div><i
                  class="el-icon-circle-plus"
                  style="cursor: pointer;"
                  @click="ruyuanSecondAddClick"
                ></i></div>
            </el-col>
          </el-row>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="title title_label">
          <el-row>
            <el-col :span="18">
              <div>标签</div>
            </el-col>
            <el-col :span="6">
              <div><i
                  class="el-icon-circle-plus"
                  style="cursor: pointer;"
                  @click="ruyuanLabelAddClick"
                ></i></div>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8">
        <div>
          <el-table
            :show-header='false'
            max-height="500"
            ref="ruyuanFirstTable"
            :data="ruyuanFirst"
            border
            highlight-current-row
            @current-change="onRuyuanFirstClick"
            style="width: 100%;"
            :row-class-name="tableRowClassName"
          >
            <el-table-column
              type="index"
              width="50"
            >
            </el-table-column>
            <el-table-column
              property="firstName"
              label="一级分类名"
              width="100"
            >
            </el-table-column>
            <el-table-column label="状态">

              <template slot-scope="scope">
                <el-button
                  size="mini"
                  slot="reference"
                  class="name-wrapper"
                  @click="onActiveFirstClick(scope.$index, scope.row)"
                  :type="scope.row.active ? 'success' : 'info'"
                >{{scope.row.active ? '启用中':'未启用'}}</el-button>

              </template>

            </el-table-column>

          </el-table>
        </div>
      </el-col>
      <el-col :span="8">
        <div>
          <el-table
            :show-header='false'
            max-height="500"
            ref="ruyuanSecondTable"
            :data="ruyuanSecond"
            border
            highlight-current-row
            @current-change="onRuyuanSecondClick"
            style="width: 100%"
            :row-class-name="tableRowClassName"
          >
            <el-table-column
              type="index"
              width="50"
            >
            </el-table-column>
            <el-table-column
              property="secName"
              label="二级分类名"
              width="100"
            >
            </el-table-column>
            <el-table-column label="状态">

              <template slot-scope="scope">
                <el-button
                  size="mini"
                  slot="reference"
                  class="name-wrapper"
                  @click="onActiveSecondClick(scope.$index, scope.row)"
                  :type="scope.row.active ? 'success' : 'info'"
                >{{scope.row.active ? '启用中':'未启用'}}</el-button>

              </template>

            </el-table-column>

          </el-table>
        </div>
      </el-col>
      <el-col :span="8">
        <div>
          <el-table
            :show-header='false'
            max-height="500"
            ref="ruyuanLabelsTable"
            :data="ruyuanLabels"
            border
            highlight-current-row
            @current-change="onRuyuanLabelsClick"
            style="width: 100%"
            :row-class-name="tableRowClassName"
          >
            <el-table-column
              type="index"
              width="50"
            >
            </el-table-column>
            <el-table-column
              property="name"
              label="标签"
              width="100"
            >
            </el-table-column>
            <el-table-column label="状态">

              <template slot-scope="scope">
                <el-button
                  size="mini"
                  slot="reference"
                  class="name-wrapper"
                  @click="onActiveClick(scope.$index, scope.row)"
                  :type="scope.row.active ? 'success' : 'info'"
                >{{scope.row.active ? '启用中':'未启用'}}</el-button>

              </template>

            </el-table-column>

          </el-table>
        </div>
      </el-col>

    </el-row>

    <el-dialog
      title="添加一级类目"
      :visible.sync="dialogFirstAddVisible"
    >
      <el-form
        :model="firstAddForm"
        :rules="firstAddFormRules"
        ref="firstAddForm"
      >
        <el-form-item
          label="一级类目"
          label-width="120px"
          prop="firstName"
        >
          <el-input
            v-model="firstAddForm.firstName"
            autocomplete="off"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="二级类目"
          label-width="120px"
          prop="secondName"
        >
          <el-input
            v-model="firstAddForm.secondName"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogFirstAddVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="onFirstAddCommit('firstAddForm')"
        >确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="添加二级类目"
      :visible.sync="dialogSecondAddVisible"
    >
      <el-form
        :model="secondAddForm"
        :rules="firstAddFormRules"
        ref="secondAddForm"
      >

        <el-form-item
          label="二级类目"
          label-width="120px"
          prop="secondName"
        >
          <el-input
            v-model="secondAddForm.secondName"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogSecondAddVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="onSecondAddCommit('secondAddForm')"
        >确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="添加标签"
      :visible.sync="dialogLabelAddVisible"
    >
      <el-form
        :model="labelAddForm"
        :rules="firstAddFormRules"
        ref="labelAddForm"
      >
        <el-form-item
          label="标签名称"
          label-width="120px"
          prop="name"
        >
          <el-input
            v-model="labelAddForm.name"
            autocomplete="off"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="所属层级"
          label-width="120px"
          prop="level"
        >
          <el-input
            placeholder="请输入数字 1、2、3 "
            v-model="labelAddForm.level"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogLabelAddVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="onLabelAddCommit('labelAddForm')"
        >确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
export default {

  data () {
    return {
      firstAddForm: {},
      secondAddForm: {},
      labelAddForm: {},
      dialogLabelAddVisible: false,
      dialogFirstAddVisible: false,
      dialogSecondAddVisible: false,
      ruyuanFirstTableCurrentRow: null,
      ruyuanSecondTableCurrentRow: null,
      ruyuanLabelsTableCurrentRow: null,
      ruyuanFirst: [],
      ruyuanSecond: [],
      ruyuanLabels: [],
      firstAddFormRules: {
        firstName: [
          { required: true, message: '请输入一级目录名称', trigger: 'blur' }
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        secondName: [
          { required: true, message: '请输入第一个二级目录名称', trigger: 'blur' }
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入标签名称', trigger: 'blur' }
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        level: [
          { required: true, message: '请输入标签所属层级', trigger: 'blur' }
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ]
      }

    }
  },
  created () {
    this.loadRuyuanFirst()
  },
  methods: {
    async loadRuyuanFirst () {
      const { data: resp } = await this.$http.get('category/find/first_category',
        { params: { type: 'a', role: 0 } })

      console.log(resp)

      if (resp.code !== 200) return this.$message.error(resp.message)

      this.ruyuanFirst = resp.data
    },
    async addCategory (fn, sn) {
      const { data: resp } = await this.$http.post('category/add', null, {
        params: { type: 'a', firstName: fn, secName: sn }
      })

      if (resp.code !== 200) return this.$message.error(resp.message)

      this.$message(
        { type: 'success', message: '添加成功！' }
      )

      this.dialogSecondAddVisible = false
      this.dialogFirstAddVisible = false
      location.reload()
    },
    async onRuyuanFirstClick (val) {
      this.ruyuanFirstTableCurrentRow = val
      console.log('入院宣教 一级目录', this.ruyuanFirstTableCurrentRow)

      this.ruyuanLabels = []

      this.ruyuanSecond = []

      const { data: resp } = await this.$http.get('category/find/sec_category',
        { params: { firstId: this.ruyuanFirstTableCurrentRow.firstId, role: 0 } })

      console.log(resp)

      if (resp.code !== 200) return this.$message.error(resp.message)

      // this.ruyuanFirst = resp.data
      this.ruyuanSecond = resp.data
    },
    async onRuyuanSecondClick (val) {
      this.ruyuanSecondTableCurrentRow = val
      console.log('入院宣教 二级目录', this.ruyuanSecondTableCurrentRow)

      // 读取标签
      const { data: resp } = await this.$http.get('article_category_tag/find/category_tag', {
        params: {
          categoryId: val.id,
          role: 0
        }
      })

      console.log(resp)

      if (resp.code !== 200) return this.$message.error(resp.message)

      this.ruyuanLabels = resp.data
    },
    onRuyuanLabelsClick (val) {
      this.ruyuanLabelsTableCurrentRow = val
      console.log('入院宣教 标签', this.ruyuanSecondTableCurrentRow)
    },
    tableRowClassName ({ row, rowIndex }) {
      // if (!row.active) {
      //   return 'unactive'
      // }
      return ''
    },
    ruyuanFirstAddClick () {
      this.dialogFirstAddVisible = true
    },
    ruyuanSecondAddClick () {
      if (this.ruyuanFirstTableCurrentRow == null) {
        return this.$message({
          message: '请先选择一个【一级分类】',
          type: 'warning'
        })
      }
      this.dialogSecondAddVisible = true
    },
    ruyuanLabelAddClick () {
      if (this.ruyuanSecondTableCurrentRow == null) {
        return this.$message({
          message: '请先选择一个【二级分类】',
          type: 'warning'
        })
      }
      this.dialogLabelAddVisible = true
    },
    onFirstAddCommit (formRef) {
      this.$refs[formRef].validate((valid) => {
        if (valid) {
          console.log(this.firstAddForm)

          // todo 调用添加一级类目的接口
          this.addCategory(this.firstAddForm.firstName, this.firstAddForm.secondName)
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    onSecondAddCommit (formRef) {
      this.$refs[formRef].validate((valid) => {
        if (valid) {
          console.log(this.secondAddForm)
          // todo 调用添加二级类目的接口
          this.addCategory(this.ruyuanFirstTableCurrentRow.firstName, this.secondAddForm.secondName)
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    onLabelAddCommit (formRef) {
      this.$refs[formRef].validate((valid) => {
        if (valid) {
          console.log(this.labelAddForm)
          // todo 调用添加标签的接口
          this.submitLabel(this.ruyuanSecondTableCurrentRow.id, this.labelAddForm.name, this.labelAddForm.level)
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    async onActiveFirstClick (index, row) {
      this.$message({
        type: 'warnning',
        message: '功能尚未完成'
      })
    },
    async onActiveSecondClick (index, row) {
      console.log(row)
      const { data: resp } = await this.$http.post('category/secCategory/active', null, { params: { categoryId: row.id } })

      if (resp.code !== 200) return this.$message.error(resp.message)

      row.active = resp.data

      this.$message({
        type: 'success',
        message: '修改成功'
      })
    },
    async submitLabel (secId, labelName, labelLevel) {
      const { data: resp } = await this.$http.post('article_category_tag/add/category_tag', null, {
        params: {
          categoryId: secId,
          name: labelName,
          level: labelLevel
        }
      })

      if (resp.code !== 200) return this.$message.error(resp.message)

      this.$message.success('添加成功')
      this.dialogLabelAddVisible = false
    }
  }
}
</script>

<style lang="less" scoped>
.el-table .unactive {
  background: #aaaaaa;
}
.title {
  width: 100%;
  text-align: center;
  line-height: 40px;
  color: white;
  font-size: 20px;
}
.title_first {
  background: #00cc00;
}
.title_second {
  background: #0066cc;
}
.title_label {
  background: #99cccc;
}
</style>
