import Vue from 'vue'
import {
  Button, Form, FormItem, Input, Message, Container, Header, Main, Aside, Footer,
  Menu, Submenu, MenuItemGroup, MenuItem, Breadcrumb, BreadcrumbItem, Card, Row, Col,
  Table, TableColumn, Pagination, Tabs, TabPane, Link, Upload, Progress, Divider, Image,
  Cascader, Select, Option, Dialog, Checkbox, CheckboxGroup, Tooltip, Tag, Drawer, MessageBox,
  Dropdown, DropdownMenu, DropdownItem, Loading, Popover, Autocomplete, Radio
} from 'element-ui'

// import VueQuillEditor from 'vue-quill-editor'
// require styles
// import 'quill/dist/quill.core.css'
// import 'quill/dist/quill.snow.css'
// import 'quill/dist/quill.bubble.css'

// Vue.use(VueQuillEditor /* { default global options } */)

Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Container)
Vue.use(Header)
Vue.use(Main)
Vue.use(Aside)
Vue.use(Footer)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItemGroup)
Vue.use(MenuItem)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Card)
Vue.use(Row)
Vue.use(Col)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Pagination)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Link)
Vue.use(Upload)
Vue.use(Progress)
Vue.use(Divider)
Vue.use(Image)
Vue.use(Cascader)
Vue.use(Select)
Vue.use(Option)
Vue.use(Dialog)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Tooltip)
Vue.use(Tag)
Vue.use(Drawer)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Loading)
Vue.use(Popover)
Vue.use(Autocomplete)
Vue.use(Radio)

Vue.prototype.$message = Message
Vue.prototype.$messageBox = MessageBox
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
