<template>

  <div class="container">

    <div
      class="video"
      id="video"
    >

      <div
        :id="rtc.remote_video_panel"
        class="video-view panel"
      >

        <div
          :id="rtc.remote_video"
          class="video"
        ></div>
        <div
          :id="rtc.remote_video_info"
          class="video-profile"
        ></div>
        <div
          :id="rtc.video_autoplay"
          class="autoplay-fallback hide"
        ></div>
      </div>

    </div>

    <div class="title">
      <span>康复中国万里行 专家驻点扶贫活动<br />广东云浮市郁南县</span>

      <!-- <span>动优直播</span> -->
    </div>

    <el-button
      v-show='!playing'
      class="btn_start"
      @click="start"
    >开始</el-button>

    <div class="foot">
      动优提供直播技术运营支持
      <!-- <span>动优直播</span> -->
    </div>
  </div>

</template>

<script>

// import AgoraRTC from 'agora-rtc-sdk'
import AgoraRTC from 'agora-rtc-sdk-ng'

export default {

  data () {
    return {
      playing: false,
      rtc: {
        client: null,
        joined: false,
        published: false,
        localStream: null,
        remoteStreams: [],
        params: {},
        remote_id: '',
        remote_video_panel: '',
        remote_video: '',
        remote_video_info: '',
        video_autoplay: ''
      },
      option: {
        appID: '1cbff07b862b48e887dae922cd2d887f',
        appCertificate: '657d29c651a641bc8c4acb7a3018d2ad',
        channel: 'Channel name',
        uid: 0,
        token: 'Your token'
      }
    }
  },
  created () {
    this.option.channel = this.$route.params.channel

    console.log(this.option)
    this.initRTC()
  },
  methods: {
    initRTC () {
      const that = this
      const rtc = this.rtc
      const option = this.option

      // Create a client
      rtc.client = AgoraRTC.createClient({ mode: 'live', codec: 'h264' })
      // 订阅远端流:当远端流加入频道时，会触发 stream-added 事件，我们需要通过 Client.on 监听该事件并在回调中订阅新加入的远端流。
      // 监听 "stream-added" 事件，当有远端流加入时订阅该流。
      rtc.client.on('stream-added', function (evt) {
        var remoteStream = evt.stream
        var id = remoteStream.getId()
        if (id !== rtc.params.uid) {
          rtc.client.subscribe(remoteStream, function (err) {
            console.log('stream subscribe failed', err)
          })
        }
        console.log('stream-added remote-uid: ', id)
      })
      rtc.client.on('stream-subscribed', function (evt) {
        var remoteStream = evt.stream
        var id = remoteStream.getId()// 远端用户id

        console.log('stream-subscribed remote-uid: ', id)
        // Add a view for the remote stream.
        // addView(id)
        that.rtc.remote_video_panel = 'remote_video_panel_' + id
        that.rtc.remote_video = 'remote_video_' + id
        that.rtc.remote_video_info = 'remote_video_info_' + id
        that.rtc.video_autoplay = 'video_autoplay_' + id
        // Play the remote stream.
        // remoteStream.play('remote_video_' + id)
        that.rtc.remoteStream = remoteStream
        that.rtc.remote_id = id
      })
      rtc.client.on('stream-removed', function (evt) {
        var remoteStream = evt.stream
        var id = remoteStream.getId()
        console.log('stream-removed remote-uid: ', id)
        // Stop playing the remote stream.
        remoteStream.stop('remote_video_' + id)
        // Remove the view of the remote stream.
        // removeView(id)
      })

      // Initialize the client
      rtc.client.init(option.appID, function () {
        console.log('init success')
        // The value of role can be "host" or "audience".
        rtc.client.setClientRole('audience')
        that.getRtcToken()
      }, (err) => {
        console.log('err')
        console.error(err)
      })
    },
    async getRtcToken () {
      const rtc = this.rtc
      const option = this.option

      const { data: resp } = await this.$http.get('videoCall/token/subscriber', {
        params: {
          appId: this.option.appID,
          appCertificate: this.option.appCertificate,
          channelName: this.option.channel,
          userAccount: 'web',
          uId: 0
        }
      })
      if (resp.code !== 200) {
        return this.$message.error(resp.message)
      }

      const rtcToken = resp.data
      this.option.token = rtcToken
      console.log('token获取成功：', this.option)

      // Join a channel
      rtc.client.join(option.token ? option.token : null, option.channel, option.uid ? option.uid : null, function (uid) {
        console.log('join channel: ' + option.channel + ' success, uid: ' + uid)
        rtc.params.uid = uid
      }, function (err) {
        console.error('client join failed', err)
      })
    },
    start () {
      this.rtc.remoteStream.play('remote_video_' + this.rtc.remote_id)
      this.playing = true
    }

  }

}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
}
.video {
  width: 100%;
  height: 100%;
}
.panel {
  width: 100%;
  height: 100%;
}
.btn_start {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.foot {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 10px;
  font-size: 14px;
  color: grey;
}

.title {
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
  background-color: rgba(0, 128, 0, 0.267);
  color: white;
  font-size: 14px;
  line-height: 20px;
}
</style>
