<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/menu/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统服务</el-breadcrumb-item>
      <el-breadcrumb-item>apk-发布</el-breadcrumb-item>
    </el-breadcrumb>

    <el-divider content-position="left">基本信息</el-divider>

    <h4><span style="color:red;">*</span> 发布端(医生端/患者端)</h4>

    <el-select
      style="margin-bottom:10px;"
      v-model="apkInfo.userType"
      placeholder="请选择"
      clearable
    >
      <el-option
        v-for="item in userTypeOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>

    <el-divider content-position="left">版本信息</el-divider>

    <el-row :gutter="20">
      <el-col :span="6">
        <!--  :props="{ expandTrigger: 'hover' }" -->
        <h4><span style="color:red;">*</span> 版本号</h4>
      </el-col>
      <el-col :span="6">
        <!--  :props="{ expandTrigger: 'hover' }" -->
        <h4><span style="color:red;">*</span> 版本名</h4>
      </el-col>
      <el-col :span="6">
        <!--  :props="{ expandTrigger: 'hover' }" -->
        <h4><span style="color:red;">*</span> 标题 </h4>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :span="6">
        <!--  :props="{ expandTrigger: 'hover' }" -->
        <el-input
          :autosize="{ minRows: 1}"
          placeholder="请输入[版本号]"
          v-model="apkInfo.versionCode"
        ></el-input>
      </el-col>
      <el-col :span="6">
        <!--  :props="{ expandTrigger: 'hover' }" -->
        <el-input
          :autosize="{ minRows: 1}"
          placeholder="请输入[版本名称]"
          v-model="apkInfo.versionName"
          @change="onVersionNameChange"
        ></el-input>
      </el-col>
      <el-col :span="6">
        <el-input
          style="width:400px;"
          :autosize="{ minRows: 1}"
          placeholder="请输入[标题]"
          v-model="apkInfo.title"
        >
        </el-input>
      </el-col>
    </el-row>

    <h4><span style="color:red;">*</span> 更新内容 </h4>

    <el-input
      type="textarea"
      style="width:500px;"
      :autosize="{ minRows: 7}"
      placeholder="请输入[更新内容]"
      v-model="apkInfo.content"
    >
    </el-input>

    <el-divider content-position="left">apk文件</el-divider>

    <div class="upload_box">

      <el-upload
        drag
        :auto-upload="false"
        :action="uploadUrl"
        ref="upload"
        :http-request="uploadRequest"
        :on-success="uploadSuccess"
        :on-error="uploadError"
        :on-progress="onProgress"
        :on-change="onChange"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <!-- <div
                class="el-upload__tip"
                slot="tip"
              >只能上传jpg/png文件，且不超过500kb</div> -->
      </el-upload>
      <el-button
        style="margin-top: 10px;"
        size="small"
        type="success"
        @click="submitUpload"
      >发布
      </el-button>

    </div>

  </div>
</template>

<script>
export default {

  data () {
    return {
      progress: 0,
      uploadUrl: '',
      userTypeOptions: [
        {
          label: '医生端',
          value: 1
        },
        {
          label: '患者端',
          value: 2
        },
        {
          label: '社区端',
          value: 3
        }
      ],
      apkInfo: {
        title: ''

      }

    }
  },
  created () {

  },
  methods: {
    submitUpload () {
      this.$refs.upload.submit()
    },
    /**
     * @description [UploadRequest 覆盖默认的上传行为，实现自定义上传]
     * @author shanshuizinong
     * @param {object} option [上传选项]
     * @return {null} [没有返回]
     */
    async uploadRequest (option) {
      console.log('【uploadRequest】', option)
      try {
        const OSS = require('ali-oss')
        const ossConfig = {
          // region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
          region: 'oss-cn-shenzhen',
          // 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录RAM控制台创建RAM账号。
          accessKeyId: 'LTAIAcClE56I1mY0',
          accessKeySecret: 'IIPoiW0o3noCHU9bRT5vyAw0EDtem8',
          bucket: 'ihealthy'
        }
        const client = new OSS(ossConfig)
        const file = option.file
        // console.log(file)
        // 分片上传文件
        var dir = this.apkInfo.userType === 1 ? 'doctor' : this.apkInfo.userType === 2 ? 'patient' : 'communityOrz'
        const ret = await client.multipartUpload('apk/' + dir + '/' + file.name, file, {
          progress: async function (p) {
            // console.log('multipartUpload', p)
            const e = {}
            e.percent = parseInt(p * 100)
            option.onProgress(e)
          }
        })

        if (ret.res.statusCode === 200) {
          // option.onSuccess(ret)
          return ret
        } else {
          option.onError('上传失败')
        }
      } catch (err) {
        console.error(err)

        option.onError('上传失败')
        this.$error(err.message)
      }
    },
    onProgress (event, file, fileList) {
      // console.log('onProgress', event)
      this.progress = event.percent
    },
    uploadError (err, file, fileList) {
      this.$message.error(err)
      console.log('【uploadError】', err)
    },
    uploadSuccess (response, file, fileList) {
      console.log('【uploadSuccess】', response)
      this.progressStatus = 'success'
      this.$message({
        message: '上传成功',
        type: 'success'
      })

      // 文件URL
      this.apkInfo.url = response.res.requestUrls[0].substring(0, response.res.requestUrls[0].indexOf('?'))
      console.log('【文件URL】', this.apkInfo.url)
      // 请求后台接口：录入APP更新信息
      this.updateApkVer()
    },
    onChange (file, fileList) {
      console.log('【onChange】', file)
    },
    onVersionNameChange () {
      this.apkInfo.title = '动优康复-' + (this.apkInfo.userType === 1 ? '医生端' : this.apkInfo.userType === 2 ? '患者端' : '社区端') + '-v' + this.apkInfo.versionName
      console.log(this.apkInfo)
    },
    async updateApkVer () {
      console.log(this.apkInfo)
      const { data: resp } = await this.$http.post('cms/apk/update', this.apkInfo)
      if (resp.code === 200) {
        this.$message({
          message: '上传成功！',
          type: 'success'
        })
      } else {
        this.$message.error(resp.message)
      }
    }
  }

}
</script>

<style lang="less" scoped>
.upload_box {
  width: 500px;
}
</style>
