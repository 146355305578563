<template>

  <div class="container">

    <img
      class="img1"
      src="../../../assets/apply_sucess.png"
    >

  </div>

</template>

<script>

export default {

  data () {
    return {

    }
  },
  created () {

  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;

  background: #9ecfd5;
}

.img1 {
  width: 100%;
  height: auto;
}
</style>
