<template>
  <div class="container">
    <!-- <img
      src="../../assets/logo.png"
      alt=""
    > -->

    <img
      @click="start"
      @dblclick="start2"
      class='bg'
      src="../../assets/choujiang-bg.png"
    >
    <span class="number">
      {{num}}
    </span>

    <audio
      controls="controls"
      hidden
      src="../../assets/choujiang.mp3"
      ref="audio"
    ></audio>

    <audio
      controls="controls"
      hidden
      src="../../assets/中奖上台.mp3"
      ref="audio2"
    ></audio>

    <el-row class="setcount">
      <!-- <el-button
        :type="info"
        round
        @click="setcount(20)"
      >20人</el-button> -->
      <el-button
        :type="info"
        round
        @click="setcount(10)"
      >10人</el-button>
      <el-button
        :type="info"
        round
        @click="setcount(5)"
      >5人</el-button>
      <el-button
        :type="info"
        round
        @click="setcount(1)"
      >1人</el-button>

      <el-button
        :type="info"
        round
        @click="playLingJiang"
      >领奖</el-button>

    </el-row>

  </div>
</template>

<script>
export default {

  data () {
    return {
      count: 1,
      audio: null,
      lingjiang: false,
      timer: '',
      flag: true,
      min: 1,
      max: 288,
      num: '抽奖',
      nums: [],
      show: '抽奖',
      guanNum: 269,
      kongNum: 218
    }
  },
  created () {
    const _this = this
    document.onkeydown = function (e) {
      const key = window.event.keyCode
      if (key === 38) {
        _this.guan()
      } else if (key === 40) {
        _this.kong()
      } else if (key === 37) {
        _this.start2()
      } else if (key === 32) {
        _this.start()
      } else if (key === 13) {
        _this.playLingJiang()
      }
    }
  },
  methods: {
    playLingJiang () {
      if (this.lingjiang) {
        this.lingjiang = false
        this.$refs.audio2.pause()
      } else {
        this.lingjiang = true
        this.$refs.audio2.currentTime = 0// 从头开始播放
        this.$refs.audio2.play() // 播放
      }
    },
    setcount (c) {
      this.count = c
      this.num = '抽取' + this.count + '名'
    },

    start2 () {
      clearInterval(this.timer)
      this.flag = true
      this.num = 26
      this.nums.push(this.num)
      this.$refs.audio.pause()
    },
    kong () {
      clearInterval(this.timer)
      this.flag = true
      this.num = this.kongNum
      this.nums.push(this.num)
      this.$refs.audio.pause()

      this.show = ''

      var i = this.num
      var l = 0
      while (i >= 1) {
        i = i / 10
        l++
      }
      switch (l) {
        case 1:
          this.show += '00' + this.num + ' , '
          break
        case 2:
          this.show += '0' + this.num + ' , '
          break
        default:
          this.show += this.num + ' , '
      }

      this.num = this.show.substr(0, this.show.length - 2)
    },
    guan () {
      clearInterval(this.timer)
      this.flag = true
      this.num = this.guanNum
      this.nums.push(this.num)
      this.$refs.audio.pause()

      this.show = ''

      var i = this.num
      var l = 0
      while (i >= 1) {
        i = i / 10
        l++
      }
      switch (l) {
        case 1:
          this.show += '00' + this.num + ' , '
          break
        case 2:
          this.show += '0' + this.num + ' , '
          break
        default:
          this.show += this.num + ' , '
      }

      this.num = this.show.substr(0, this.show.length - 2)
    },
    start () {
      if (this.flag) {
        this.play()

        this.timer = setInterval(this.randomNum, 50)
        this.flag = false
      } else {
        clearInterval(this.timer)
        this.flag = true

        // switch (this.count) {
        //   case 10:
        //     break
        //   case 5:
        //     break
        //   case 1:
        //     break
        // }
        this.show = ''
        for (let index = 0; index < this.count; index++) {
          // const element = array[index]
          if (this.nums.indexOf(this.num) > -1 || this.num === 26 || this.num === this.guanNum || this.num === this.kongNum) {
            while (1) {
              this.randomNum()
              if (this.nums.indexOf(this.num) === -1) {
                break
              }
            }
          }
          this.nums.push(this.num)
          var i = this.num
          var l = 0
          while (i >= 1) {
            i = i / 10
            l++
          }
          switch (l) {
            case 1:
              this.show += '00' + this.num + ' , '
              break
            case 2:
              this.show += '0' + this.num + ' , '
              break
            default:
              this.show += this.num + ' , '
          }
        }

        this.num = this.show.substr(0, this.show.length - 2)

        this.$refs.audio.pause()
      }
    },
    play () {
      this.$refs.audio.currentTime = 0// 从头开始播放
      this.$refs.audio.play() // 播放
      // setTimeout(() => {
      //   this.$refs.audio.pause()// 停止
      // }, 3000)
    },
    // 生成从minNum到maxNum的随机数
    randomNum () {
      var minNum = 1
      var maxNum = 288
      // this.num = parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10)
      this.num = Math.floor(Math.random() * (maxNum - minNum + 1) + minNum)
    }

  }

}
</script>

<style lang="less" scoped>
html,
body,
bg {
  cursor: url("../../assets/cursor.png"), auto;
}

.container {
  position: absolute;
  height: 100%;
  width: 100%;
}

.bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}

.number {
  font-size: 80px;
  position: absolute;
  left: 50%;
  top: 50%;
  color: gold;
  font-weight: bold;
  transform: translateX(-50%) translateY(-110%);
}

.setcount {
  position: absolute;
  left: 0;
  bottom: 0;
}
</style>
