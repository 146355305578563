<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/menu/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>内容管理</el-breadcrumb-item>
      <el-breadcrumb-item>资讯文章</el-breadcrumb-item>
      <el-breadcrumb-item>文章列表</el-breadcrumb-item>
    </el-breadcrumb>

    <el-table
      :data="articles"
      style="width: 100%;"
      height="630"
      stripe
      border
    >
      <el-table-column
        prop="createDate"
        label="录入时间"
        width="120"
      >
      </el-table-column>

      <el-table-column
        label="所属分类"
        width="260"
        align="center"
      >

        <template slot-scope="scope">

          <el-tag
            style="margin-bottom:4px"
            v-for="item in scope.row.categories"
            :key="item.id"
            closable
            size="mini"
            @close="closeCategory(scope.row,item)"
          >
            {{item.type + ' -- ' + item.firstName + ' --  ' + item.secondName}}
          </el-tag>

        </template>
      </el-table-column>

      <el-table-column
        label="标签"
        width="260"
        align="center"
      >

        <template slot-scope="scope">

          <div
            v-for="items in scope.row.tags"
            :key="items.id"
          >
            <el-tag
              style="margin-bottom:4px;margin-left:4px;margin-right:4px"
              v-for="tag in items.categoryTagRespList"
              :key="tag.id"
              closable
              size="mini"
              @close="closeTag(scope.row,tag)"
            >
              {{tag.name}}
            </el-tag>
          </div>

        </template>
      </el-table-column>

      <el-table-column
        prop="title"
        label="标题"
        width="200"
      >
      </el-table-column>
      <el-table-column
        prop="author"
        label="作者"
        width="150"
      >
        <!-- <template slot-scope="scope">

          <el-link
            slot="reference"
            class="name-wrapper"
            :href="scope.row.videoUrl "
            target="_blank"
          > {{ scope.row.title }}</el-link>

        </template> -->
      </el-table-column>

      <el-table-column
        label="引用链接"
        width="80"
      >
        <template slot-scope="scope">
          <el-link
            slot="reference"
            class="name-wrapper"
            type="primary"
            :href="scope.row.refLink"
            target="_blank"
            :disabled="scope.row.refLink === '' ? true : false"
          > {{scope.row.refLink === '' ? '无' : '查看'}}</el-link>
        </template>
      </el-table-column>
      <el-table-column
        label="摘要"
        width="250"
      >
        <template slot-scope="scope">

          <el-tooltip placement="top">
            <div
              slot="content"
              style="max-width:200px;line-height:18px"
            >{{scope.row.brief}}</div>
            <span>{{scope.row.brief.substring(0,30)+"..."}}</span>
          </el-tooltip>

        </template>
      </el-table-column>

      <!-- <el-table-column
        label="详情"
        width="100"
      >
        <template slot-scope="scope">

          <el-button
            size="mini"
            slot="reference"
            class="name-wrapper"
            @click="onArticleShowClick(scope.$index, scope.row)"
            type="success"
          >查看</el-button>

        </template>
      </el-table-column> -->

      <el-table-column
        label="上架状态"
        width="100"
      > <template slot-scope="scope">

          <el-button
            size="mini"
            slot="reference"
            class="name-wrapper"
            @click="onActiveClick(scope.$index, scope.row)"
            :type="scope.row.active ? 'success' : 'info'"
          >{{scope.row.active?"已上架":"未上架"}}</el-button>

          <!-- <el-link
            slot="reference"
            class="name-wrapper"
            :href="scope.row. refLink"
            target="_blank"
          > 查看</el-link> -->

        </template></el-table-column>
      <el-table-column
        prop="readCount"
        label="阅读数"
        width="100"
      >
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        width="200"
        align="center"
      >
        <template slot-scope="scope">
          <div>
            <el-button
              @click="handleArticleShowClick(scope.row)"
              type="text"
              size="small"
            >查看</el-button>
            <el-button
              @click="handleArticleCategoriesClick(scope.row)"
              type="text"
              size="small"
            >分类</el-button>
            <el-button
              @click="handleArticleDeleteClick(scope.row)"
              type="text"
              size="small"
            >删除</el-button>
          </div>
          <div>
            <el-button
              type="text"
              size="small"
            >待定</el-button>
            <el-button
              type="text"
              size="small"
            >待定</el-button>
            <el-button
              type="text"
              size="small"
            >待定</el-button>
          </div>
        </template>
      </el-table-column>

    </el-table>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-size="pageSize"
      layout="total, prev, pager, next"
      :total="total"
    >
    </el-pagination>

    <el-dialog
      title="设置文章的[分类/标签]"
      :visible.sync="dialogCategorySettingVisible"
    >
      <el-divider content-position="left">入院宣教</el-divider>
      <el-row
        :gutter="20"
        style="color:#24a631"
      >
        <el-col :span="10">
          <!--  :props="{ expandTrigger: 'hover' }" -->
          请选择分类
        </el-col>

        <el-col :span="14">
          <!--  :props="{ expandTrigger: 'hover' }" -->
          请选择标签
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="10">
          <!--  :props="{ expandTrigger: 'hover' }" -->
          <el-cascader
            v-model="ruyuanCategory"
            :options="ruyuanCategoryOptions"
            @change="ruyuanCategoryChange"
          ></el-cascader>
        </el-col>

        <el-col :span="14">
          <el-checkbox-group v-model="ruyuanCheckList">
            <el-checkbox
              v-for="(item) in ruyuanCheckOptions"
              :key="item.id"
              :label="item.name"
            ></el-checkbox>
          </el-checkbox-group>
        </el-col>
      </el-row>

      <el-divider content-position="left">资讯文章(开发中) </el-divider>

      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogCategorySettingVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="onCategorySettingCommit"
        >绑定分类</el-button>
        <el-button
          type="primary"
          @click="onLabelSettingCommit"
        >绑定标签</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
export default {

  data () {
    return {
      articles: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      canDelete: false,
      dialogCategorySettingVisible: false,
      currentArticle: null,
      ruyuanCategory: [],
      ruyuanCategoryOptions: [],
      ruyuanCheckOptions: [],
      ruyuanCheckList: [],
      ruyuanLabelCheckIds: []
    }
  },
  created () {
    this.loadArticles()
  },
  methods: {
    async loadArticles () {
      const { data: resp } = await this.$http.get('cms/article/list', { params: { page: this.currentPage, pageSize: this.pageSize } })
      console.log(resp)
      if (resp.code !== 200) return this.$message.error(resp.message)

      resp.data.data.forEach(e => {
        e.createDate = new Date(e.createDate).toLocaleDateString() + ' ' + new Date(e.createDate).toLocaleTimeString()
      })

      this.articles = resp.data.data
      this.currentPage = resp.data.page
      this.pageSize = resp.data.pageSize
      this.total = resp.data.total
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
      this.currentPage = val
      this.loadArticles()
    },
    async onActiveClick (index, row) {
      // console.log(value)
      console.log(index)
      console.log(row)

      const loading = this.$message({
        message: '操作中',
        duration: 0
      })

      const { data: resp } = await this.$http.get('cms/article/active', { params: { id: row.id, active: row.active } })

      console.log(resp)
      if (resp.code !== 200) return this.$message.error(resp.message)

      row.active = resp.data

      loading.close()
      this.$message({
        message: '操作成功',
        type: 'success'
      })
    },
    handleArticleShowClick (row) {
      // this.$router.push('/article/' + row.id)

      const routeData = this.$router.resolve({
        path: '/article/' + row.id,
        name: 'article'
      })
      window.open(routeData.href, '_blank')
    },
    async handleArticleDeleteClick (row) {
      if (!this.canDelete) {
        return this.$message({
          message: '暂不提供删除功能',
          type: 'warning'
        })
      }

      if (row.active) {
        return this.$message({
          showClose: true,
          message: '正在上架的文章不可删除',
          type: 'warning'
        })
      }
      const loading = this.$message({
        message: '操作中',
        duration: 0
      })

      const { data: resp } = await this.$http.delete('cms/article/delete', { params: { id: row.id } })

      console.log(resp)
      loading.close()

      if (resp.code !== 200) return this.$message.error(resp.message)

      this.articles.forEach(article => {
        if (article.id === row.id) {
          this.articles.splice(this.articles.indexOf(article), 1)
        }
      })

      this.$message({
        message: '操作成功',
        type: 'success'
      })
    },
    handleArticleCategoriesClick (row) {
      // 打开分类、标签的修改界面
      this.dialogCategorySettingVisible = true
      this.currentArticle = row
      console.log('handleArticleCategoriesClick', this.currentArticle)

      // 加载分类数据
      this.loadRuyuanCategories()
      // this.loadRuyuanTagCheckOptions()
    },
    async onLabelSettingCommit () {
      this.ruyuanCheckOptions.forEach(option => {
        option.checked = false
      })

      this.ruyuanLabelCheckIds = []

      this.ruyuanCheckList.forEach(checked => {
        this.ruyuanCheckOptions.forEach(option => {
          if (checked === option.name) {
            option.checked = true
            this.ruyuanLabelCheckIds.push(option.id)
          }
        })
      })
      console.log(this.ruyuanCheckOptions)
      console.log(this.ruyuanLabelCheckIds)

      this.bindArticleLabels(this.currentArticle.id, this.ruyuanLabelCheckIds)
    },
    async onCategorySettingCommit () {
      console.log('onCategorySettingCommit', this.currentArticle)
      console.log('onCategorySettingCommit', this.ruyuanCategory)

      // 调用文章二级分类设置接口、标签设置接口
      var articleId = this.currentArticle.id
      var categoryId = this.ruyuanCategory[1]

      const { data: resp } = await this.$http.post('category/binding/article_and_category', null,
        {
          params: {
            articleId: articleId,
            categoryId: categoryId
          }
        })
      console.log(resp)
      if (resp.code !== 200) return this.$message.error(resp.message)

      this.$message.success('修改成功')
      this.loadArticles()
      this.dialogCategorySettingVisible = false
    },
    async ruyuanCategoryChange (value) {
      console.log('ruyuanCategoryChange', value)
      // this.courseInfo.secondSort = value[1]
      // 读取该类目下的标签
      // 读取标签
      const { data: resp } = await this.$http.get('article_category_tag/find/category_tag', {
        params: {
          categoryId: value[1],
          role: 0
        }
      })

      console.log(resp)

      if (resp.code !== 200) return this.$message.error(resp.message)

      this.ruyuanCheckOptions = resp.data
      this.ruyuanCheckOptions.forEach(option => {
        option.checked = false
      })
    },
    async loadRuyuanCategories () {
      const { data: resp } = await this.$http.get('category/categories', {
        params: {
          type: 'a', row: 0
        }
      })

      console.log(resp)

      if (resp.code !== 200) return this.$message.error(resp.message)

      this.ruyuanCategoryOptions = resp.data
    },
    async closeCategory (article, category) {
      console.log('删除分类: ', article.id, category.id)
      const { data: resp } = await this.$http.delete('category/unbinding/article_and_category', {
        params: {
          articleId: article.id, categoryId: category.id
        }
      })

      if (resp.code !== 200) return this.$message.error(resp.message)

      this.$message.success('目录解绑成功')

      this.loadArticles()
    },
    async bindArticleLabels (articleId, labelCheckIds) {
      console.log(labelCheckIds)
      var ids = ''
      labelCheckIds.forEach(id => {
        ids += id + ','
      })
      ids = ids.substring(0, ids.length - 1)
      console.log(ids)
      const { data: resp } = await this.$http.post('article_category_tag/binding/singleCategory/article_and_category_tag?categoryId=' + this.ruyuanCategory[1] + '&articleId=' + articleId + '&tagIdList=' + ids
      )

      if (resp.code !== 200) return this.$message.error(resp.message)

      this.$message.success('标签绑定成功')
    },
    async closeTag (article, tag) {
      console.log(tag)
      console.log('删除标签: ', article.id, tag.id)

      const { data: resp } = await this.$http.delete('article_category_tag/unbinding/article_tag', {
        params: {
          articleId: article.id, tagId: tag.id
        }
      })

      if (resp.code !== 200) return this.$message.error(resp.message)

      this.$message.success('目录解绑成功')

      this.loadArticles()
    }

  }

}
</script>

<style lang="less" scoped>
</style>
