<template>
  <div class="container">

    <div class="box">

      <el-card class="box-card">

        <div class="doctor">
          治疗师/医生：{{plan.doctorName}}
        </div>

        <div
          class="time"
          style="margin-top:10px"
        >
          定制日期：{{plan.gmtCreate}}
        </div>

      </el-card>

      <el-card class="box-card">

        <div class="title">
          评估意见
        </div>
        <div class="content">
          评估意见{{plan.assessOpinion}}
        </div>

      </el-card>

      <el-card class="box-card">

        <div class="title">
          康复目标
        </div>
        <div class="content">
          短期目标：{{planDetail.goalShort==''?"无":planDetail.goalShort}}
        </div>
        <div class="content">
          中期目标：{{planDetail.goalMiddle==''?"无":planDetail.goalMiddle}}
        </div>
        <div class="content">
          长期目标：{{planDetail.goalLong==''?"无":planDetail.goalLong}}
        </div>

      </el-card>

      <div
        class="title"
        style="margin-top:20px"
      >
        教学视频（点击播放）
      </div>

      <video-player
        style="margin-top:10px"
        class="video-player vjs-custom-skin"
        ref="videoPlayer"
        :playsinline="true"
        :options="playerOptions"
      >
      </video-player>
      <div
        v-for="(item,index) of planDetail.planCourseBeans"
        :key="index"
      >
        <el-card class="box-card">
          <div
            class="course"
            @click="courseClick(index)"
          >
            <i class="el-icon-video-play"></i>
            {{item.courseName}}
          </div>
        </el-card>

      </div>

      <el-card
        class="box-card"
        style="margin-top:20px"
      >

        <div class="title">
          训练要求
        </div>
        <div class="content">
          {{planDetail.request==''?"无":planDetail.request}}
        </div>

      </el-card>

      <el-card class="box-card">

        <div class="title">
          注意事项
        </div>
        <div class="content">
          {{planDetail.attention==''?"无":planDetail.attention}}
        </div>

      </el-card>

    </div>

  </div>
</template>

<script>

export default {

  data () {
    return {
      plan: {},
      planDetail: {},
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: false, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: 'video/mp4', // 类型
          src: '' // url地址
        }],
        poster: '', // 封面地址
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true // 是否显示全屏按钮
        }
      }

    }
  },
  created () {
    this.plan = this.$route.params.plan
    this.planDetail = JSON.parse(this.plan.plan)
    console.log(this.plan)
    console.log(this.planDetail)
    this.setupVideo(0, false)
  },
  methods: {
    handleFullscreen () {

    },
    courseClick (index) {
      this.setupVideo(index, true)
    },
    async setupVideo (index, auto) {
      console.log(this.planDetail.planCourseBeans[index].courseId)
      const { data: resp } = await this.$http.get('/course_and_tag/id?courseId=' + this.planDetail.planCourseBeans[index].courseId)

      if (resp.code === 200) {
        console.log(resp)
        this.playerOptions.autoplay = auto
        this.playerOptions.sources = [{
          type: 'video/mp4', // 类型
          src: resp.data.videoUrl // url地址
        }]
        this.playerOptions.poster = resp.data.coverUrl

        // this.video.cover = resp.data.coverUrl
      } else {
        this.$createDialog({
          type: 'alert',
          title: '',
          content: '错误：' + resp.message
        }).show()
      }
    }

  }
}
</script>

<style lang="less" scoped>
body {
  background: #9ecfd5;
}

.container {
  width: 100%;
  background: #9ecfd5;
  height: 100%;
  position: relative;
}

.box {
  position: absolute;
  background: #9ecfd5;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
}

.el-card__body {
  padding: 10px;
}
.box-card {
  margin-top: 5px;
}

.doctor,
.time,
.title,
.content,
.course {
  font-size: 20px;
  line-height: 30px;
}

.title {
  font-weight: bold;
  color: #333333;
}
</style>
