/**
 * 自定义的编辑器菜单项
 */

// 预览按钮
export class PreviewButtonMenu {
  // 自定义菜单的标题
  title
  tag

  constructor ({ title } = {}) {
    this.title = title || '预览'
    this.tag = 'button'
  }

  // 获取菜单执行时的 value ，用不到则返回空 字符串或 false
  getValue (editor) {
    return editor.getHtml()
  }

  // 菜单是否需要激活（如选中加粗文本，“加粗”菜单会激活），用不到则返回 false
  isActive (editor) {
    return false
  }

  // 菜单是否需要禁用（如选中 H1 ，“引用”菜单被禁用），用不到则返回 false
  isDisabled (editor) {
    return false
  }

  // 点击菜单时触发的函数
  exec (editor, value) {
    if (this.isDisabled(editor)) return
    editor.emit('preview', value)
  }
}

export function getAllMenuConf () {
  return [{
    key: 'preview-button', // 定义 menu key，要保证唯一、不重复(包括不与自带菜单项的 key 重复)
    factory () {
      return new PreviewButtonMenu()
    }
  }]
}
