<template>
  <div class="container">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/menu/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>内容管理</el-breadcrumb-item>
      <el-breadcrumb-item>中风科普</el-breadcrumb-item>
      <el-breadcrumb-item>上传</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card class="content_container">

      <div class="upload_box">

        <el-progress
          style="margin-top:10px;margin-bottom:10px;width:360px;"
          :text-inside="true"
          :stroke-width="24"
          :percentage="progress"
          :status="progressStatus"
        ></el-progress>

        <el-upload
          drag
          :auto-upload="false"
          :disabled="disabled"
          :action="uploadUrl"
          ref="upload"
          :http-request="uploadRequest"
          :on-success="uploadSuccess"
          :on-error="uploadError"
          :before-upload="beforeUpload"
          :on-progress="onProgress"
          :on-change="onChange"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <!-- <div
                class="el-upload__tip"
                slot="tip"
              >只能上传jpg/png文件，且不超过500kb</div> -->
        </el-upload>
        <el-button
          style="margin-top: 10px;"
          size="small"
          type="success"
          @click="submitUpload"
        >上传到服务器</el-button>

      </div>

      <el-divider content-position="left">基本信息</el-divider>

      <el-row
        :gutter="20"
        class="baseInfoTitle"
      >
        <el-col :span="6">
          <span>科普名称：</span>
        </el-col>
        <el-col :span="4">
          <span>序号：</span>
        </el-col>
        <el-col :span="4">
          <span>文件大小：</span>
        </el-col>

        <el-col :span="4">
          <span>视频长度：</span>
        </el-col>

      </el-row>

      <el-row
        :gutter="20"
        class="baseInfo"
      >
        <el-col :span="6">
          <div class="grid-content bg-purple">
            <el-link
              :href="courseInfo.videoUrl"
              target="_blank"
            >{{courseInfo.name}} </el-link>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="grid-content bg-purple">{{courseInfo.courseNum}} </div>
        </el-col>
        <el-col :span="4">
          <div class="grid-content bg-purple">{{ (courseInfo.size / 1024 / 1024).toFixed(1)}} MB</div>
        </el-col>

        <el-col :span="4">
          <div class="grid-content bg-purple">{{formatDuration(courseInfo.duration)}} </div>
        </el-col>
      </el-row>

      <el-divider content-position="left">科普信息</el-divider>

      <el-row :gutter="20">
        <el-col :span="6">
          <h4><span style="color:red;">*</span> 分类</h4>
        </el-col>
      </el-row>

      <el-row
        :gutter="20"
        class="baseInfoTitle"
      >
        <el-col :span="6">
          <el-select
            v-model="courseInfo.sort"
            placeholder="请选择"
            @change="onSortChange"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>

      </el-row>

      <h4 style="margin-top:50px;"><span style="color:red;">*</span> 内容</h4>

      <el-input
        type="textarea"
        style="width:700px;"
        :autosize="{ minRows: 10}"
        placeholder="请输入[内容]"
        v-model="courseInfo.text"
      >
      </el-input>

      <div style="margin-top:50px;margin-bottom:50px">
        <el-button
          type="primary"
          @click="onSubmitCourseInfo"
        >提交<i class="el-icon-upload el-icon--right"></i></el-button>

      </div>
    </el-card>

  </div>
</template>

<script>

export default {
  name: 'fileUpload',

  data () {
    return {
      uploadUrl: '',
      disabled: false,
      progress: 0,
      progressStatus: 'warning',
      file: {},
      options: [],
      courseInfo: {
        fileUrl: '',
        coverUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/%E7%A7%91%E6%99%AE/%E8%84%91%E5%8D%92%E4%B8%AD/%E9%80%9A%E7%94%A8%E5%B0%81%E9%9D%A2%E5%9B%BE-%E8%84%91%E5%8D%92%E4%B8%AD%E7%A7%91%E6%99%AE%E7%B3%BB%E5%88%97.png',
        courseNum: '-',
        sort: '',
        text: '',
        duration: 0,
        size: 0,
        name: '-'

      },
      fileInfo: {

      }
    }
  },
  created () {
    this.getSorts()
  },
  methods: {
    async getSorts () {
      const { data: resp } = await this.$http.get('cms/zfkp/sorts')
      console.log(resp)
      if (resp.code !== 200) return this.$message.error(resp.message)
      resp.data.forEach(data => {
        this.options.push({
          value: data.id,
          label: data.title
        })
      })

      // if (this.isFirst) {
      //   this.isFirst = false
      //   this.getSecondSort(this.firstSorts[0].id)
      // }
    },
    submitUpload () {
      this.$refs.upload.submit()
    },
    /**
    * @description [UploadRequest 覆盖默认的上传行为，实现自定义上传]
    * @author shanshuizinong
    * @param {object} option [上传选项]
    * @return {null} [没有返回]
    */
    async uploadRequest (option) {
      console.log('【uploadRequest】', option)
      try {
        const vm = this
        vm.disabled = true
        const OSS = require('ali-oss')
        const ossConfig = {
          // region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
          region: 'oss-cn-shenzhen',
          // 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录RAM控制台创建RAM账号。
          accessKeyId: 'LTAIAcClE56I1mY0',
          accessKeySecret: 'IIPoiW0o3noCHU9bRT5vyAw0EDtem8',
          bucket: 'ihealthy'
        }
        const client = new OSS(ossConfig)
        const file = option.file
        // console.log(file)
        // 分片上传文件
        // 获取分类名称
        var sort = ''
        this.options.forEach(option => {
          if (option.value === this.courseInfo.sort) {
            sort = option.label
          }
        })
        const ret = await client.multipartUpload('科普/脑卒中/' + sort + '/' + file.name, file, {
          progress: async function (p) {
            // console.log('multipartUpload', p)
            const e = {}
            e.percent = parseInt(p * 100)
            option.onProgress(e)
          }
        })

        if (ret.res.statusCode === 200) {
          // option.onSuccess(ret)
          return ret
        } else {
          vm.disabled = false
          option.onError('上传失败')
        }
      } catch (err) {
        console.error(err)
        this.disabled = false
        option.onError('上传失败')
        this.$error(err.message)
      }
    },
    onChange (file, fileList) {
      console.log('【onChange】', file)
      this.courseInfo.size = file.raw.size
      this.courseInfo.courseNum = file.raw.name.substring(0, file.raw.name.indexOf(' '))
      this.courseInfo.name = file.raw.name.substring(file.raw.name.indexOf(' '), file.raw.name.indexOf('.'))

      this.file = file

      /**
     * 获取视频文件时长
     */
      var url = URL.createObjectURL(file.raw)
      var audioElement = new Audio(url)
      const that = this
      // var ele = this.$refs.dur
      audioElement.addEventListener('loadedmetadata', function (_event) {
        that.courseInfo.duration = audioElement.duration * 1000 // 时长为秒，小数，182.36
        // ele.name = duration // 目前使用这种方式解决js与vue之间的传值问题
        // this.$parent.$data.wDuration = parseInt(duration) //注意传递给接口的时长字段类型要与接口接收的字段类型一致，不然无法获取到值
      })
    },
    beforeUpload (file) {
      // 上传文件之前的钩子，参数为上传的文件，若返回 false 或者返回 Promise 且被 reject，则停止上传。
      console.log('【beforeUpload】', file)
    },
    onProgress (event, file, fileList) {
      // console.log('onProgress', event)
      this.progress = event.percent
    },
    uploadError (err, file, fileList) {
      this.$message.error(err)
      console.log('【uploadError】', err)
    },
    uploadSuccess (response, file, fileList) {
      console.log('【uploadSuccess】', response)
      this.progressStatus = 'success'
      this.$message({
        message: '上传成功',
        type: 'success'
      })

      // 视频url
      this.courseInfo.fileUrl = response.res.requestUrls[0].substring(0, response.res.requestUrls[0].indexOf('?'))
      console.log('【视频URL】', this.courseInfo.fileUrl)
    },
    // 截取封面
    capture (response) {
      const objectName = response.name

      // 创建OSSClient实例。
      const OSS = require('ali-oss')
      const client = new OSS({
        accessKeyId: 'LTAIAcClE56I1mY0',
        accessKeySecret: 'IIPoiW0o3noCHU9bRT5vyAw0EDtem8',
        bucket: 'ihealthy',
        endpoint: 'http://oss-cn-shenzhen.aliyuncs.com'
      })

      // 设置视频截帧操作。
      const style = 'video/snapshot,t_2000,f_jpg,w_640,h_360,m_fast'
      // 过期时间10分钟, 图片处理式样"image/resize,w_300"
      const signUrl = client.signatureUrl(objectName, { expires: 600, process: style })
      console.log('signUrl=' + signUrl)

      this.downloadByBlob(signUrl, this.fileInfo.coverFileName)
    },
    // 保存截图
    downloadByBlob (url, name) {
      const image = new Image()
      image.setAttribute('crossOrigin', 'anonymous')
      image.src = url
      image.onload = () => {
        const canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        const ctx = canvas.getContext('2d')
        ctx.drawImage(image, 0, 0, image.width, image.height)
        canvas.toBlob((blob) => {
          const url = URL.createObjectURL(blob)
          this.download(url, name)
          // 用完释放URL对象
          URL.revokeObjectURL(url)
        })
      }
    },
    download (href, name) {
      const eleLink = document.createElement('a')
      eleLink.download = name
      eleLink.href = href
      eleLink.click()
      eleLink.remove()
    },
    // 封面上传相关函数
    onCoverChange (file, fileList) {
      console.log(file)
      // this.coverUrl = file.raw
      // this.coverUrls.push(this.coverUrl)
    },
    async uploadCoverRequest (option) {
      console.log('【uploadCoverRequest】', option)
      try {
        const vm = this
        vm.disabled = true
        const OSS = require('ali-oss')
        const ossConfig = {
          // region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
          region: 'oss-cn-shenzhen',
          // 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录RAM控制台创建RAM账号。
          accessKeyId: 'LTAIAcClE56I1mY0',
          accessKeySecret: 'IIPoiW0o3noCHU9bRT5vyAw0EDtem8',
          bucket: 'ihealthy'
        }
        const client = new OSS(ossConfig)
        const file = option.file
        // console.log(file)
        // 分片上传文件
        const ret = await client.multipartUpload('video/courses/covers/' + file.name, file, {
          progress: async function (p) {
            // console.log('multipartUpload', p)
            const e = {}
            e.percent = parseInt(p * 100)
            option.onProgress(e)
          }

        })

        if (ret.res.statusCode === 200) {
          // option.onSuccess(ret)
          return ret
        } else {
          vm.disabled = false
          option.onError('上传失败')
        }
      } catch (err) {
        console.error(err)
        this.disabled = false
        option.onError('上传失败')
        this.$error(err.message)
      }
    },
    uploadCoverError (err, file, fileList) {
      this.$message.error(err)
      console.log('【uploadCoverError】', err)
    },
    uploadCoverSuccess (response, file, fileList) {
      console.log('【uploadCoverSuccess】', response)
      this.progressStatus = 'success'
      this.$message({
        message: '上传成功',
        type: 'success'
      })

      // 封面url
      this.courseInfo.coverUrl = response.res.requestUrls[0].substring(0, response.res.requestUrls[0].indexOf('?'))
      this.coverUrls.push(this.courseInfo.coverUrl)

      console.log('视频URL:', this.courseInfo.videoUrl)
      console.log('封面URL:', this.courseInfo.coverUrl)
    },
    classifyChange (value) {
      console.log(value)
      this.courseInfo.secondSort = value[1]
      this.getCourseLabels()
    },
    onLabelChange (value) {
      // console.log(this.courseInfo.label_1)
      // console.log(this.courseInfo.label_2)
      // console.log(this.courseInfo.label_3)
    },
    onSubmitCourseInfo () {
      this.courseInfo.name = this.courseInfo.courseNum + '.' + this.courseInfo.name
      console.log(this.courseInfo)
      // 判断输入框是否都填了
      this.postCourseInfo()
    },
    async postCourseInfo () {
      const { data: resp } = await this.$http.post('cms/zfkp/add', this.courseInfo)
      if (resp.code === 200) {
        this.$message({
          message: '上传成功！',
          type: 'success'
        })
      } else {
        this.$message.error(resp.message)
      }
    },
    formatDuration (mss) {
      // const days = Math.floor(mss / (1000 * 60 * 60 * 24))
      // const hours = Math.floor((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const minutes = Math.floor((mss % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.round((mss % (1000 * 60)) / 1000)
      return minutes + '分钟' + seconds + '秒'
    },
    onSortChange (data) {
      console.log('分类', data)
      console.log(this.courseInfo)
    }

  }
}
</script>

<style lang="less" scoped>
.baseInfoTitle {
  font-size: 14px;
  margin-bottom: 5px;
  span {
    margin-left: 3px;
  }
}

.baseInfo {
  font-size: 12px;
}

.container {
  position: relative;
}
.content_container {
  width: 1300px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 40px;
}

.upload_box {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.cover_box {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

// /deep/ .el-upload-dragger {
//   width: 250px !important;
// }

.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  padding: 10px;
  text-align: center;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
</style>
