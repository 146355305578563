<template>
  <div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="shouldShowDialog"
      width="640px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      append-to-body
    >
      <el-form
        ref="form"
        :rules="rules"
        :model="article"
        label-position="left"
        label-width="auto"
      >
        <el-form-item label="标签" prop="tagIdList">
          <TagSelect v-model="article.tagIdList" />
        </el-form-item>
        <el-form-item label="标题" prop="title">
          <el-input v-model="article.title" placeholder="请填写标题"></el-input>
        </el-form-item>
        <el-form-item label="摘要" prop="summary">
          <el-input
            v-model="article.summary"
            placeholder="请填写摘要"
            type="textarea"
            maxlength="100"
            :autosize="{ minRows: 2, maxRows: 4}"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item v-if="article.type === 2" label="图片" prop="picList">
          <FileUploadOssPics
            v-model="article.picList"
            fileListType="picture-card"
            :dir="fileDir"
            :fileLimit="9"
          />
        </el-form-item>
        <el-form-item v-if="article.type === 3" label="视频" prop="video">
          <FileUploadOssPics
            v-model="article.video"
            fileListType="text"
            :dir="fileDir"
            :fileLimit="1"
          />
        </el-form-item>
        <el-form-item class="btn-row">
          <el-button type="primary" @click="handleSubmit">提交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<!-- 图片/视频创建和编辑 -->
<script>
import FileUploadOssPics from '../../../common-components/FileUploadOss/FileUploadOssPics.vue'
import TagSelect from './TagSelect.vue'
import systemConfig from '../../../constants/systemConfig'

export default {
  components: { TagSelect, FileUploadOssPics },
  emits: ['onSuccess'],
  props: {
    keepOpenAfterSuccess: Boolean
  },
  data () {
    return {
      article: {
        tagIdList: null,
        title: null,
        summary: null,
        content: null,
        picList: [],
        video: '',
        contentId: null,
        type: null
      },
      rules: {
        tagIdList: [
          { required: true, message: '标签不能为空', trigger: 'submit' }
        ],
        title: [{ required: true, message: '标题不能为空', trigger: 'blur' }],
        picList: [
          { required: true, message: '图片不能为空', trigger: 'submit' }
        ],
        video: [{ required: true, message: '视频不能为空', trigger: 'submit' }]
        // summary: [{ required: true, message: '摘要不能为空', trigger: 'blur' }]
      },
      shouldShowDialog: false
    }
  },
  computed: {
    dialogTitle () {
      let typeText = ''
      if (this.article?.type === 2) typeText = '图片'
      if (this.article?.type === 3) typeText = '视频'
      return this.article?.contentId
        ? `编辑${typeText}资讯`
        : `创建${typeText}资讯`
    },
    fileDir () {
      const folderDir =
        this.article.title ||
        `未知标题资讯/${new Date().toDateString().replaceAll(' ', '-')}`
      return `article/feeds/${folderDir}`
    },
    closeAfterSuccess () {
      return !this.keepOpenAfterSuccess
    }
  },
  methods: {
    show (feedData) {
      this.shouldShowDialog = true
      const article = {
        tagIdList: null,
        title: null,
        summary: null,
        content: null,
        contentId: null,
        ...(feedData || {})
      }
      if (feedData?.type === 2) {
        article.picList = feedData?.picList
          ? this.getFileListBy(feedData.picList)
          : []
      }
      if (feedData?.type === 3) {
        article.video = feedData?.video
          ? [
            {
              name: '视频-1',
              url: `${systemConfig.OSS_HOST}${feedData.video}`
            }
          ]
          : []
      }
      this.article = article
      console.log('data to show: ', feedData)
    },
    innerOnSuccess () {
      if (this.closeAfterSuccess) this.shouldShowDialog = false
      this.$emit('onSuccess')
    },
    async postTagList (tagItemList) {
      try {
        if (!tagItemList?.length) return []
        const { data: resp } = await this.$http.post(
          '/reh_circle/add/tags',
          null,
          { params: { nameList: tagItemList.map(tag => tag.name).join(',') } }
        )
        if (resp?.code !== 200 || !resp?.data) throw new Error(resp)
        return resp.data.map(tag => tag.id)
      } catch (error) {
        console.log(error)
        this.$message.error('添加标签出错')
      }
    },
    async handleSubmit () {
      await this.$refs.form.validate()
      const values = this.article
      const isCreation = !this.article.contentId
      const newTagItemList = values.tagIdList.filter(tag => tag.from === 'new')
      const newAddedTagIdList = await this.postTagList(newTagItemList)
      const tagIdList =
        values?.tagIdList
          ?.filter(tag => !!tag && tag.from !== 'new')
          ?.map(tagIdOrTagItem => {
            if (['string', 'number'].includes(typeof tagIdOrTagItem)) {
              return tagIdOrTagItem
            } else {
              return tagIdOrTagItem.tagId
            }
          }) || []
      const data = {
        ...values,
        tagIdList: [...tagIdList, ...newAddedTagIdList],
        // 固定为空值
        userId: ''
      }
      if (data.type === 2) {
        data.picList = this.getUrlListBy(values.picList)
      }
      if (data.type === 3) {
        console.log('values.video: ', values.video)
        data.video = this.getUrlListBy(values.video)[0]
      }
      if (isCreation) delete data.contentId
      const { data: resp } = await this.$http.post(
        'reh_circle/add_or_update/content',
        data
      )
      if (resp.code === 200) {
        this.$message.success(isCreation ? '创建资讯成功' : '修改资讯成功')
        this.innerOnSuccess()
      } else {
        this.$message.error(resp.message)
      }
    },
    getFileListBy (urlList) {
      if (!Array.isArray(urlList)) return []
      return urlList.map((url, index) => ({
        name: `文件-${index + 1}`,
        url: `${systemConfig.OSS_HOST}${url}`
      }))
    },
    getUrlListBy (fileList) {
      if (!Array.isArray(fileList)) return []
      const result = []
      fileList.forEach(file => {
        let url = file.url?.split('.com/')[1]
        if (file.response) {
          url = file.response.name
        }
        result.push(url)
      })
      return result
    }
  }
}
</script>

<style>
  .btn-row {
    padding: 0 24px;
    text-align: right;
  }
</style>
