import { render, staticRenderFns } from "./ZfkpUpload.vue?vue&type=template&id=f4730a86&scoped=true"
import script from "./ZfkpUpload.vue?vue&type=script&lang=js"
export * from "./ZfkpUpload.vue?vue&type=script&lang=js"
import style0 from "./ZfkpUpload.vue?vue&type=style&index=0&id=f4730a86&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f4730a86",
  null
  
)

export default component.exports