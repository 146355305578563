<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/menu/welcome' }"
        >首页</el-breadcrumb-item
      >
      <el-breadcrumb-item>内容管理</el-breadcrumb-item>
      <el-breadcrumb-item>康复圈</el-breadcrumb-item>
      <el-breadcrumb-item>列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <RichContentPreview
      :previewTime="previewTime"
      :htmlContent="currentPreviewContent"
    />
    <EditorDialog ref="editorDialog" @onSuccess="handleEditorSuccess" />
    <div class="btn-row">
      <el-dropdown @command="handlePost">
        <el-button size="medium" type="primary">
          发布 <i class="el-icon-caret-bottom"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="(value, key) in dropdownOptions"
            :key="key"
            :command="key"
            >{{ value }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-table
      v-loading="loading"
      element-loading-text="正在加载"
      :data="list"
      :row-class-name="getRowClassName"
      style="width: 100%;"
      height="630"
      stripe
      border
    >
      <el-table-column type="expand">
        <template slot-scope="props">
          <p class="column-expand">
            <span class="label">摘要：</span>{{ props.row.summary }}
          </p>
        </template>
      </el-table-column>
      <el-table-column prop="title" label="标题"> </el-table-column>
      <el-table-column
        prop="browseNumber"
        label="阅读数"
        width="100"
        align="center"
      >
      </el-table-column>
      <el-table-column label="上架状态" width="100" align="center">
        <template slot-scope="scope">
          <el-button
            @click="handleSwitchPublished(scope.$index, scope.row)"
            slot="reference"
            class="name-wrapper"
            :type="scope.row.status ? 'success' : 'info'"
            size="mini"
          >
            {{ scope.row.status ? '已上架' : '未上架' }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="120" align="center">
        <template slot-scope="scope">
          <el-button
            @click="handleToEdit(scope.row)"
            type="text"
            size="small"
            >编辑</el-button
          >
          <el-button
            @click="handleDelete(scope.row.contentId)"
            type="text"
            size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-size="pageSize"
      layout="total, prev, pager, next"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<!-- 康复圈-文章列表 -->
<script>
import RichContentPreview from '../../../common-components/RichEditor/RichContentPreview.vue'
import EditorDialog from './EditorDialog.vue'

export default {
  components: { RichContentPreview, EditorDialog },
  data () {
    return {
      dropdownOptions: {
        essay: '文章',
        photo: '图片',
        video: '视频'
      },
      previewTime: null,
      currentPreviewContent: null,
      loading: false,
      list: [],
      currentPage: 1,
      pageSize: 10,
      total: 0
    }
  },
  created () {
    this.loadList()
  },
  methods: {
    async loadList () {
      this.loading = true
      try {
        const {
          data: resp
        } = await this.$http.get('reh_circle/check/content_list', {
          params: { page: this.currentPage, pageSize: this.pageSize, role: 1 }
        })
        if (resp?.code !== 200 || !resp?.data) { return this.$message.error(resp.message) }
        const { contentPreResp, pageResp } = resp.data
        this.total = pageResp?.size
        this.list = contentPreResp
      } catch (error) {}
      this.loading = false
    },
    // 设置上/下架
    async handleSwitchPublished (_, row) {
      this.$confirm(
        `确定要${row.status === 1 ? '下架' : '上架'}吗？`,
        undefined,
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(async () => {
          const loading = this.$message({
            message: '操作中',
            duration: 0
          })
          try {
            const nextStatus = row.status === 1 ? 0 : 1
            const {
              data: resp
            } = await this.$http.put('reh_circle/update/content_status', null, {
              params: { contentId: row.contentId, status: nextStatus }
            })
            if (resp.code !== 200) {
              this.$message.error(resp.message)
            } else {
              row.status = nextStatus
            }
          } catch (error) {}
          loading.close()
        })
        .catch(() => {})
    },
    // 获取行类名
    // FIXME 没有生效
    getRowClassName ({ row }) {
      if (row.status === 1) {
        return 'success-row'
      } else {
        return 'warning-row'
      }
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.loadList()
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handlePost (command) {
      if (command === 'essay') {
        this.$router.push('/menu/content/feeds/detail')
      }
      if (command === 'photo') {
        this.$refs.editorDialog.show({ type: 2 })
      }
      if (command === 'video') {
        this.$refs.editorDialog.show({ type: 3 })
      }
    },
    handlePreview (htmlContent) {
      this.currentPreviewContent = htmlContent
      this.previewTime = Date.now().valueOf()
    },
    handleToEdit (feedItem) {
      const { type, contentId } = feedItem
      if (type !== 1) {
        this.$refs.editorDialog.show(feedItem)
        return
      }
      this.$router.push({
        path: '/menu/content/feeds/detail',
        query: {
          contentId
        }
      })
    },
    handleDelete (contentId) {
      this.$confirm('操作不可撤回，确认删除吗？', '请确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const {
            data: resp
          } = await this.$http.put('reh_circle/delete/content', null, {
            params: { contentId }
          })
          if (resp?.code === 200) {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.loadList()
          } else {
            this.$message({
              message: `删除失败 ${resp?.message || ''}`,
              type: 'warning'
            })
          }
        })
        .catch(() => {})
    },
    handleEditorSuccess () {
      this.currentPage = 1
      this.loadList()
    }
  }
}
</script>

<style scoped lang="less">
.btn-row {
  margin: 0 24px 24px 24px;
  text-align: right;
}

.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

.column-expand {
  padding-left: 12px;
  text-align: 'justify';
  line-height: 1.6rem;
  .label {
    font-weight: bold;
  }
}
</style>
