<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/menu/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>商城管理</el-breadcrumb-item>
      <el-breadcrumb-item>上架商品</el-breadcrumb-item>
    </el-breadcrumb>
    <div>
      <!--   小标题：分类   -->
      <el-divider content-position="left">分类</el-divider>
      <el-cascader
        :options="options"
        placeholder="请选择分类"
        @change="handleChange"
      ></el-cascader>
    </div>

    <div>
      <el-divider content-position="left">商品信息</el-divider>
      <el-form :model="goods" label-width="120px">
        <el-form-item label="产品编码">
          <el-input v-model="goods.productCode" placeholder="请输入产品编码，若无则填写产品名称"></el-input>
        </el-form-item>
        <el-form-item label="产品名称">
          <el-input v-model="goods.name" placeholder="请输入产品名称"></el-input>
        </el-form-item>
        <el-form-item label="产品摘要">
          <el-input v-model="goods.brief" placeholder="Enter brief"></el-input>
        </el-form-item>
        <el-form-item label="价格">
          <el-input v-model="goods.price" placeholder="Enter price"></el-input>
        </el-form-item>
        <el-form-item label="主图">
          <el-upload
            ref="uploadMainPic"
            :action="uploadUrl"
            :http-request="uploadRequest"
            :on-change="onChange"
            :on-error="uploadError"
            :on-progress="onProgress"
            :on-success="uploadSuccess"
            :show-file-list="false"
            class="avatar-uploader"
            drag>
            <img v-if="mainPicUrl" :src="mainPicUrl" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="详情图">
          <el-upload
            ref="uploadMainPic"
            :action="uploadUrl"
            :before-upload="beforeDetailPicsUpload"
            :file-list="fileList"
            :http-request="uploadRequest"
            :on-change="onDetailPicChange"
            :on-error="uploadError"
            :on-progress="onProgress"
            :on-success="uploadSuccess"
            class="upload-demo"
            drag
            list-type="picture"
            multiple>
            <el-button size="small" type="primary">点击上传</el-button>
            <!--            <el-button size="small" style="margin-left: 10px;" type="success" @click="submitUploadDetailPics">上传到服务器-->
            <!--            </el-button>-->
          </el-upload>
        </el-form-item>

        <!--    提交    -->
        <el-form-item v-if="createSuccess" size="large">
          <div style="color: #136b59">
            <div>商品详情页链接：<a :href="goodsWebUrl" target="_blank">{{ goodsWebUrl }}</a></div>
            <div style="margin-top: 16px">添加成功，请刷新后继续添加下一款产品</div>
          </div>
        </el-form-item>
        <el-form-item v-else size="large">
          <el-button type="primary" @click="handleSubmit">立即创建</el-button>
        </el-form-item>
      </el-form>
    </div>

  </div>
</template>

<script>
export default {

  data () {
    return {
      goodsWebUrl: null,
      createSuccess: false,
      uploadUrl: '',
      options: [
        {
          value: 'hospital1',
          label: 'Hospital 1',
          children: [
            {
              value: 'product1',
              label: 'Product 1'
            },
            {
              value: 'product2',
              label: 'Product 2'
            }
          ]
        },
        {
          value: 'hospital2',
          label: 'Hospital 2',
          children: [
            {
              value: 'product3',
              label: 'Product 3'
            },
            {
              value: 'product4',
              label: 'Product 4'
            }
          ]
        }
        // Add more hospitals and products as needed
      ],
      mainPicUrl: null,
      detailPics: [],
      fileList: [],
      goods: {
        productCode: null,
        name: null,
        brief: null,
        smallPicUrl: null,
        bigPicUrl: null,
        price: null,
        stock: 500,
        active: 1,
        hospitalId: null,
        catalogId: null,
        detailPics: []
      },
      beforeArr: [],
      currentUpload: 1
    }
  },
  created () {
  },
  mounted () {
    this.loadCatalogs()
  },
  methods: {
    submitUploadDetailPics () {
      this.currentUpload = 2
      this.$refs.uploadMainPic.submit()
    },
    handleSubmit (values) {
      // 请求后台接口：录入APP更新信息
      this.goods.detailPics = this.detailPics
      // 检查this.goods是否有空值
      for (const key in this.goods) {
        if (this.goods[key] === null) {
          return this.$message.error('请填写完整信息')
        }
      }
      console.log('提交', this.goods)
      this.$http.post('/mallGoods/createGoods', this.goods).then(resp => {
        console.log(resp)
        resp = resp.data
        if (resp?.code !== 200) {
          return this.$message.error(resp.message)
        }
        console.log('创建成功')
        this.createSuccess = true
        this.$message.success('商品添加成功')
        // this.$router.push('/mall/goods')
        this.goodsWebUrl = `https://doucan.jianerkanggz.cn/#/MallDetailPage?goodId=${resp.data.id}&article=true`
      })
    },
    beforeDetailPicsUpload (file) {
      // this.beforeArr.push(file)
      // this.detailPics.push(file)
    },
    beforeAvatarUpload: function (file) {
      // this.qiniuData.key = file.name
      // const isJPG = file.type === 'image/jpeg'
      // const isPNG = file.type === 'image/png'
      // const isLt2M = file.size / 1024 / 1024 < 2
      // if (!isJPG && !isPNG) {
      //   this.$message.error('图片只能是 JPG/PNG 格式!')
      //   return false
      // }
      // if (!isLt2M) {
      //   this.$message.error('图片大小不能超过 2MB!')
      //   return false
      // }
    },
    handleAvatarSuccess (res, file) {
      // this.mainPicUrl = this.upload_qiniu_addr + res.key
    },
    handleChange (value) {
      this.goods.hospitalId = value[0]
      this.goods.catalogId = value[1]
    },
    async loadCatalogs () {
      const { data: resp } = await this.$http.get('/mallGoods/catalogue/all')
      console.log('loadCatalogs', resp)
      if (resp?.code !== 200 || !resp?.data) {
        return this.$message.error(resp.message)
      }
      this.options = resp.data.map(hospital => ({
        value: hospital.hospitalId,
        label: hospital.hospitalName,
        children: hospital.catalogues.map(catalogue => ({
          value: catalogue.id,
          label: catalogue.name
        }))
      }))
    },
    /**
     * @description [UploadRequest 覆盖默认的上传行为，实现自定义上传]
     * @author shanshuizinong
     * @param {object} option [上传选项]
     * @return {null} [没有返回]
     */
    async uploadRequest (option) {
      console.log('【uploadRequest】', option)
      try {
        const OSS = require('ali-oss')
        const ossConfig = {
          // region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
          region: 'oss-cn-shenzhen',
          // 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录RAM控制台创建RAM账号。
          accessKeyId: 'LTAIAcClE56I1mY0',
          accessKeySecret: 'IIPoiW0o3noCHU9bRT5vyAw0EDtem8',
          bucket: 'ihealthy'
        }
        const client = new OSS(ossConfig)
        const file = option.file
        // console.log(file)
        // fileName : 时间戳+file后缀
        const fileName = Date.now() + '.' + file.name.split('.').pop()
        // 分片上传文件
        const ret = await client.multipartUpload('mall/' + 'image' + '/' + fileName, file, {
          progress: async function (p) {
            // console.log('multipartUpload', p)
            const e = {}
            e.percent = parseInt(p * 100)
            option.onProgress(e)
          }
        })

        if (ret.res.statusCode === 200) {
          // option.onSuccess(ret)
          return ret
        } else {
          option.onError('上传失败')
        }
      } catch (err) {
        console.error(err)

        option.onError('上传失败')
        this.$error(err.message)
      }
    },
    onProgress (event, file, fileList) {
      // console.log('onProgress', event)
      this.progress = event.percent
    },
    uploadError (err, file, fileList) {
      this.$message.error(err)
      console.log('【uploadError】', err)
    },
    uploadSuccess (response, file, fileList) {
      console.log('【uploadSuccess】', response)
      this.progressStatus = 'success'
      this.$message({
        message: '上传成功',
        type: 'success'
      })
      // 文件URL
      // this.mainPicUrl = response.res.requestUrls[0].substring(0, response.res.requestUrls[0].indexOf('?'))
      if (this.currentUpload === 1) {
        this.mainPicUrl = 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/' + response.name
        console.log('【主图文件URL】', this.mainPicUrl)
        this.goods.bigPicUrl = this.mainPicUrl
        this.goods.smallPicUrl = this.mainPicUrl
      } else if (this.currentUpload === 2) {
        this.detailPics.push('https://ihealthy.oss-cn-shenzhen.aliyuncs.com/' + response.name)
        // this.beforeArr.forEach((item, index) => {
        //   if (item.uid === file.uid) { // 与正确顺序的UID进行比对，添加进将要上传后台的数组
        //     this.detailPics.splice(index, 1, 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/' + response.name)
        //   }
        // })
      }
    },
    onChange (file, fileList) {
      console.log('【onChange】', file)
    },
    onDetailPicChange (file, fileList) {
      console.log('【onChangeDetailPic】', file)
      this.currentUpload = 2
    }
  }

}
</script>

<style lang="less" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #6b6b6b;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 200px;
  height: 200px;

}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 200px;
  border: 1px dashed #bebebe;
  border-radius: 6px;
  height: 200px;
  line-height: 200px;
  text-align: center;
}

.avatar {
  width: 200px;
  height: 200px;
  display: block;
}
</style>
