<template>
  <div id="app">
    <!-- APP根组件 -->
    <!-- 路由占位符 -->
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'app'
}
</script>

<style>
/* @import url("./assets/css/font.css"); */
/*html {*/
/*  -webkit-user-select: text;*/
/*}*/
</style>
