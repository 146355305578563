<template>
  <div>
    <h2>{{pageTitle}}</h2>
  </div>
</template>

<!-- 信息流-标签管理 -->
<script>
export default {
  components: {},
  data () {
    return {
      pageTitle: '标签管理'
    }
  },
  methods: {}
}
</script>

<style scoped>
  h2 {
    font-size: 20px;
  }
</style>
