<template>
  <div>
    <h1>组件测试</h1>
    <h2>图片上传：FileUploadOssPics</h2>

    <div style="margin: 32px">
      <FileUploadOssPics :dir="picDir" @urlListChange="urlListChange"></FileUploadOssPics>
    </div>

    <h1 style="margin-top: 32px">AliOssUploadUtils测试</h1>
    <input
      type="file"
      name="file"
      @change="selectFileEvent"
      ref="file2"
    />
    <!--    <el-button @click="selectFile">选择文件</el-button>-->
    <div></div>

  </div>
</template>

<script>
import FileUploadOssPics from '../../common-components/FileUploadOss/FileUploadOssPics'
import { uploadFile } from '../../utils/AliOssUploadUtils'

export default {
  name: 'TestFileUpload',
  components: { FileUploadOssPics },
  data () {
    return {
      picDir: 'article/feeds/文章标题xxx2'
    }
  },
  methods: {
    urlListChange (urlList) {
      console.log('图片url列表', urlList)
    },
    selectFileEvent (e) {
      console.log('文件选择', e.target.files)
      // 上传逻辑
      uploadFile(
        this.picDir + '/test2',
        e.target.files[0],
        (percent) => {
          console.log('上传进度', percent)
        },
        (fileUrl) => {
          console.log('上传成功', fileUrl)
        },
        (error) => {
          console.log('上传失败', error)
        }
      )
    }

  }
}
</script>

<style scoped>

</style>
