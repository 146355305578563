<template>
  <div class="feeds-detail">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/menu/welcome' }"
        >首页</el-breadcrumb-item
      >
      <el-breadcrumb-item>内容管理</el-breadcrumb-item>
      <el-breadcrumb-item>康复圈</el-breadcrumb-item>
      <el-breadcrumb-item v-if="!!routeName">{{
        routeName
      }}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <el-form
      v-loading="loading"
      ref="form"
      :rules="rules"
      :model="article"
      label-position="left"
      label-width="auto"
    >
      <el-form-item label="标签" prop="tagIdList">
        <!--TODO （寻找更合理的方式进行优化）这里加个条件渲染，是因为数据缓存问题会导致 TagSelect 渲染不出初始化数据的问题 -->
        <TagSelect v-if="!loading" v-model="article.tagIdList" />
      </el-form-item>
      <el-form-item label="标题" prop="title">
        <el-input v-model="article.title" placeholder="请填写标题"></el-input>
      </el-form-item>
      <el-form-item label="封面" prop="cover">
        <FileUploadOssPics
          v-model="article.cover"
          :dir="picDir"
          :fileLimit="3"
          :allowMultiple="true"
        />
      </el-form-item>
      <el-form-item label="摘要" prop="summary">
        <el-input
          v-model="article.summary"
          placeholder="请填写摘要"
          :autosize="{ minRows: 3, maxRows: 6}"
          type="textarea"
          maxlength="200"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="内容" prop="content">
        <RichEditor
          v-model="article.content"
          :readonly="true"
          :fileDir="picDir"
        ></RichEditor>
      </el-form-item>
      <el-form-item class="btn-row">
        <el-button type="primary" @click="handleSubmit">提交</el-button>
        <el-button @click="handleReset">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<!-- 康复圈-创建、编辑文章 -->
<script>
import FileUploadOssPics from '../../../common-components/FileUploadOss/FileUploadOssPics.vue'
import RichEditor from '../../../common-components/RichEditor/RichEditor.vue'
import { scrollToTop } from '../../../utils'
import TagSelect from './TagSelect.vue'
import systemConfig from '../../../constants/systemConfig'

export default {
  components: { TagSelect, FileUploadOssPics, RichEditor },
  data () {
    return {
      routeName: '文章详情',
      article: {
        tagIdList: null,
        title: null,
        summary: null,
        content: null,
        cover: [],
        // 文章类型固定为 1
        type: 1,
        // 固定为空值
        userId: '',
        contentId: null
      },
      rules: {
        tagIdList: [
          { required: true, message: '标签不能为空', trigger: 'submit' }
        ],
        title: [{ required: true, message: '标题不能为空', trigger: 'blur' }],
        cover: [
          { required: true, message: '封面图不能为空', trigger: 'submit' }
        ],
        summary: [{ required: true, message: '摘要不能为空', trigger: 'blur' }],
        content: [
          { required: true, message: '正文不能为空', trigger: 'submit' }
        ]
      },
      loading: false
    }
  },
  computed: {
    picDir () {
      const folderDir =
        this.article.title ||
        `未知标题文章/${new Date().toDateString().replaceAll(' ', '-')}`
      return `article/feeds/${folderDir}`
    }
  },
  mounted () {
    const params = this.$route.query
    if (!params?.contentId) {
      this.routeName = '新建文章'
      return
    }
    this.routeName = '编辑文章'
    this.loadDetail({ ...params })
  },
  beforeRouteUpdate (to, _, next) {
    const params = to.params
    if (!params?.contentId) {
      this.routeName = '新建文章'
      return
    }
    this.routeName = '编辑文章'
    this.loadDetail({ ...params })
    next()
  },
  methods: {
    async loadDetail (params) {
      this.loading = true
      const { data: resp } = await this.$http.get('reh_circle/check/content', {
        params: { contentId: params.contentId }
      })
      const { code, data } = resp
      if (code !== 200 || !data) {
        try {
          await this.$alert('查询文章详情失败', undefined, {
            confirmButtonText: '确定',
            type: 'warning'
          })
        } catch (error) {}
        this.$router.replace('/menu/content/feeds')
        return
      }
      this.article = {
        ...data,
        cover: this.getFileListBy(data?.cover)
      }
      this.loading = false
    },
    async postArticle () {
      const values = this.article
      const coverString = this.getCoverStringBy(values.cover)
      const isCreation = !this.article.contentId
      const newTagItemList = values.tagIdList.filter(
        tag => tag.from === 'new'
      )
      const newAddedTagIdList = await this.postTagList(newTagItemList)
      const tagIdList =
        values?.tagIdList
          ?.filter(tag => !!tag && tag.from !== 'new')
          ?.map(tagIdOrTagItem => {
            if (['string', 'number'].includes(typeof tagIdOrTagItem)) {
              return tagIdOrTagItem
            } else {
              return tagIdOrTagItem.tagId
            }
          }) || []
      const data = {
        ...values,
        cover: coverString,
        tagIdList: [...tagIdList, ...newAddedTagIdList]
      }
      if (isCreation) delete data.contentId
      const { data: resp } = await this.$http.post(
        'reh_circle/add_or_update/content',
        data
      )
      if (resp.code === 200) {
        try {
          const tipsText = isCreation ? '创建文章成功' : '修改文章成功'
          const confirmButtonText = isCreation ? '返回文章列表' : '好的'
          await this.$alert(tipsText, undefined, {
            confirmButtonText,
            type: 'success'
          })
        } catch (error) {
          // TODO 可以优化，用户取消则留在当前页面继续修改当前文章
        }
        if (isCreation) this.$router.replace('/menu/content/feeds')
      } else {
        this.$message.error(resp.message)
      }
    },
    async postTagList (tagItemList) {
      try {
        if (!tagItemList?.length) return []
        const { data: resp } = await this.$http.post(
          '/reh_circle/add/tags',
          null,
          { params: { nameList: tagItemList.map(tag => tag.name).join(',') } }
        )
        if (resp?.code !== 200 || !resp?.data) throw new Error(resp)
        return resp.data.map(tag => tag.id)
      } catch (error) {
        console.log(error)
        this.$message.error('添加标签出错')
      }
    },
    async handleSubmit () {
      try {
        await this.$refs.form.validate()
        this.postArticle()
      } catch (_) {
        this.$message({
          message: '提交失败，请检查表单内容',
          type: 'warning'
        })
        scrollToTop({ delayForAnimation: true })
      }
    },
    async handleReset () {
      const hasConfirmed = await this.$confirm(
        '未提交的内容将丢失，确认重置吗？',
        undefined,
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
      if (!hasConfirmed) return
      this.$refs.form.resetFields()
    },
    getFileListBy (coverString) {
      if (typeof coverString !== 'string') return []
      return coverString
        .split(',')
        .map((url, index) => ({ name: `图片-${index + 1}`, url: `${systemConfig.OSS_HOST}${url}` }))
    },
    getCoverStringBy (fileList) {
      if (!Array.isArray(fileList)) return ''
      const result = []
      fileList.forEach(file => {
        let url = file.url.split('.com/')[1]
        if (file.response) {
          url = file.response.name
        }
        result.push(url)
      })
      return result.join(',')
    }
  }
}
</script>

<style lang="less" scoped>
.feeds-detail {
  .btn-row {
    text-align: center;
  }
}
</style>
