<template>

  <div class="container">

    <img
      class="img1"
      src="../../../assets/sqym.png"
    >

    <div class="tips">*请填入以下信息，医护人员会根据以下信息为您提供康复指导</div>

    <div class="input_box">
      <el-input
        class="input_info"
        v-model="input_name"
        placeholder="请输入姓名"
      ></el-input>
    </div>

    <div class="input_box">
      <el-input
        class="input_info"
        v-model="input_age"
        placeholder="请输入年龄"
      ></el-input>
    </div>

    <div class="input_box">
      <el-input
        class="input_info"
        v-model="input_phone"
        placeholder="请输入手机号"
      ></el-input>
    </div>

    <div class="input_box">
      <el-input
        class="input_info"
        v-model="input_city"
        placeholder="请输入居住城市"
      ></el-input>
    </div>

    <div class="input_box">
      <cube-radio-group
        v-model="selected_sex"
        :options="options_sex"
        :horizontal="true"
      />
    </div>

    <div class="input_title">请选择您要康复的类型</div>

    <div class="input_box">
      <cube-checkbox-group
        v-model="types"
        :options="typeOptions"
        :col-num="2"
      />
    </div>

    <cube-button
      @click="showDialog"
      style="margin-top:20px"
    >提交</cube-button>

  </div>

</template>

<script>

export default {

  data () {
    return {
      doctorid: '',
      input_city: '',
      input_name: '',
      input_phone: '',
      input_age: '',
      selected_sex: '',
      options_sex: ['男', '女'],
      types: [],
      typeOptions: ['中风康复', '骨科术后', '骨质疏松', '慢性疼痛', '呼吸康复', '截瘫康复']

    }
  },
  created () {
    this.doctorid = this.$route.params.doctorid
    console.log(this.doctorid)
  },
  methods: {
    async showDialog () {
      console.log(this.doctorid)
      console.log(this.input_city)
      console.log(this.input_name)
      console.log(this.input_phone)
      console.log(this.input_age)
      console.log(this.selected_sex)
      console.log(this.types)

      // 提交到后台，进行登记和注册

      // this.$createDialog({
      //   type: 'alert',
      //   title: '',
      //   content: '正在提交申请'
      // }).show()
      const toast = this.$createToast({
        time: 0,
        txt: '正在提交申请...',
        mask: true
      })
      toast.show()

      var req = {}
      req.inviteCode = this.doctorid
      req.name = this.input_name
      req.phone = this.input_phone
      req.city = this.input_city
      req.sex = this.sex
      req.age = this.input_age
      req.types = this.types

      const { data: resp } = await this.$http.post('register/webApply', req)

      toast.hide()
      if (resp.code === 200) {
        this.$router.push('/PlanApplySuccess')
      } else {
        // this.$message.error(resp.message)
        this.$createDialog({
          type: 'alert',
          title: '',
          content: '错误：' + resp.message
        }).show()
      }
    }

  }

}
</script>

<style lang="less" scoped>
.container {
  width: 100%;

  background: #76c0fb;
}

.img1 {
  width: 100%;
  height: auto;
}

.tips {
  padding-left: 20px;
  padding-right: 20px;
  line-height: 20px;
}
.input_box {
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
}
.input_info {
  font-size: 20px;
  box-sizing: content-box;
  width: 100%;
}

.input_title {
  text-align: center;
  margin-left: 20px;
  margin-top: 10px;
  color: whitesmoke;
}

.el-input__inner::placeholder {
  color: #333;
}
.el-input__inner::-webkit-input-placeholder {
  color: #333;
}

.el-input__inner:-moz-placeholder {
  color: #333;
}

.el-input__inner:-ms-input-placeholder {
  color: #333;
}
</style>
