<template>

  <el-container class="home-container">
    <el-header>
      <div><img
        alt=""
        src="../assets/doucan-logo.png"
      >
        <span>动优后台管理系统</span>
      </div>
      <div>
        <el-button type="primary">退出</el-button>
        <el-button type="success">设置</el-button>
      </div>

    </el-header>
    <el-container>
      <el-aside :width="isCollapse ? '64px':'200px'">
        <div
          class="toggle-button"
          @click="handleCollapse"
        >
          <i :class="[isCollapse ? 'el-icon-d-arrow-right' : 'el-icon-d-arrow-left']"></i>
        </div>
        <!-- 侧边栏菜单区 -->
        <el-menu
          :collapse="isCollapse"
          :collapse-transition="false"
          :default-active="currentPath"
          :unique-opened="true"
          active-text-color="#ffd04b"
          background-color="#383d63"
          router
          text-color="#fff"
        >
          <el-menu-item
            index="/menu/welcome"
            @click="menuClick($event.index)"
          >
            <i class="el-icon-s-home"></i>
            <span slot="title">首页</span>
          </el-menu-item>
          <!-- 一级菜单 -->
          <el-submenu index="1">
            <!-- 一级菜单模板区 -->
            <template slot="title">
              <!-- 图标 -->
              <i class="el-icon-location"></i>
              <!-- 文字 -->
              <span>运营数据</span>
            </template>
            <!--            <el-menu-item-group>-->
            <!--              <template slot="title">概览</template>-->
            <!--              &lt;!&ndash; 二级菜单 &ndash;&gt;-->
            <!--              <el-menu-item-->
            <!--                index="/menu/data/overview"-->
            <!--                @click="menuClick($event.index)"-->
            <!--              >数据概览</el-menu-item>-->
            <!--            </el-menu-item-group>-->
            <!-- 菜单分组 -->
            <el-menu-item-group>
              <template slot="title">用户管理</template>
              <!-- 二级菜单 -->
              <el-menu-item
                index="/menu/data/user/doctor"
                @click="menuClick($event.index)"
              >医生
              </el-menu-item>
              <el-menu-item
                index="/menu/data/user/patient"
                @click="menuClick($event.index)"
              >患者
              </el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <template slot="title">医院管理</template>
              <!-- 二级菜单 -->
              <el-menu-item
                index="/menu/data/hospital/register"
                @click="menuClick($event.index)"
              >注册选择医院
              </el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <template slot="title">MDT群管理</template>
              <!-- 二级菜单 -->
              <el-menu-item
                index="/menu/data/mdt/list"
                @click="menuClick($event.index)"
              >MDT群列表
              </el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <template slot="title">业务操作</template>
              <!-- 二级菜单 -->
              <el-menu-item
                index="/menu/data/operation/doctor"
                @click="menuClick($event.index)"
              >医生业务
              </el-menu-item>
            </el-menu-item-group>
            <!--            <el-menu-item-group>-->
            <!--              <template slot="title">订单管理</template>-->
            <!--              &lt;!&ndash; 二级菜单 &ndash;&gt;-->
            <!--              <el-menu-item-->
            <!--                index="/menu/data/order/all"-->
            <!--                @click="menuClick($event.index)"-->
            <!--              >全部订单</el-menu-item>-->
            <!--            </el-menu-item-group>-->
            <!--            <el-menu-item-group title="分组2">-->
            <!--              <el-menu-item-->
            <!--                index="1-3"-->
            <!--                disabled-->
            <!--              >选项3</el-menu-item>-->
            <!--            </el-menu-item-group>-->
            <!--            <el-submenu index="1-4">-->
            <!--              <template-->
            <!--                slot="title"-->
            <!--                disabled-->
            <!--              >选项4</template>-->
            <!--              <el-menu-item-->
            <!--                index="1-4-1"-->
            <!--                disabled-->
            <!--              >选项1</el-menu-item>-->
            <!--            </el-submenu>-->
          </el-submenu>

          <el-submenu index="2">
            <template slot="title">
              <i class="el-icon-menu"></i>
              <span slot="title">内容管理</span>
            </template>
            <el-submenu index="2-1">
              <template slot="title">
                <i class="el-icon-camera"></i>
                <span slot="title">训练视频管理</span>
              </template>
              <el-menu-item
                index="/menu/content/course"
                @click="menuClick($event.index)"
              >
                <!-- 图标 -->
                <i class="el-icon-video-camera"></i>
                <!-- 文字 -->
                <span>训练视频库</span>
              </el-menu-item>
              <el-menu-item
                index="/menu/content/courseUpload"
                @click="menuClick($event.index)"
              >
                <!-- 图标 -->
                <i class="el-icon-upload"></i>
                <!-- 文字 -->
                <span>上传视频</span>
              </el-menu-item>
            </el-submenu>

            <el-submenu index="2-2">
              <template slot="title">
                <i class="el-icon-camera"></i>
                <span slot="title">资讯文章</span>
              </template>
              <el-menu-item
                index="/menu/content/article"
                @click="menuClick($event.index)"
              >
                <!-- 图标 -->
                <i class="el-icon-video-camera"></i>
                <!-- 文字 -->
                <span>文章列表</span>
              </el-menu-item>
              <el-menu-item
                index="/menu/content/articleUpload"
                @click="menuClick($event.index)"
              >
                <!-- 图标 -->
                <i class="el-icon-upload"></i>
                <!-- 文字 -->
                <span>添加文章</span>
              </el-menu-item>
              <el-menu-item
                index="/menu/content/articleCategories"
                @click="menuClick($event.index)"
              >
                <!-- 图标 -->
                <i class="el-icon-upload"></i>
                <!-- 文字 -->
                <span>分类|标签</span>
              </el-menu-item>
            </el-submenu>

            <el-submenu index="2-3">
              <template slot="title">
                <i class="el-icon-camera"></i>
                <span slot="title">中风科普</span>
              </template>
              <el-menu-item
                index="/menu/content/zfkp"
                @click="menuClick($event.index)"
              >
                <!-- 图标 -->
                <i class="el-icon-video-camera"></i>
                <!-- 文字 -->
                <span>已上架</span>
              </el-menu-item>
              <el-menu-item
                index="/menu/content/zfkpUpload"
                @click="menuClick($event.index)"
              >
                <!-- 图标 -->
                <i class="el-icon-upload"></i>
                <!-- 文字 -->
                <span>上传</span>
              </el-menu-item>
            </el-submenu>

            <el-submenu index="2-4">
              <template slot="title">
                <i class="el-icon-postcard"></i>
                <span slot="title">康复圈</span>
              </template>
              <el-menu-item index="/menu/content/feeds" @click="menuClick($event.index)">
                <i class="el-icon-files"></i>
                <span>资讯列表</span>
              </el-menu-item>
              <el-menu-item index="/menu/content/feeds/tag" @click="menuClick($event.index)">
                <i class="el-icon-collection-tag"></i>
                <span>标签管理</span>
              </el-menu-item>
              <el-menu-item index="/menu/content/feeds/group" @click="menuClick($event.index)">
                <i class="el-icon-collection"></i>
                <span>分类管理</span>
              </el-menu-item>
            </el-submenu>

          </el-submenu>

          <el-submenu index="3">
            <template slot="title">
              <i class="el-icon-document"></i>
              <span slot="title">系统服务</span>
            </template>
            <el-menu-item
              index="/menu/system/doctorApkUpload"
              @click="menuClick($event.index)"
            >
              <!-- 图标 -->
              <i class="el-icon-video-camera"></i>
              <!-- 文字 -->
              <span>apk-发布</span>
            </el-menu-item>
            <el-menu-item
              index="/menu/content/zfkpUpload"
              @click="menuClick($event.index)"
            >
              <!-- 图标 -->
              <i class="el-icon-upload"></i>
              <!-- 文字 -->
              <span>上传</span>
            </el-menu-item>
          </el-submenu>

          <el-submenu index="4">
            <template slot="title">
              <i class="el-icon-shopping-cart-1"></i>
              <span slot="title">商城管理</span>
            </template>
            <el-submenu index="5-1">
              <template slot="title">
                <i class="el-icon-sell"></i>
                <span slot="title">商品管理</span>
              </template>
              <el-menu-item
                index="/menu/mall/CreateMallGoods"
                @click="menuClick($event.index)"
              >
                <i class="el-icon-upload"></i>
                <span>上架商品</span>
              </el-menu-item>
              <el-menu-item
                index="/menu/mall/goods/edit"
                @click="menuClick($event.index)"
              >
                <i class="el-icon-edit"></i>
                <span>编辑商品</span>
              </el-menu-item>
            </el-submenu>
            <el-submenu index="5-2">
              <template slot="title">
                <i class="el-icon-document"></i>
                <span slot="title">订单管理</span>
              </template>
              <el-menu-item
                index="/menu/mall/orders/list"
                @click="menuClick($event.index)"
              >
                <i class="el-icon-document"></i>
                <span>订单列表</span>
              </el-menu-item>
            </el-submenu>
          </el-submenu>

          <el-submenu index="99">
            <template slot="title">
              <i class="el-icon-setting"></i>
              <span slot="title">组件测试</span>
            </template>
            <el-menu-item
              index="/menu/test/TestFileUpload"
              @click="menuClick($event.index)"
            >
              <!-- 图标 -->
              <i class="el-icon-upload"></i>
              <!-- 文字 -->
              <span>文件上传测试</span>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>

</template>

<script>
export default {
  created () {
    var path = window.sessionStorage.getItem('menuActivePath')
    console.log(path)
    this.currentPath = path === null ? '/menu/welcome' : path
  },
  data () {
    return {
      currentPath: '',
      token: window.sessionStorage.getItem('token'),
      isCollapse: false
    }
  },
  methods: {
    handleCollapse () {
      this.isCollapse = !this.isCollapse
    },
    menuClick (path) {
      console.log(path)
      window.sessionStorage.setItem('menuActivePath', path)
      this.currentPath = path
    }
  }

}
</script>

<style lang="less" scoped>
.home-container {
  background-color: #101014;
  height: 100%;
}

.el-header {
  background-color: #383d63;
  color: #fff;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    display: flex;
    align-items: center;

    img {
      width: 70px;
      margin-right: 20px;
    }
  }
}

.el-aside {
  background-color: #383d63;
  color: #fff;

  .el-menu {
    border-right: none;
  }
}

.el-main {
  background-color: #fff;
}

.toggle-button {
  background-color: #52578e;
  color: #fff;
  text-align: center;
  font-size: 10px;
  line-height: 24px;
  // letter-spacing: 0.1em;
  // 鼠标变成点击小手提示
  cursor: pointer;
}
</style>
