<template>
  <div>
    <el-tag
      class="selected-tag"
      v-for="tagItem in tagSelection"
      :key="`${tagItem.middleId}-${tagItem.tagId}`"
      closable
      @close="handleRemoveFromSelection(tagItem)"
      effect="plain"
    >
      {{ tagItem.name }}
    </el-tag>
    <el-popover
      ref="popover"
      placement="bottom-start"
      title="标签"
      width="560"
      trigger="click"
      v-model="visible"
      :visible-arrow="false"
    >
      <div class="popover-content">
        <el-divider />
        <el-autocomplete
          class="auto-complete-input"
          v-model="input"
          @keyup.enter.native="handlerInputConfirm"
          placeholder="请输入文字搜索，Enter 键可添加自定义标签"
          :fetch-suggestions="handleInputSearch"
          @select="handleInputSelect"
        />
        <el-row class="select-container" :gutter="0">
          <el-col class="group-col" :span="8">
            <div
              v-for="groupItem in groupList"
              :key="groupItem.id"
              :class="[
                'group-item',
                currentGroupId === groupItem.id ? 'active' : ''
              ]"
              @click="handleGroupClick(groupItem.id)"
            >
              {{ groupItem.name }}
            </div>
          </el-col>
          <el-col class="tag-col" :span="16">
            <el-tag
              v-for="tagItem in tagList"
              :key="tagItem.tagId"
              :effect="
                tagKeySelection.includes(`${tagItem.middleId}-${tagItem.tagId}`)
                  ? 'dark'
                  : 'light'
              "
              @click="handleTagClick(tagItem)"
              size="medium"
            >
              {{ tagItem.name }}
            </el-tag>
          </el-col>
        </el-row>
      </div>
      <el-button slot="reference" icon="el-icon-plus" size="small"
        >添加标签</el-button
      >
    </el-popover>
  </div>
</template>

<!-- 标签选择器 -->
<script>
export default {
  props: {
    value: Array
  },
  data () {
    return {
      // 数据
      input: null,
      fullTagList: [],
      groupList: [],
      tagList: [],
      // 状态
      currentGroupId: null,
      tagSelection: [],
      visible: false
    }
  },
  mounted () {
    this.loadFullTagList()
    this.loadGroupList()
  },
  computed: {
    tagKeySelection () {
      if (!this.tagSelection?.length) return []
      return this.tagSelection.map(tag => `${tag.middleId}-${tag.tagId}`)
    },
    autocompleteList () {
      if (!this.fullTagList?.length) return []
      return this.fullTagList
        .map(this.itemDataMapper)
        .filter(
          tag => !this.tagKeySelection.includes(`${tag.middleId}-${tag.tagId}`)
        )
    }
  },
  watch: {
    value () {
      this.setSelection()
    },
    tagSelection (nextSelection) {
      if (nextSelection === this.value) return
      this.$emit('input', nextSelection)
    },
    fullTagList () {
      this.setSelection()
    },
    groupList (nextList) {
      this.currentGroupId = nextList?.[0]?.id || null
    },
    currentGroupId (nextItem) {
      if (!nextItem) return
      this.loadTagListByGroupId(nextItem)
    }
  },
  methods: {
    async loadFullTagList () {
      const { data: resp } = await this.$http.get('/reh_circle/find/tags')
      // TODO 增加页面显示查询出错，和重试按钮
      if (resp?.code !== 200 || !resp?.data) {
        return this.$message.error(resp.message)
      }
      this.fullTagList = resp.data
    },
    async loadGroupList () {
      const { data: resp } = await this.$http.get('/reh_circle/find/sets')
      // TODO 增加页面显示查询出错，和重试按钮
      if (resp?.code !== 200 || !resp?.data) {
        return this.$message.error(resp.message)
      }
      this.groupList = resp.data
    },
    async loadTagListByGroupId (groupId) {
      const { data: resp } = await this.$http.get(
        '/reh_circle/find/tags/by_set_id',
        {
          params: { setId: groupId }
        }
      )
      // TODO 增加页面显示查询出错，和重试按钮
      if (resp?.code !== 200 || !resp?.data) {
        return this.$message.error(resp.message)
      }
      this.tagList = resp.data
    },
    setSelection () {
      if (this.tagSelection === this.value) return
      if (!Array.isArray(this.value)) return
      const itemType = typeof this.value[0]
      // 初始值
      if (['string', 'number'].includes(itemType)) {
        this.tagSelection = this.fullTagList
          .filter(tag => this.value.includes(tag.id))
          .map(tag => ({ name: tag.name, middleId: tag.name, tagId: tag.id }))
        return
      }
      // 回填值
      if (itemType === 'object') {
        this.tagSelection = this.value
      }
    },
    itemDataMapper (tagItem) {
      return {
        value: tagItem.name,
        name: tagItem.name,
        tagId: tagItem.id,
        middleId: tagItem.name,
        from: 'autocomplete'
      }
    },
    handleGroupClick (itemId) {
      this.currentGroupId = itemId
    },
    handleTagClick (item) {
      const matched = this.tagSelection.find(
        t => t.tagId === item.tagId && t.middleId === item.middleId
      )
      if (matched) {
        this.tagSelection = this.tagSelection.filter(
          tag =>
            `${tag.middleId}-${tag.tagId}` !== `${item.middleId}-${item.tagId}`
        )
      } else {
        this.tagSelection.push(item)
      }
    },
    handleInputSearch (queryString, callback) {
      const autocompleteList = this.autocompleteList
      const results = queryString?.trim()
        ? autocompleteList.filter(
          item =>
            item.name.toLowerCase().indexOf(queryString.toLowerCase()) !== -1
        )
        : autocompleteList
      callback(results)
    },
    handleInputSelect (item) {
      this.tagSelection.push(item)
      this.input = ''
    },
    handlerInputConfirm (e) {
      const inputValue = e.target.value.trim()
      if (this.tagSelection.find(tag => tag.name === inputValue)) {
        this.$message.warning('不可重复添加标签')
        return
      }
      if (!inputValue) return
      this.tagSelection.push({
        from: 'new',
        middleId: inputValue,
        tagId: inputValue,
        name: inputValue
      })
      this.input = null
    },
    handleRemoveFromSelection (item) {
      this.tagSelection = this.tagSelection.filter(
        tag =>
          `${tag.middleId}-${tag.tagId}` !== `${item.middleId}-${item.tagId}`
      )
    }
  }
}
</script>

<style lang="less" scoped>
.el-tag {
  &.selected-tag {
    margin-right: 12px;
  }
}
.popover-content {
  .auto-complete-input {
    width: 100%;
  }
  .el-divider--horizontal {
    margin-top: 0;
  }
  .select-container {
    margin: 24px;
    .group-col {
      max-height: 240px;
      height: 240px;
      overflow-y: auto;
      cursor: pointer;
      .group-item {
        line-height: 34px;
        &:hover {
          color: rgb(70, 160, 252);
        }
        &.active {
          color: rgb(82, 122, 152);
          font-weight: bolder;
        }
      }
    }
    .tag-col {
      padding-left: 24px;
      max-height: 240px;
      height: 240px;
      overflow-y: auto;
      cursor: pointer;
      .el-tag {
        margin-right: 12px;
      }
    }
  }
}
</style>
