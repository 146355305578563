<template>
  <div class="login_container">
    <div class="login_box">
      <!-- 头像区 -->
      <div class="avatar_box">
        <img
          src="../assets/logo.png"
          alt=""
        >
      </div>
      <!-- 登陆表单区 -->
      <div class="form_box">
        <el-form
          ref="form"
          :model="form"
          label-width="0px"
          class="login_form"
          :rules="rules"
        >
          <!-- 用户名 -->
          <el-form-item prop="name">
            <el-input
              prefix-icon="iconfont icon-usercenter"
              placeholder="请输入用户名"
              v-model="form.name"
            ></el-input>
          </el-form-item>
          <!-- 密码 -->
          <el-form-item prop="passwd">
            <el-input
              prefix-icon="iconfont icon-password"
              placeholder="请输入密码"
              show-password
              v-model="form.passwd"
            ></el-input>
          </el-form-item>
          <!-- 按钮区 -->
          <el-form-item class="btns">
            <el-button
              type="primary"
              round
              @click="submitForm('form')"
            >登陆</el-button>
            <el-button
              type="info"
              round
              @click="resetForm('form')"
            >重置</el-button>

          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="footer">
      <div>健而康科技信息服务(中山)有限公司</div>
      <el-link
        :underline="false"
        style="color:#ffffff"
        href="https://beian.miit.gov.cn/"
        target="_blank"
      >粤ICP备17160222号</el-link>
    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      form: {
        name: 'admin',
        passwd: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入用户名称', trigger: 'blur' },
          { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        passwd: [
          { required: true, message: '请输入用户密码', trigger: 'blur' },
          { min: 4, max: 20, message: '长度在 4 到 20 个字符', trigger: 'blur' }
        ]
      }

    }
  },
  methods: {
    onSubmit: function () {
      console.log(JSON.stringify(this.form))
    },
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { data: resp } = await this.$http.post('cms/admin/login', this.form)
          if (resp.code === 200) {
            this.$message({
              message: '登陆成功！',
              type: 'success'
            })
            window.sessionStorage.setItem('token', resp.data)
            this.$router.push('/home')
          } else {
            this.$message.error(resp.message)
          }
        } else {
          this.$message({
            message: '请填写完整',
            type: 'warning'
          })
          return false
        }
      })
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    }
  }

}
</script>

<style lang="less" scoped>
.login_container {
  background-color: #336699;
  height: 100%;
}
.login_box {
  width: 450px;
  height: 350px;
  background-color: #fff;
  border-radius: 3px;
  // 居中一个div
  position: absolute;
  border: 5px solid #000;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 10px #000;

  .avatar_box {
    height: 100px;
    width: 100px;
    position: absolute;
    background-color: #eee;
    border-radius: 50%;
    border: 5px solid #000;
    box-shadow: 0 0 10px #000;
    padding: 10px;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    img {
      height: 100%;
      widows: 100%;
      border-radius: 50%;
      background-color: #eee;
    }
  }

  .form_box {
    position: absolute;
    top: 100px;
    width: 100%;
    height: 250px;

    .login_form {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      // 宽度自适应父布局，添加边距的处理方式，使用padding，并把盒模型改为边框而非内容
      padding: 0 20px;
      box-sizing: border-box;

      .btns {
        // 子元素居右显示
        //先设为弹性盒子
        display: flex;
        //横轴方向靠右对齐
        justify-content: flex-end;
      }
    }
  }
}

.footer {
  color: white;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 10px;
}
</style>
