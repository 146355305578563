
/**
 * @param {object} options 选项
 * @param {boolean} options.smooth 开启平滑滚动，默认开
 * @param {boolean | number} options.delayForAnimation 开启延迟执行，比如要等待页面上个过渡动画完成再滚动，避免卡顿，默认关
 */
export function scrollToTop (options) {
  const { smooth, delayForAnimation } = { smooth: true, ...(options || {}) }
  const delay = typeof delayForAnimation === 'number' ? delayForAnimation : delayForAnimation ? 800 : 0
  setTimeout(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: smooth ? 'smooth' : 'auto'
    })
  }, delay)
}
