<template>
  <div>
    <div>医院管理</div>
    <div style="margin-top: 16px">患者端注册医院选择列表管理</div>
    <el-card style="margin-top: 16px">
      <el-form ref="form" :model="formAddHospital" label-width="80px" style="margin-top: 16px">
        <el-form-item label="医院名称">
          <el-input v-model="formAddHospital.name" style="width: 300px"></el-input>
        </el-form-item>
        <el-form-item label="邀请码">
          <el-input v-model="formAddHospital.ivCode" style="width: 300px"></el-input>
        </el-form-item>
        <el-form-item label="排序">
          <el-input v-model="formAddHospital.sort" style="width: 300px"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">立即添加</el-button>
          <el-button>取消</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-table :data="tableDataHospitals" border style="width: 100%;margin-top: 16px">
      <el-table-column prop="id" label="id" width="90"></el-table-column>
      <el-table-column prop="name" label="医院名称" width="180"></el-table-column>
      <el-table-column prop="hospitalId" label="医院ID" width="90"></el-table-column>
      <el-table-column prop="id" label="注册时的医院ID" width="90"></el-table-column>
      <el-table-column label="当前状态" width="90">
        <template slot-scope="scope">
          <span
            style="margin-left: 10px"
            :style="{ 'color': scope.row.active ? '#04ab04' : '#fd4e4e' }"
          >
            {{
            scope.row.active ?
            '已开通' : '未开通' }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="是否显示" width="90">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.show ? '显示' : '隐藏' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="inviteCode" label="邀请码" width="180"></el-table-column>
      <el-table-column prop="sort" label="排序"></el-table-column>
    </el-table>

    <div style="margin-top: 32px">添加医生到注册选择界面</div>
    <div style="display: flex;justify-items: center;margin-top: 16px">
      <div>手机号：</div>
      <el-input style="width: 200px" v-model="phone"></el-input>
      <div>注册医院id</div>
      <el-input style="width: 200px" v-model="hid"></el-input>
      <el-button @click="add">提交</el-button>
    </div>
    <div style="margin-top: 32px">添加医生到医生列表</div>
    <div style="display: flex;justify-items: center;margin-top: 16px">
      <div>手机号：</div>
      <el-input style="width: 200px" v-model="phone"></el-input>
      <div>医院id</div>
      <el-input style="width: 200px" v-model="hid2"></el-input>
      <el-button @click="addDoctorList">提交</el-button>
    </div>
    <div style="margin-top: 32px">添加医生到MDT(1-惠州立德 2-顺德社区测试1 3-顺德中医院 4-南海医院)</div>
    <div style="display: flex;justify-items: center;margin-top: 16px">
      <div>手机号：</div>
      <el-input style="width: 200px" v-model="phone"></el-input>
      <div>mdt domian id</div>
      <el-input style="width: 200px" v-model="domian"></el-input>
      <el-button @click="addDomain">提交</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RegisterHospital',
  data () {
    return {
      tableDataHospitals: [],
      formAddHospital: {},
      phone: '',
      hid: '',
      hid2: '',
      domian: ''
    }
  },
  mounted () {
    this.getHosiptals()
  },
  methods: {
    async addDomain () {
      console.log('手机号', this.phone)
      console.log('domian', this.domian)

      const { data: resp } = await this.$http.post('regist/setDoctorMDT', {
        phone: this.phone,
        hid: this.domian
      })
      if (resp.code !== 200) return this.$message.error(resp.message)
      this.$message.success(resp.message)
    },
    async addDoctorList () {
      console.log('手机号', this.phone)
      console.log('hid2', this.hid2)

      const { data: resp } = await this.$http.post('regist/setDoctorList', {
        phone: this.phone,
        hid: this.hid2
      })
      if (resp.code !== 200) return this.$message.error(resp.message)
      this.$message.success(resp.message)
    },
    async add () {
      console.log('手机号', this.phone)
      console.log('hid', this.hid)

      const { data: resp } = await this.$http.post('regist/setDoctor', {
        phone: this.phone,
        hid: this.hid
      })
      if (resp.code !== 200) return this.$message.error(resp.message)
      this.$message.success(resp.message)
    },
    async getHosiptals () {
      const { data: resp } = await this.$http.get('regist/hospitals')
      console.log(resp)
      if (resp.code !== 200) return this.$message.error(resp.message)
      this.tableDataHospitals = resp.data
    },
    async onSubmit () {
      console.log(this.formAddHospital)
      const { data: resp } = await this.$http.post('regist/add', this.formAddHospital)
      console.log(resp)
      if (resp.code !== 200) return this.$message.error(resp.message)
      this.$message.success('添加成功')
    }
  }
}
</script>

<style scoped></style>
