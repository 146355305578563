<template>
  <div class="bg">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/menu/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>内容管理</el-breadcrumb-item>
      <el-breadcrumb-item>资讯文章</el-breadcrumb-item>
      <el-breadcrumb-item>添加文章</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="container">

      <div style="width:900px">

        <p>标题</p>
        <el-input
          v-model="article.title"
          placeholder="请输入标题"
        ></el-input>

        <el-row :gutter="20">
          <el-col :span="6">
            <div>
              <p>作者</p>
              <el-input
                style="width:200px"
                v-model="article.author"
                placeholder="请输入作者"
              ></el-input>
            </div>
          </el-col>
          <el-col :span="18">
            <div>
              <p>引用网页</p>
              <el-input
                v-model="article.refLink"
                placeholder="请输入文章网址"
              ></el-input>
            </div>
          </el-col>

        </el-row>

      </div>

      <!-- bidirectional data binding（双向数据绑定） -->
      <quill-editor
        class="editor"
        v-model="article.content"
        ref="myQuillEditor"
        :options="editorOption"
        @blur="onEditorBlur($event)"
        @focus="onEditorFocus($event)"
        @ready="onEditorReady($event)"
        @change="onEditorChange"
      >
      </quill-editor>

      <div style="width:900px">

        <h5 style="margin-top:50px;"><span style="color:red;">*</span> 摘要</h5>

        <el-input
          type="textarea"
          style="width:700px;"
          :autosize="{ minRows: 4}"
          placeholder="请输入[摘要]"
          v-model="article.brief"
        >
        </el-input>
      </div>

      <el-button
        style="margin-top: 10px;width:200px"
        size="small"
        type="success"
        @click="onArticleSubmit"
      >提交</el-button>

    </div>

  </div>
</template>

<script>

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { Quill, quillEditor } from 'vue-quill-editor'
import { ImageExtend, QuillWatch } from 'quill-image-extend-module'

// 图片可收缩
import ImageResize from 'quill-image-resize-module'

Quill.register('modules/ImageExtend', ImageExtend)
Quill.register('modules/ImageResize', ImageResize)

const Size = Quill.import('attributors/style/size')
Size.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px']
Quill.register(Size, true)

// quill编辑器的字体
// var fonts = ['SimSun', 'SimHei', 'Microsoft-YaHei', 'KaiTi', 'FangSong', 'Arial', 'Times-New-Roman', 'sans-serif']
var fonts = ['SimSun']
var Font = Quill.import('formats/font')
Font.whitelist = fonts // 将字体加入到白名单
Quill.register(Font, true)

export default {
  components: { quillEditor },

  data () {
    return {

      editorOption: {
        // some quill options
        theme: 'snow',
        modules: {
          ImageResize: {
            displayStyles: {
              backgroundColor: 'black',
              border: 'none',
              color: 'white'
            },
            modules: ['Resize', 'DisplaySize', 'Toolbar']
          },
          // ImageResize: {
          //   modules: ['Resize', 'DisplaySize', 'Toolbar']
          // },
          ImageExtend: {
            loading: true,
            name: 'file',
            action: 'http://www.jianerkanggz.com/docan/oss/article/img',
            headers: (xhr) => {
              console.log(xhr)
            },
            response: (res) => {
              console.log(res)
              return res.data
            },
            change: (xhr, formData) => {
              console.log(xhr)
              console.log(formData)
              // xhr.setRequestHeader('myHeader','myValue')
              // formData.append('token', 'myToken')
            } // 可选参数 每次选择图片触发，也可用来设置头部，但比headers多了一个参数，可设置formData

          },
          toolbar: {
            container: [
              [{ header: 2 },
                'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block', { list: 'ordered' }, { list: 'bullet' }], // 标题，键值对的形式；1、2表示字体大小 ：： [{ header: 1 }, { header: 2 }]
              // ['bold', 'italic', 'underline', 'strike'], // 加粗，斜体，下划线，删除线
              // ['blockquote', 'code-block'], // 引用，代码块
              // [{ list: 'ordered' }, { list: 'bullet' }], // 列表
              // [{ script: 'sub' }, { script: 'super' }], // 上下标
              [{ indent: '-1' }, { indent: '+1' }], // 缩进
              // [{ direction: 'rtl' }], // 文本方向
              [{ size: Size.whitelist }], // 字体大小
              // [{ header: [1, 2, 3, 4, 5, 6, false] }], // 几级标题
              [{ color: [] }, { background: [] }], // 字体颜色，字体背景颜色
              [{ font: Font.whitelist }], // 字体
              [{ align: [] }], // 对齐方式
              ['clean'], // 清除字体样式
              ['image', 'video'] // 上传图片、上传视频
            ],
            handlers: {
              image: function () {
                QuillWatch.emit(this.quill.id)
              }
            }
          }
        }
      },
      article: {
        title: '',
        author: '',
        refLink: '',
        content: ''
      }
    }
  },
  methods: {
    onEditorBlur (quill) {
      console.log('editor blur!', quill)
    },
    onEditorFocus (quill) {
      console.log('editor focus!', quill)
    },
    onEditorReady (quill) {
      console.log('editor ready!', quill)
    },
    onEditorChange ({ quill, html, text }) {
      // console.log('editor change!', quill, html, text)

      this.article.content = html
      console.log('文章内容', this.article.content)
    },
    onArticleSubmit () {
      this.postArticle()
    },
    async postArticle () {
      console.log(this.article)
      const { data: resp } = await this.$http.post('cms/article/add', this.article)
      console.log(resp)
      if (resp.code === 200) {
        this.$message({
          message: '上传成功！',
          type: 'success'
        })
      } else {
        this.$message.error(resp.message)
      }
    }
  },
  computed: {
    editor () {
      return this.$refs.myQuillEditor.quill
    }
  },
  mounted () {
    console.log('this is current quill instance object', this.editor)
  }

}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.editor {
  width: 900px;
  height: 800px;
  margin-top: 20px;
  line-height: 24px;
  .ql-snow {
    background-color: #ffffff;
  }
}

.ql-editor {
  line-height: 1.7;
}
</style>
