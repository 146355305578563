<template>
  <div>
    <slot>请添加表格组件</slot>
    <div class="block">
      <el-pagination
        style="margin-top: 16px"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        layout="total,sizes,prev,pager,next,jumper"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TablePage',
  props: {
    total: Number,
    pageSize: Number,
    pageSizes: {
      type: Array,
      default: () => {
        return [5, 10, 20, 30, 40]
      }
    }
  },
  data () {
    return {
      currentPage: 1
    }
  },
  computed: {},
  methods: {
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
      this.$emit('sizeChange', val)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
      this.$emit('pageChange', val)
    }
  }
}
</script>

<style scoped>

</style>
