export async function uploadFile (dir, file, onProgress, onSuccess, onError) {
  try {
    const OSS = require('ali-oss')
    const ossConfig = {
      // region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
      region: 'oss-cn-shenzhen',
      // 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录RAM控制台创建RAM账号。
      accessKeyId: 'LTAIAcClE56I1mY0',
      accessKeySecret: 'IIPoiW0o3noCHU9bRT5vyAw0EDtem8',
      bucket: 'ihealthy'
    }
    const client = new OSS(ossConfig)

    // console.log(file)
    // 分片上传文件
    const ret = await client.multipartUpload(dir + '/' + file.name, file, {
      progress: async function (p) {
        // console.log('multipartUpload', p)
        const e = {}
        e.percent = parseInt(p * 100)
        onProgress(e.percent)
      }
    })

    if (ret.res.statusCode === 200) {
      var fileResp = ret.res.requestUrls[0]
      onSuccess(fileResp.split('?')[0])
    } else {
      onError('上传失败')
      console.log('上传失败', ret)
    }
  } catch (err) {
    console.error('上传失败', err)
    onError('上传失败')
    // this.$error(err.message)
  }
}
