<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/menu/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>运营数据</el-breadcrumb-item>
      <el-breadcrumb-item>MDT群管理</el-breadcrumb-item>
      <el-breadcrumb-item>MDT列表</el-breadcrumb-item>
    </el-breadcrumb>

    <div>
      <TablePage v-if="!loading" :total="total" :page-size="pageSize" :page-sizes="[5,10,20]" @sizeChange="onSizeChange"
                 @pageChange="onPageChange">
        <el-table
          :data="tableData"
          :height="tableHeight"
          stripe
          style="width: 100%">
          <el-table-column
            label="创建日期"
            width="180">
            <template v-slot="scope">
              <div>{{ formateDate(scope.row.createDate) }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="jmessageGroupId"
            label="极光群ID"
            width="120">
          </el-table-column>
          <el-table-column
            prop="name"
            label="群名"
            width="280">
          </el-table-column>
          <el-table-column
            label="群主"
            width="180">
            <template v-slot="scope">
              <div>{{ scope.row.owner.name }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            label="成员-患者"
            width="180">
            <template v-slot="scope">
              <div v-for="(item) in scope.row.memberPatients" :key="item.userId">{{ item.name }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            label="成员-医生"
            width="180">
            <template v-slot="scope">
              <div v-for="(item) in scope.row.memberDoctors" :key="item.userId">{{ item.name }}</div>
            </template>
          </el-table-column>
          <el-table-column
            :fixed="'right'"
            label="操作"
            width="300"
          >
            <template slot-scope="scope">
              <el-button
                @click="onDelMdtClick(scope.row)"
                type="text"
                size="small"
              >删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </TablePage>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialogMdtDelVisible"
      width="30%">
      <span>确定删除「{{ delMdt ? delMdt.name : '' }}」？</span>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogMdtDelVisible = false">取 消</el-button>
    <el-button type="primary" @click="doDelMdt">确 定</el-button>
  </span>
    </el-dialog>

  </div>
</template>

<script>
import TablePage from '../../../common-components/TablePage'

export default {
  name: 'MdtList',
  components: { TablePage },
  data () {
    return {
      loading: true,
      page: 1,
      pageSize: 10,
      total: 0,
      totalPage: 0,
      tableData: [],
      tableHeight: 400,
      dialogMdtDelVisible: false,
      delMdt: null
    }
  },
  mounted () {
    this.getPage()
    this.$nextTick(() => {
      // 窗口高度 - 上边其他布局高度 - 最上面的导航栏高度和其他边距高度(225) = 表格高度（也就是剩下的高度）
      this.tableHeight = (window.innerHeight - 275)
    })
  },
  methods: {
    async getPage () {
      const { data: resp } = await this.$http.get('cms/mdt/list', {
        params: {
          page: this.page,
          pageSize: this.pageSize
        }
      })
      console.log('MDT page', resp.data)
      this.totalPage = resp.data.totalPage
      this.total = resp.data.totalElements
      this.tableData = resp.data.datas
      this.loading = false
    },
    onSizeChange (val) {
      this.pageSize = val
    },
    onPageChange (val) {
      this.page = val
      this.getPage()
    },
    formateDate (date) {
      return new Date(date).toLocaleDateString() + ' ' + new Date(date).toLocaleTimeString()
    },
    onDelMdtClick (item) {
      this.dialogMdtDelVisible = true
      console.log('删除MDT', item)
      this.delMdt = item
    },
    async doDelMdt () {
      console.log('确认删除', this.delMdt)
      const { data: resp } = await this.$http.get('cms/mdt/delete?id=' + this.delMdt.id)
      if (resp.code === 200) {
        this.$message({
          message: '删除成功',
          type: 'success'
        })
        this.delMdt = null
        this.dialogMdtDelVisible = false
        this.$router.go(0)
      }
    }
  }
}
</script>

<style scoped>

</style>
