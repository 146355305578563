<template>
  <div>
    <el-row :gutter="10">
      <el-col
        :xs="{span:24,offset:0}"
        :sm="{span:16,offset:4}"
        :md="{span:12,offset:6}"
      >
        <div>
          <h2 style="margin-left:16px;margin-right:16px">{{article.title}}</h2>
          <div style="margin-left:16px;margin-right:16px;"><span class="author">{{article.author}}</span> <span class="author">{{ article.createDate}}</span></div>

          <div class="ql-container ql-snow">
            <div
              class="ql-editor"
              v-html="article.content"
            >
            </div>
          </div>

          <el-link
            style="margin-start:16px;margin-bottom:16px;font-size: 12px;color:#008FEE;"
            :href="article.refLink"
            v-if="showLink"
            target="_blank"
          > 原文链接</el-link>
        </div>

      </el-col>
    </el-row>

  </div>

</template>

<script>

export default {

  data () {
    return {
      articleId: '',
      article: '',
      showLink: false

    }
  },
  created () {
    // 跳转后页面获取参数
    this.articleId = this.$route.params.id
    console.log(this.articleId)

    this.loadArticle()
  },
  methods: {
    async loadArticle () {
      const { data: resp } = await this.$http.get('cms/article/load', { params: { id: this.articleId } })
      if (resp.code !== 200) {
        return this.$message.error(resp.message)
      }

      const date = new Date(resp.data.createDate)
      var year = date.getFullYear() // 获取完整的年份(4位)
      var month = date.getMonth() + 1 // 获取当前月份(0-11,0代表1月)
      var day = date.getDate() // 获取当前日(1-31)
      var hour = date.getHours() // 获取当前小时数(0-23)
      var min = date.getMinutes() // 获取当前分钟数(0-59)
      resp.data.createDate = year + '年' + month + '月' + day + '日' + ' ' + hour + ':' + min

      this.article = resp.data
      if (this.article.refLink !== '') {
        this.showLink = true
      }
    }
  }

}
</script>

<style lang="less" scoped>
.ql-container.ql-snow {
  border: 0 solid #ccc;
}

.ql-editor {
  line-height: 2;
  // letter-spacing: 1px;
}

.author {
  color: #777777;
  font-size: 14px;
}
</style>
