<template>
  <div>

    <el-breadcrumb id="nav" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/menu/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>运营数据</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>患者</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <div id="top">
        <el-row :gutter="20">
          <el-col :span="6">
            <el-input
              placeholder="请输入手机号"
              v-model="inputPhone"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="onSearch"
              ></el-button>
            </el-input>
          </el-col>
          <el-col :span="2">
            <el-button
              type="info"
              plain
            >清除输入
            </el-button>
          </el-col>
        </el-row>
      </div>
      <TablePage :total="total" :page-size="pageSize" :page-sizes="[5,10,15,20]"
                 @sizeChange="sizeChange"
                 @pageChange="pageChange">
        <el-table
          v-loading="loading"
          :height="tableHeight"
          :data="doctors"
          style="width: 100%"
          stripe
          border
        >
          <el-table-column
            prop="createDate"
            label="日期"
            width="180"
          >
          </el-table-column>
          <el-table-column
            prop="realname"
            label="姓名"
            width="120"
          >
          </el-table-column>
          <el-table-column
            prop="phone"
            label="手机号"
            width="120"
          >
          </el-table-column>
          <el-table-column
            prop="hospital"
            label="医院"
            width="230"
          >
          </el-table-column>
          <el-table-column
            prop="inviterName"
            label="医院渠道"
            width="180"
          >
          </el-table-column>
          <el-table-column
            prop="registerHospital"
            label="注册选择医院"
            width="180"
          >
          </el-table-column>
          <el-table-column
            prop="registerDoctorName"
            label="注册选择医生(邀请人)"
          >
          </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="300"
          >
            <template slot-scope="scope">
              <el-button
                @click="handleClick(scope.row)"
                type="text"
                size="small"
              >查看
              </el-button>
              <el-button
                type="text"
                size="small"
              >编辑
              </el-button>
              <el-button
                type="text"
                size="small"
                @click="changeHopsital(scope.row)"
              >切换医院
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </TablePage>
      <!--      <el-pagination-->
      <!--        @size-change="handleSizeChange"-->
      <!--        @current-change="handleCurrentChange"-->
      <!--        :page-size="pageSize"-->
      <!--        layout="total, prev, pager, next"-->
      <!--        :total="total"-->
      <!--      >-->
      <!--      </el-pagination>-->
    </el-card>
    <el-dialog title="切换医院" :visible.sync="dialogChangeHospitalVisible">
      <!--      <div v-if="loadingGetChangeHospitalList"></div>-->
      <!--      <div v-else></div>-->
      <el-table
        v-loading="loadingGetChangeHospitalList"
        :data="changeHospitalList"
        border
        stripe
        ref="changeHospitalTableData"
        style="width: 100%">
        <el-table-column
          width="100"
          align="center"
          label="当前医院">
          <template slot-scope="scope">
            <el-radio v-model="changeHospitalSeleced" :label="scope.row.hospitalId">{{ ' ' }}</el-radio>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="医院"
        >
        </el-table-column>
      </el-table>
      <el-row style="margin-top: 32px" type="flex" justify="end">
        <el-button type="info" @click="dialogChangeHospitalVisible = false">取消</el-button>
        <el-button type="primary" @click="changeHospitalSelection">确定</el-button>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import TablePage from '../../../common-components/TablePage'

export default {
  components: { TablePage },
  data () {
    return {
      loading: true,
      inputPhone: '',
      page: 1,
      pageSize: 15,
      total: 0,
      doctors: [],
      hospitals: [],
      dialogChangeHospitalVisible: false,
      loadingGetChangeHospitalList: true,
      changeHospitalList: [],
      operatorPatient: null,
      changeHospitalSeleced: '',
      tableHeight: 400
    }
  },
  created () {
    // this.getHosiptals()
  },
  mounted () {
    this.$nextTick(() => {
      // 窗口高度 - 上边其他布局高度 - 最上面的导航栏高度和其他边距高度(225) = 表格高度（也就是剩下的高度）
      const number = document.getElementById('top').offsetHeight + document.getElementById('nav').offsetHeight + 220
      console.log('其他高度', number)
      this.tableHeight = (window.innerHeight - number)
    })
    this.getPatients()
  },
  methods: {
    async onSearch () {
      this.loading = true
      console.log('搜索', this.inputPhone)
      this.pageSize = 100
      this.page = 1
      const { data: resp } = await this.$http.get('cms/patient/phone', {
        params: {
          phone: this.inputPhone
        }
      })

      // this.doctors = [
      //   { name: '陈其康', hospital: '动优健康管理中心', date: '2020年8月23日', phone: '13610386482' }
      // ]
      console.log(resp)
      if (resp.code !== 200) return this.$message.error(resp.message)

      resp.data.content.forEach(function (d) {
        d.createDate = new Date(d.createDate).toLocaleString()
      })
      this.doctors = resp.data.content
      this.total = resp.data.totalElements
      this.pageSize = resp.data.size
      this.loading = false
    },
    async changeHospitalSelection () {
      if (this.changeHospitalSeleced !== this.operatorPatient.hospitalCode) {
        console.log('修改了医院')
        const rsp = await this.$http.post('cms/patient/changeHospital', {
          userId: this.operatorPatient.userId,
          hospitalId: this.changeHospitalSeleced
        })
        if (rsp.data.code === 200) {
          this.$message({
            message: '修改成功',
            type: 'success'
          })
          this.dialogChangeHospitalVisible = false
        } else {
          this.$message({
            message: '修改失败:' + rsp.data.message,
            type: 'error'
          })
        }
      } else {
        console.log('没修改医院')
      }
    },
    async changeHopsital (patient) {
      this.operatorPatient = patient
      this.changeHospitalList = []
      this.changeHospitalSeleced = patient.hospitalCode
      console.log('切换医院', patient)
      this.dialogChangeHospitalVisible = true
      const hospitals = await this.getHosiptals()
      for (const key in hospitals) {
        console.log(hospitals[key])
        this.changeHospitalList.push({
          name: hospitals[key].name,
          hospitalId: hospitals[key].hospitalId
        })
      }
      this.loadingGetChangeHospitalList = false
    },
    async getHosiptals () {
      this.loading = true
      const { data: resp } = await this.$http.get('regist/hospitals')
      console.log(resp)
      if (resp.code !== 200) return this.$message.error(resp.message)
      this.loading = false
      return resp.data
    },
    // async getHosiptals () {
    //   const { data: resp } = await this.$http.get('cms/doctor/hospitals')
    //   console.log(resp)
    //   if (resp.code !== 200) return this.$message.error(resp.message)
    //
    //   this.hospitals = resp.data
    // },
    async getPatients () {
      this.loading = true
      // this.doctors = [
      //   { name: '陈其康', hospital: '动优健康管理中心', date: '2020年8月23日', phone: '13610386482' }
      // ]
      const { data: resp } = await this.$http.get('cms/patient/list', {
        params: {
          page: this.page,
          pageSize: this.pageSize
        }
      })
      console.log(resp)
      if (resp.code !== 200) return this.$message.error(resp.message)

      resp.data.content.forEach(function (d) {
        d.createDate = new Date(d.createDate).toLocaleString()
      })
      this.doctors = resp.data.content
      this.total = resp.data.totalElements
      this.pageSize = resp.data.size
      this.loading = false
    },
    sizeChange (val) {
      console.log(`每页 ${val} 条`)
      this.pageSize = val
      this.getPatients()
    },
    pageChange (val) {
      console.log(`当前页: ${val}`)
      this.page = val
      this.getPatients()
    }
  }

}
</script>

<style lang="less" scoped>
.el-table {
  margin-top: 15px;
  font-size: 10px;
}
</style>
