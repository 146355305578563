import { render, staticRenderFns } from "./RichContentPreview.vue?vue&type=template&id=2bcf63ee&scoped=true"
import script from "./RichContentPreview.vue?vue&type=script&lang=js"
export * from "./RichContentPreview.vue?vue&type=script&lang=js"
import style0 from "./styles/rich-content-preview.less?vue&type=style&index=0&id=2bcf63ee&prod&lang=less&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bcf63ee",
  null
  
)

export default component.exports