<template>
  <el-dialog title="出现在哪些医院注册时的医生列表" :visible.sync="show"
             @open="onOpen">
    <el-table
      :data="datas"
      ref="refTable"
      border
      @selection-change="handleChange"
      style="width: 100%">
      <el-table-column
        type="selection"
        width="55">
      </el-table-column>
      <el-table-column
        prop="name"
        label="医院"
      >
      </el-table-column>
    </el-table>
    <el-row style="margin-top: 32px" type="flex" justify="end">
      <el-button type="info" @click="show = false">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
export default {
  name: 'RegisterHospitalSelectDialog',
  data () {
    return {
      datas: [],
      show: false,
      phone: '',
      userId: '',
      selections: []
    }
  },
  mounted () {

  },
  methods: {
    onShow (phone, userId) {
      console.log('当前医生phone', phone)
      console.log('userId', userId)
      this.phone = phone
      this.userId = userId
      this.show = true
    },
    onOpen () {
      console.log('打开')
      this.getHospitals()
    },
    handleChange (val) {
      this.selections = val
      console.log('选择', this.selections)
    },
    async submit () {
      const hids = []
      for (const h of this.selections) {
        hids.push(h.id)
      }
      const { data: resp } = await this.$http.post('regist/updateDoctor', {
        phone: this.phone,
        hid: hids
      })
      if (resp.code !== 200) this.$message.error(resp.message)
      this.$message.success(resp.message)
    },
    async getHospitals () {
      const { data: resp } = await this.$http.get('regist/hospitals/phone',
        {
          params:
            {
              userId: this.userId
            }

        }
      )
      console.log(resp)
      if (resp.code !== 200) return this.$message.error(resp.message)
      this.datas = resp.data
      setTimeout(() => {
        this.datas.forEach(row => {
          if (row.selected) {
            console.log('已选择', row)
            this.$refs.refTable.toggleRowSelection(row)
          }
        })
      }, 50)
    }
  }
}
</script>

<style scoped>

</style>
