import Vue from 'vue'
import './cube-ui'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
// 导入全局样式表
import './assets/css/global.css'
// 导入字体图标库
import './assets/fonts/iconfont.css'
import './assets/css/font.css'
// 导入网络访问库
import axios from 'axios'

import VueQuillEditor from 'vue-quill-editor'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'

import 'vant/es/button/style'
import { Button, Dialog, Popup } from 'vant'

Vue.use(VueQuillEditor)
Vue.use(VideoPlayer)

Vue.use(Button).use(Popup).use(Dialog)

// 把网络访问对象挂载到vue原型属性上，各个组件都可以使用 --- 全局变量
// axios.defaults.baseURL = 'http://localhost:8080/'
axios.defaults.baseURL = 'https://www.jianerkanggz.com/docan/'
axios.interceptors.request.use(config => {
  console.log(config)
  config.headers.Authorization = window.sessionStorage.getItem('token')
  if (!config.headers.token) {
    config.headers.token = '795287af-2e9b-49a5-86eb-48f8be6d54bd'
  }
  return config
})

Vue.prototype.$http = axios

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
